import React, { Component } from "react";

class UserItem extends Component {
	userClick = () => {
		this.props.accountClick(this.props.id);
	};
	render() {
		return (
			<li className="listColumns" onClick={this.userClick}>
				<p>{this.props.serial}</p>
				<p>{this.props.name}</p>
				<p>{this.props.org}</p>
				<p>{this.props.email}</p>
				<p>{this.props.roles.join(", ")}</p>
			</li>
		);
	}
}

export default UserItem;
