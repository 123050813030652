import React from "react";

function add(a, b) {
	return a + b;
}

function getOrganizationHeader(organization, logo) {
	return (
		<React.Fragment>
			{logo ? (
				<p className="mb-0">
					<img
						src={logo}
						alt=""
						style={{ maxHeight: 80, maxWidth: 150 }}
						className="mr-2"
					/>{" "}
				</p>
			) : organization ? (
				organization + ": "
			) : (
				""
			)}
		</React.Fragment>
	);
}

function Step(context, t) {
	this._context = context;
	this._t = t;
}

Step.prototype = {
	areaStart: function () {
		this._line = 0;
	},
	areaEnd: function () {
		this._line = NaN;
	},
	lineStart: function () {
		this._x = this._y = NaN;
		this._point = 0;
	},
	lineEnd: function () {
		if (0 < this._t && this._t < 1 && this._point === 2) this._context.lineTo(this._x, this._y);
		if (this._line || (this._line !== 0 && this._point === 1)) this._context.closePath();
		if (this._line >= 0) {
			this._t = 1 - this._t;
			this._line = 1 - this._line;
		}
	},
	point: function (x, y) {
		var dy = y - this._y;

		const c1 = 40;
		const c2 = 20;

		if (dy > c1) dy = c1;
		if (dy < -c1) dy = -c1;

		dy = 0.5 * dy;

		x = +x;
		y = +y;

		var x1 = this._x * (1 - this._t) + x * this._t;
		switch (this._point) {
			case 0:
				this._point = 1;
				this._line ? this._context.lineTo(x, y) : this._context.moveTo(x, y);
				break;
			// case 1:
			// 	this._point = 2; // proceed

			default: {
				if (this._point === 1) {
					this._point = 2;
				}
				if (this._t <= 0) {
					this._context.lineTo(this._x, y - dy);
					//this._context.lineTo(this._x-c2, y);
					this._context.bezierCurveTo(this._x, y, this._x, y, this._x - c2, y);

					//this._context.lineTo(this._x, y);
					//this._context.lineTo(x, this.y);
					//this._context.lineTo(this._x);
					//this._context.lineTo(this._x+10, y);
					this._context.lineTo(x, y);
				} else {
					//var x1 = this._x * (1 - this._t) + x * this._t;
					this._context.lineTo(x1 - c2, this._y);
					//this._context.lineTo(x1, this._y+dy);
					this._context.bezierCurveTo(x1, this._y, x1, this._y, x1, this._y + dy);
					//this._context.lineTo(x1, this._y);

					//this._context.lineTo(x1+10, this._y);
					this._context.lineTo(x1, y - dy);
					this._context.bezierCurveTo(x1, y, x1, y, x1 + c2, y);
					this._context.lineTo(x1 + c2, y);
				}
				break;
			}
		}
		this._x = x;
		this._y = y;
	},
};

function SmoothStepFunc(context) {
	return new Step(context, 1);
}

export { add, getOrganizationHeader, SmoothStepFunc };
