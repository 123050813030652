const ProjectStatusEnum = Object.freeze({
	none: 0,
	Draft: "Draft",
	Submitted: "Submitted",
	Approved: "Approved",
	Rejected: "Rejected",
	// only generated by server for transferred offsets
	Received: "Received"
	//Archived: "Archived",
	//Featured: "Featured"
});

const ProgramTypeEnum = Object.freeze({
	none: null,
	BcGccOption1: "BC GCC Option 1",
	BcGccOption2: "BC GCC Option 2",
	FCM: "FCM",
	CompactOfMayors: "Compact Of Mayors",
	ClimateLense: "Infrastructure Canada",
	ISO14064: "ISO 14064-2",
	GoldStandard: "Gold Standard",
	VCS: "VCS",
	Other: "Other",

	// Special Projects
	PurchasedOffsets: "Offsets",
	TransferredOffsets: "TransferredOffsets",
	Inventory: "Inventory"
});

export { ProjectStatusEnum, ProgramTypeEnum };
