import React, { Component } from "react";
import { Table, Col, Row, Input } from "reactstrap";
import classnames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faAngleDown, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { CombineEmissions, SplitEmissions, StatusEnum } from "../../lib/Emissions";
import Tooltip from "../common/Tooltip";
import { TextDictionary } from "../../lib/TextDictionary";
//import { read } from "fs";

function add(a, b) {
	return a + b;
}

function onlyUnique(value, index, self) {
	return self.indexOf(value) === index;
}

function calcSerial(start, count) {
	return (
		splitSerial(start).project +
		"-" +
		(parseInt(splitSerial(start).serial) + count).toString().padStart(6, "0")
	);
}

function splitSerial(serial) {
	const split = serial.split("-");
	return { serial: split[split.length - 1], project: split.slice(0, split.length - 1).join("-") };
}

function sortByProjectSerial(a, b) {
	if (a.projectSerial < b.projectSerial) { return -1; }
	if (a.projectSerial > b.projectSerial) { return 1; }
	return 0;
}
//const EmissionReduction = ({ onChange, erRow, erUpdate, value }) => {

/*
	Special model states (old):
	- isEmpty: Only on first element of a year, means that the summary field is empty
	- count == null: Count field empty

	Special model states (new):
	- isSumEmpty: Only on first element of a year, means that the summary field is empty
	- isRowEmpty: Count field empty

*/

class EmissionReduction extends Component {
	constructor(props) {
		super(props);

		this.onChangeSum = this.onChangeSum.bind(this);
	}
	totalTons(data, year) {
		return data
			.filter(x => x.year === year)
			.map(x => (x.state === StatusEnum.Cancelled ? 0 : parseInt(x.count) || 0))
			.reduce(add, 0);
	}

	registerdTons(data, year) {
		return data
			.filter(x => x.year === year && x.state === StatusEnum.Registered)
			.map(x => parseInt(x.count) || 0)
			.reduce(add, 0);
	}

	cancelledTons(data, year) {
		return data
			.filter(x => x.year === year && x.state === StatusEnum.Cancelled)
			.map(x => parseInt(x.count) || 0)
			.reduce(add, 0);
	}

	getLastElementIdx(newData, year) {
		var lastIdx = 0;
		while (
			lastIdx < newData.length &&
			lastIdx !== newData.length - 1 &&
			newData[lastIdx + 1].year <= year
		) {
			lastIdx++;
		}
		return lastIdx;
	}

	isAdjust(newData, lastIdx) {
		if (
			(newData[lastIdx].state === StatusEnum.Registered ||
				newData[lastIdx].state === StatusEnum.Projected) &&
			!newData[lastIdx].serialMin
		) {
			return true;
		} else {
			return false;
		}
	}

	addOffsetTons(newData, diff, year, idx) {
		if (diff !== 0) {
			// find last entry of that year
			var lastIdx = 0;
			while (
				lastIdx < newData.length &&
				lastIdx !== newData.length - 1 &&
				newData[lastIdx + 1].year <= year
			) {
				lastIdx++;
			}

			if (
				(newData[lastIdx].state === StatusEnum.Registered ||
					newData[lastIdx].state === StatusEnum.Projected ||
					newData[lastIdx].state === StatusEnum.Inventory ||
					newData[lastIdx].state === StatusEnum.ProjectedInventory ||
					newData[lastIdx].state === StatusEnum.Reduced) &&
				!newData[lastIdx].serialMin
			) {
				newData[lastIdx].count = parseInt(newData[lastIdx].count) + diff;
				// remove empty row
				// but only if it's not the only row for that year
				if (
					newData[lastIdx].count === 0 &&
					lastIdx !== 0 &&
					newData[lastIdx - 1].year === year
				) {
					newData.splice(lastIdx, 1);
				}
			} else {
				newData.splice(lastIdx + 1, 0, {
					year: year,
					state: StatusEnum.Registered,
					count: diff
				});
			}
		}
	}

	addOffsetTonsWithSerial(newData, diff, idx) {
		// element with serial needs special handling
		if (
			idx < newData.length - 1 &&
			newData[idx + 1].year === newData[idx].year &&
			newData[idx + 1].serialMin ===
			calcSerial(newData[idx].serialMin, (newData[idx].count || 0) - diff) &&
			newData[idx + 1].state === StatusEnum.Registered
		) {
			// there is an adjacent block
			newData[idx + 1].count += -diff;
			newData[idx + 1].serialMin = calcSerial(newData[idx + 1].serialMin, diff);

			if (newData[idx + 1].count < 0 || newData[idx].count < 0) {
				// not allowed to create negative rows with serial

				throw new Error("Change not allowed");
			}

			if (newData[idx + 1].count === 0) {
				newData.splice(idx + 1, 1);
			}
		} else {
			if (diff > 0) {
				// not allowed to create negative rows with serial
				throw new Error("Change not allowed");
			}
			// split the block
			newData.splice(idx + 1, 0, {
				year: newData[idx].year,
				state: StatusEnum.Registered,
				count: -diff,
				serialMin: calcSerial(newData[idx].serialMin, newData[idx].count || 0)
			});
		}
	}

	onChangeSum(year, e, idx) {
		const newValue = e.target.value;
		const oldData = this.props.value;

		// only allow numbers
		if ((isNaN(newValue) && newValue !== "") || parseInt(newValue) < 0) {
			return;
		}


		const calculatedSum = this.totalTons(oldData, year);

		const diff = (parseInt(newValue) || 0) - calculatedSum;

		var newData = oldData.slice(0);

		if (newValue === "") {
			newData[idx].isEmpty = true;
		} else {
			delete newData[idx].isEmpty;
		}

		// add a registered row if we added some co2
		this.addOffsetTons(newData, diff, year, idx);

		//newData[idx].reductionSum = newValue

		this.props.onChange(newData);
		//console.log(diff);
	}

	getChangeCount(idx, newValue) {
		try {
			const newData = this.props.value.map(a => Object.assign({}, a)); //oldData.slice(0);
			if ((isNaN(newValue) && newValue !== "") || parseInt(newValue) < 0) {
				return newData;
			}

			const diff = (parseInt(newValue) || 0) - (parseInt(newData[idx].count) || 0);
			newData[idx].count = parseInt(newValue) || null;

			if (newData[idx].serialMin) {
				// element with serial needs special handling
				this.addOffsetTonsWithSerial(newData, diff, idx);
			} else {
				//if (newData[idx].state !== StatusEnum.Registered) {
				if (
					idx === newData.length - 1 ||
					(newData[idx + 1].year !== newData[idx].year &&
						newData[idx].state === StatusEnum.Registered)
				) {
					// add a new row if we mauannly adjust the last element
					newData.splice(idx + 1, 0, {
						year: newData[idx].year,
						state: StatusEnum.Registered,
						count: 0
					});
				}
				this.addOffsetTons(newData, -diff, newData[idx].year, idx);
			}

			return newData;
		} catch (error) {
			console.error(error);

			// don't make changes if there was an exception
			return this.props.value;
		}

		//console.log(diff);
	}

	onChangeCount(idx, newValue) {
		const newData = this.getChangeCount(idx, newValue);
		this.props.onChange(newData);
	}

	onRemoveRow(idx) {
		const newData = this.props.value.map(a => Object.assign({}, a));
		const wasOpen = newData[idx].isOpen;

		if (!newData[idx].serialMin) {
			var oldValue = parseInt(newData[idx].count) || 0;

			// keep the year open when the first element is removed.
			if (newData[idx].isOpen) {
				newData[idx + 1].isOpen = true;
			}

			newData.splice(idx, 1);
			this.addOffsetTons(newData, oldValue, newData[idx].year, -1 /* usused */);
		} else {
			// first, set the row count to zero
			const count = newData[idx].count;
			if (count !== 0) {
				newData[idx].count = 0;
				this.addOffsetTonsWithSerial(newData, -count, idx);
			}

			// then remove the row with zero
			newData.splice(idx, 1);
		}

		if (wasOpen) {
			newData[idx].isOpen = wasOpen;
		}
		this.props.onChange(newData);
	}

	onChangeStatus(idx, e) {
		const newData = this.props.value.slice(0);

		if (newData[idx].state === StatusEnum.Projected) {
			newData[idx].isOpen = true;
		}

		newData[idx].state = e.target.value;

		// add a flag to allow editing blocks that have a serial but are not commited in the backend yet.
		if (newData[idx].serialMin) {
			newData[idx].edited = true;
		}

		this.props.onChange(newData);
	}

	onChangeNote(idx, e) {
		const newData = this.props.value.slice(0);
		newData[idx].retiredAgainst = e.target.value;

		this.props.onChange(newData);
	}

	onChangeInventory(idx, project, year, count) {
		const oldValue = parseInt(this.props.value[idx].count) || 0;
		// make sure we don't retire too much
		if (count === undefined || count > oldValue) count = oldValue;

		let newData;
		if (count !== oldValue) {
			newData = this.getChangeCount(idx, count);
		} else {
			newData = this.props.value.map(a => Object.assign({}, a));
		}

		if (year === "other" || year === undefined) {
			newData[idx].state = StatusEnum.Retired;
		} else {
			newData[idx].state = StatusEnum.RetiredInventory;
			newData[idx].inventoryProject = project;
			newData[idx].inventoryYear = year;
		}

		this.props.onChange(newData);
	}

	onChangeTransferred(idx, transferredTo, count) {
		const newData = this.props.value.slice(0);
		newData[idx].transferredTo = transferredTo;

		this.props.onChange(newData);


	}

	renderYearOverview(obj, rows, restricted, isOpen) {
		const idx = obj.idx;
		const readOnly = this.props.readOnly;
		const canBeProjected =
			(obj.state === StatusEnum.Projected || obj.state === StatusEnum.Registered) &&
			// last element in year (=> only element in year)
			(rows.length - 1 === idx || rows[idx + 1].year !== obj.year) &&
			!obj.serialMin;

		const isInventory =
			obj.state === StatusEnum.Inventory || obj.state === StatusEnum.ProjectedInventory;
		const isEstimated = obj.state === StatusEnum.Estimated;

		const isProjected = obj.state === StatusEnum.Projected;

		const isDetailHidden = isInventory || isProjected;

		const cancelledTonnes = this.cancelledTons(rows, obj.year);

		return (
			<React.Fragment>
				<tr
					className="erTableData"
					style={{
						backgroundColor:
							obj.state === StatusEnum.Projected ||
								obj.state === StatusEnum.ProjectedInventory
								? "lightblue"
								: "rgba(0,0,0,.05)"
					}}
				>
					<th
						scope="row"
						className="align-middle"
						style={{
							width: "1px" /* Keep the year colum as small as possible*/
						}}
					>
						<div className="text-nowrap text-right">
							<button
								id={`expand${obj.year}`}
								className="link-button"
								onClick={e => {
									e.preventDefault();
									if (!isDetailHidden) {
										const newData = this.props.value.slice(0);
										newData[idx].isOpen = !isOpen;
										this.props.onChange(newData);
									}
								}}
							>
								{!isDetailHidden && isOpen && (
									<FontAwesomeIcon icon={faAngleDown} className="mr-1" />
								)}

								{!isDetailHidden && !isOpen && (
									<FontAwesomeIcon icon={faAngleRight} className="mr-1" />
								)}

								{obj.year}
							</button>
						</div>
					</th>
					<td>
						<Input
							id={`status${obj.year}`}
							type="select"
							disabled={(!canBeProjected && !isInventory) || readOnly || isEstimated}
							value={obj.state}
							onChange={e => this.onChangeStatus(obj.idx, e)}
						>
							<React.Fragment>
								{canBeProjected && (
									<option value={StatusEnum.Projected}>Projected</option>
								)}
								{!isInventory && (
									<option value={StatusEnum.Registered}>Realized</option>
								)}
							</React.Fragment>

							{isEstimated && <option value={StatusEnum.Estimated}>Baseline</option>}
							{isInventory && (
								<React.Fragment>
									<option value={StatusEnum.Inventory}>Inventory</option>
									<option value={StatusEnum.ProjectedInventory}>Projected</option>
								</React.Fragment>
							)}
						</Input>
					</td>
					<td>
						<input
							id={`value${obj.year}`}
							readOnly={readOnly || this.props.isTransferred}
							name="reduction"
							tag={obj.year}
							onChange={e => this.onChangeSum(obj.year, e, obj.idx)}
							onKeyDown={e => {
								if (e.which === 38 || e.which === 40) e.preventDefault();
							}}
							onWheel={e => {
								document.activeElement.blur();
							}}
							value={
								obj.isEmpty
									? ""
									: obj.reductionSum || this.totalTons(rows, obj.year)
							}
							className={classnames("form-control", {
								"is-invalid": this.props.error[obj.errorIdx] && isProjected
							})}
						/>
						{this.props.error[obj.errorIdx] && isProjected && (
							<div
								style={{ color: "#f86c6b", fontSize: "80%" }}
								id={obj.errorIdx + "_error"}
							>
								{this.props.error[obj.errorIdx]}
							</div>
						)}
					</td>
					<td>
						{cancelledTonnes !== 0 && (
							<React.Fragment>Cancelled: {cancelledTonnes}</React.Fragment>
						)}
					</td>
					<td />
				</tr>
			</React.Fragment>
		);
	}

	renderRow(obj, rows, restricted, isOpen) {
		// assumption: ordered by years
		const idx = obj.idx;
		//const showYear = idx === 0 || rows[idx - 1].year !== obj.year;
		const isProjected = obj.state === StatusEnum.Projected;
		const isInventory = obj.state === StatusEnum.Inventory;

		const isEstimated = obj.state === StatusEnum.Estimated;
		const isReduced = obj.state === StatusEnum.Reduced;

		const isHidden = isProjected || isInventory;

		const readOnly = this.props.readOnly;

		return (
			<React.Fragment key={idx}>
				{!isHidden && (
					<tr
						className={
							classnames({ erTableData: true, collapse: true, show: isOpen }) //"erTableData collapse" + isOpen ? " show" : ""
						}
					>
						<th scope="row" />
						<td className="align-top pl-3">
							<Input
								id={`status-${obj.idx}`}
								type="select"
								value={
									obj.state === StatusEnum.Retired
										? StatusEnum.RetiredInventory
										: obj.state
								}
								onChange={e => this.onChangeStatus(obj.idx, e)}
								disabled={
									(restricted &&
										obj.serialMin &&
										obj.state !== StatusEnum.Registered &&
										!obj.edited) ||
									isEstimated ||
									isReduced ||
									readOnly
								}
							>
								<option value={StatusEnum.Registered}>
									{obj.serialMin ? "Registered" : "Add to Register"}
								</option>
								{/* <option value={StatusEnum.RetiredInventory}>
									Retired Registered Inventory
								</option> */}
								<option value={StatusEnum.RetiredInventory}>
									{obj.serialMin ? "Retired" : "Retire"}</option>


								{this.props.isOffsets || <option value={StatusEnum.Transferred}>{obj.serialMin ? "Transferred" : "Transfer"}</option>}

								{// only show the canceld option when serials are assigned.
									obj.serialMin && !this.props.isOffsets &&  (
										<option value={StatusEnum.Cancelled}>Cancelled</option>
									)}
								{isEstimated && (
									<option value={StatusEnum.Estimated}>Project</option>
								)}
								{isReduced && <option value={StatusEnum.Reduced}>Reduced</option>}
							</Input>
						</td>
						<td>
							<div className="text-nowrap">
								<input
									id={`value-${obj.idx}`}
									//type="number"
									name="qunatity"
									className={classnames("form-control d-inline-block", {
										"is-invalid": this.props.error[obj.errorIdx]
									})}
									onChange={e => this.onChangeCount(obj.idx, e.target.value)}
									onKeyDown={e => {
										if (e.which === 38 || e.which === 40) e.preventDefault();
									}}
									onWheel={e => {
										document.activeElement.blur();
									}}
									value={obj.count}
									readOnly={
										(restricted &&
											obj.serialMin &&
											obj.state !== StatusEnum.Registered &&
											!obj.edited) ||
										readOnly
									}
								/>
								{!(
									(restricted &&
										obj.serialMin &&
										obj.state !== StatusEnum.Registered &&
										!obj.edited) ||
									readOnly ||
									obj.state === StatusEnum.Estimated ||
									//obj.serialMin ||
									(this.getLastElementIdx(rows, obj.year) === obj.idx &&
										!(obj.serialMin && obj.state !== StatusEnum.Registered))
								) && (
										<div
											onClick={e => this.onRemoveRow(obj.idx)}
											className="d-inline-block"
										>
											<FontAwesomeIcon icon={faTimes} color="#f86c6b" />
										</div>
									)}
							</div>

							{this.props.error[obj.errorIdx] && (
								<div
									style={{ color: "#f86c6b", fontSize: "80%" }}
									id={obj.errorIdx + "_error"}
								>
									{this.props.error[obj.errorIdx]}
								</div>
							)}
						</td>
						<td>
							<div style={{ display: "flex" }}>
								{/* className="align-top" */}
								{(obj.state === StatusEnum.RetiredInventory ||
									obj.state === StatusEnum.Retired) && (
										<Input
											type="select"
											id={`retired-${obj.idx}`}
											style={{ minWidth: 0 }}
											//value={obj.state}
											onChange={e => {
												const value = e.target.value;
												const item = this.props.inventory.find(
													x => x.year + ":" + x.projectSerial === value
												);

												this.onChangeInventory(
													obj.idx,
													item?.projectSerial,
													item?.year,
													item ? item.count : undefined
												);
											}}
											disabled={
												(restricted &&
													obj.serialMin &&
													obj.state !== StatusEnum.Registered &&
													!obj.edited) ||
												isEstimated ||
												isReduced ||
												readOnly
											}
											value={
												obj.state === StatusEnum.Retired
													? "other"
													: obj.inventoryYear + ":" + obj.inventoryProject
											}
										>
											{/* Element to be show after select was made */}
											<option />
											{obj.inventoryYear && (
												<option
													value={
														obj.inventoryYear + ":" + obj.inventoryProject
													}
												>
													{" "}
													{this.props.projectNames[obj.inventoryProject]}-
													{obj.inventoryYear}{" "}
												</option>
											)}
											{/* Remaning Inventory Elements */}
											{this.props.inventory &&
												this.props.inventory.sort(sortByProjectSerial).map((x, idx) => (
													<option
														value={x.year + ":" + x.projectSerial}
														key={x.year + ":" + x.projectSerial}
													>
														{x.projectName}-{x.year} ({x.count}t)
													</option>
												))}
											<option value="other">Other</option>
										</Input>
									)}
								{(obj.state === StatusEnum.Transferred) && (
									<Input
										type="select"
										id={`transferred-${obj.idx}`}
										style={{ minWidth: 0 }}
										//value={obj.state}
										onChange={e => {
											const value = e.target.value;

											this.onChangeTransferred(
												obj.idx,
												value,
												10
											);
										}}
										disabled={
											(restricted &&
												obj.serialMin &&
												obj.state !== StatusEnum.Registered &&
												!obj.edited) ||
											isEstimated ||
											isReduced ||
											readOnly
										}
										value={
											obj.transferredTo
										}
									>

										{/* Remaning Inventory Elements */}

										{this.props.entities &&
											Object.entries(this.props.entities).map((k, v) => (
												<option
													value={k[1].organizationSerial}
													key={k[1].organizationSerial}
												>
													{k[0]}
												</option>
											))}
										<option value="other">Other</option>
									</Input>
								)}

								{(obj.state === StatusEnum.Retired ||
									obj.state === StatusEnum.Cancelled) && (
										<input
											name="retiredAgainst"
											id={`comment-${obj.idx}`}
											onChange={e => this.onChangeNote(obj.idx, e)}
											value={obj.retiredAgainst || ""}
											className="form-control ml-1"
											style={{ minWidth: 0 }}
											readOnly={
												(restricted &&
													obj.serialMin &&
													obj.state !== StatusEnum.Registered &&
													!obj.edited) ||
												readOnly
											}
										/>
									)}
							</div>
						</td>
						<td className="align-top">
							{obj.serialMin ? (
								<React.Fragment>
									{obj.serialMin}{" "}
									{obj.count > 1 &&
										" to " +
										splitSerial(calcSerial(obj.serialMin, obj.count - 1))
											.serial}
								</React.Fragment>
							) : (
								""
							)}
						</td>
					</tr>
				)}
			</React.Fragment>
		);
	}

	render() {
		//({ onChange, erRow, erUpdate, value } = this.props);
		//const onChange = this.props.onChange;
		const erRow = this.props.value.map((obj, idx) => ({ idx: idx, ...obj }));

		const years = this.props.value
			.map(obj => obj.year)
			.filter(onlyUnique)
			.reverse();

		const restricted = this.props.restricted;
		const isInventory = this.props.value.some(x => x.state === StatusEnum.Inventory);

		return (
			<div className="mb-3">
				<Row>
					{/* <Col md="4">
						<DatePickerGroup
							name="updateDate"
							label="Updated On"
							onChange={erUpdate}
							value={value}
						/>
					</Col> */}
				</Row>
				<Row>
					<Col md={isInventory ? "12" : "10"}>
						<div className="erBorder">
							<Table borderless size="sm">
								<thead>
									<tr>
										<th
											colSpan={2}
											style={{
												width:
													"1px" /* Keep the year colum as small as possible*/
											}}
										>
											Year
											{!isInventory && (
												<Tooltip
													left={true}
													name="er_year"
													content={TextDictionary.Tooltip.ERInventoryYear}
												/>
											)}
										</th>

										<th>
											Tonnes CO<sub>2</sub>e
										</th>
										{!isInventory && (
											<th>
												Comment / <br /> Retired Against
												<Tooltip
													left={true}
													name="er_comment"
													content={
														TextDictionary.Tooltip.ERRetiredAgainst
													}
												/>
											</th>
										)}
										{this.props.showSerial && <th>Serial Range</th>}
									</tr>
								</thead>
								<tbody>
									{years.map(year => {
										const elements = erRow.filter(x => x.year === year);
										const isOpen =
											elements[0].isOpen ||
											(elements[0].isOpen === undefined &&
												elements.some(x => this.props.error[x.errorIdx]));
										return (
											<React.Fragment key={year}>
												{this.renderYearOverview(
													elements[0],
													erRow,
													restricted,
													isOpen
												)}
												{erRow
													.filter(x => x.year === year)
													.map(obj =>
														this.renderRow(
															obj,
															erRow,
															restricted,
															isOpen
														)
													)}
											</React.Fragment>
										);
									})}
								</tbody>
							</Table>
						</div>
					</Col>
				</Row>
				{/* Test: 
					<p className="m-0">{JSON.stringify(obj, null, 2)}</p>
				))}
				{this.splitEmissions(this.props.value).draftEmissionReductions.map(obj => (
					<p className="m-0">{JSON.stringify(obj, null, 2)}</p>
				))} 
				<pre>{JSON.stringify(this.splitEmissions(this.props.value), null, 2)}</pre>

				Combined:
				{this.combineEmissions(this.splitEmissions(this.props.value)).map(obj => (
					<p className="m-0">{JSON.stringify(obj, null, 2)}</p>
				))} */}
			</div>
		);
	}
}

class SplitEmissionReduction extends Component {
	render() {
		if (!this.props.value) return null;
		return (
			<EmissionReduction
				value={CombineEmissions(this.props.value)}
				onChange={x => this.props.onChange(SplitEmissions(x))}
				restricted={this.props.restricted}
				readOnly={this.props.readOnly}
				error={this.props.error || {}}
				showSerial={this.props.showSerial}
				inventory={this.props.inventory}
				projectNames={this.props.projectNames}
				entities={this.props.entities}
				isOffsets={this.props.isOffsets}
				isTransferred={this.props.isTransferred}
			/>
		);
	}
}

export default SplitEmissionReduction;
