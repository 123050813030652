import React, { Component } from "react";
import RequestItem from "./RequestItem";

class RequestList extends Component {
	render() {
		return (
			<div>
				<div className="listColumnsRequests headings">
					<p>Organization</p>
					<p>Email</p>
				</div>
				<div>
					<ul className="listRows">
						{this.props.requests.map(request => (
							<RequestItem
								key={request.serial}
								id={request.serial}
								email={request.email}
								org={request.organization}
								approve={this.props.handleApproveClick}
								reject={this.props.handleRejectClick}
								projects={request.registrationData && request.registrationData.projects}		
								inventory={request.registrationData && request.registrationData.inventory}						
								comment={request.registrationData && request.registrationData.comment}
							/>
						))}
					</ul>
				</div>
			</div>
		);
	}
}
export default RequestList;
