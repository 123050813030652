import { GET_DRAFTS, DRAFTS_LOADING } from "../actions/types";

const initialState = {
	drafts: null,
	draft: null,
	loading: false
};

export default function(state = initialState, action) {
	switch (action.type) {
		case GET_DRAFTS:
			return {
				...state,
				drafts: action.payload,
				loading: false
			};
		// case GET_DRAFT:
		// 	return {
		// 		...state,
		// 		draft: action.payload,
		// 		loading: false
		// 	};
		case DRAFTS_LOADING:
			return {
				...state,
				loading: true
			};
		default:
			return state;
	}
}
