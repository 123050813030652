import React, { Component } from "react";
import { Link } from "react-router-dom";
import axios from "axios";

import Spinner from "../common/Spinner";

import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Input } from "reactstrap";
import { CORE_API_ROOT } from "../../config/api-config";
import ResetPasswordButton from "./ResetPasswordButton";

class UserDetail extends Component {
	constructor(props) {
		super(props);
		this.state = {
			edit: false,
			contactName: this.props.contactName,
			contactTel: this.props.contactTel,
			balance: this.props.balance,
			inventoryBalance: 22, //this.props.inventoryBalance,
			tokenModal: false,
			inventorModal: false,
			handoverModal: false,
			micrositeBalance: 0,
			micrositeModal: false,
			email: "",
			name: "",
		};
	}
	userData = () => {
		console.log("userdata");
		this.props.getUserData(this.props.userId);
	};
	onChange = (e) => {
		this.setState({ [e.target.name]: e.target.value });
	};
	handleCancel = () => {
		this.setState({ edit: false });
	};
	handleEdit = () => {
		this.setState({ edit: true });
	};
	// handleEditSave = () => {
	// 	axios({
	// 		method: "POST",
	// 		url: "/api/admin/users/editProfile",
	// 		data: {
	// 			id: this.props.userId,
	// 			contactName: this.state.contactName,
	// 			contactTel: this.state.contactTel,
	// 			projectTokens: this.state.balance
	// 		}
	// 	}).then(res => {
	// 		this.userData();
	// 		this.setState({ edit: false });
	// 	});
	// };

	updateProjectTokens = () => {
		axios({
			method: "POST",
			url: `${CORE_API_ROOT}/users/${this.props.userId}/updateProjectTokens`,
			data: {
				tokens: this.state.balance,
			},
		})
			.then((res) => {
				this.userData();
			})
			.catch((err) => alert("request failed"))
			.finally(() => {
				this.toggleTokenModal();
			});
	};

	toggleTokenModal = () => {
		this.setState({ tokenModal: !this.state.tokenModal });
	};

	updateInventoryTokens = () => {
		axios({
			method: "POST",
			url: `${CORE_API_ROOT}/users/${this.props.userId}/updateInventoryTokens`,
			data: {
				tokens: this.state.inventoryBalance,
			},
		})
			.then((res) => {
				this.userData();
			})
			.catch((err) => alert("request failed"))
			.finally(() => {
				this.toggleInventoryModal();
			});
	};

	toggleInventoryModal = () => {
		this.setState({ inventoryModal: !this.state.inventoryModal });
	};

	updateMicrositeTokens = () => {
		axios({
			method: "POST",
			url: `${CORE_API_ROOT}/users/${this.props.userId}/updateMicrositeTokens`,
			data: {
				tokens: this.state.micrositeBalance,
			},
		})
			.then((res) => {
				this.userData();
			})
			.catch((err) => alert("request failed"))
			.finally(() => {
				this.toggleMicrositeModal();
			});
	};

	toggleMicrositeModal = () => {
		this.setState({ micrositeModal: !this.state.micrositeModal });
	};

	doHandover = () => {
		axios({
			method: "POST",
			url: `${CORE_API_ROOT}/users/${this.props.userId}/handover`,
			data: {
				email: this.state.email,
				name: this.state.name,
			},
		})
			.then((res) => {
				this.userData();
			})
			.catch((err) => alert("request failed"))
			.finally(() => {
				this.toggleHandoverModal();
			});
	};

	toggleHandoverModal = () => {
		this.setState({ handoverModal: !this.state.handoverModal });
	};

	resetPassword = () => {
		this.props.handleResetPassword(this.props.email);
	};
	getProjectsList = (projects) => {
		return projects ? (
			projects.map((project) => (
				<li key={project.projectSerial}>
					<Link to={`/admin/project/${project._id}`}>{project.title}</Link>
				</li>
			))
		) : (
			<li>
				<p>no projects</p>
			</li>
		);
	};
	disableAccount = () => {
		this.props.handleDisableAccount(this.props.userId);
	};
	activateAccount = () => {
		this.props.handleActivateAccount(this.props.userId);
	};
	render() {
		return (
			<div>
				{this.props.loading ? (
					<Spinner />
				) : (
					<div className="details">
						<div
							className={
								!this.props.isActive
									? "imgOrgContainer mb-4 disabled"
									: "imgOrgContainer mb-4"
							}
						>
							<div className="img">
								<img src={this.props.logo ? this.props.logo : ""} alt="" />
							</div>
							<div className="org">
								<p>{this.props.org}</p>
								{/* {this.props.isActive ? (
									this.state.edit ? (
										<p>Editing...</p>
									) : (
										<p onClick={this.handleEdit}>Edit Account</p>
									)
								) : (
									<p>Account Disabled</p>
								)} */}
							</div>
						</div>
						<div
							className={
								!this.props.isActive
									? "infoContainer mb-4 disabled"
									: "infoContainer mb-4"
							}
						>
							{this.state.edit ? (
								<input
									type="text"
									name="contactName"
									id="contactName"
									value={this.state.contactName}
									onChange={this.onChange}
								/>
							) : (
								<p>{this.props.contactName ? this.props.contactName : "N/A"}</p>
							)}
							{this.state.edit ? (
								<input
									type="text"
									name="contactTel"
									id="contactTel"
									value={this.state.contactTel}
									onChange={this.onChange}
								/>
							) : (
								<p>{this.props.contactTel ? this.props.contactTel : "N/A"}</p>
							)}
							<p>{this.props.email}</p>
						</div>
						<div className={!this.props.isActive ? "mb-4 disabled" : "mb-4"}>
							<p>
								Listed Projects (
								{
									// this.props.projects.filter(project => {
									// 	return project.status === 2;
									// }).length
								}
								)
							</p>
							<ul>
								{/* {this.getProjectsList(
									this.props.projects.filter(project => {
										return project.status === 2;
									})
								)} */}
							</ul>
						</div>
						<div className={!this.props.isActive ? "mb-4 disabled" : "mb-4"}>
							<p>
								Submitted Projects (
								{
									// this.props.projects.filter(project => {
									// 	return project.status === 0;
									// }).length
								}
								)
							</p>
							<ul>
								{/* {this.getProjectsList(
									this.props.projects.filter(project => {
										return project.status === 0;
									})
								)} */}
							</ul>
						</div>
						<div className={!this.props.isActive ? "mb-4 disabled" : "mb-4"}>
							<p>
								Draft Projects (
								{
									// this.props.projects.filter(project => {
									// 	return project.status === 1;
									// }).length
								}
								)
							</p>
							<ul>
								{/* {this.getProjectsList(
									this.props.projects.filter(project => {
										return project.status === 1;
									})
								)} */}
							</ul>
						</div>
						<div className={!this.props.isActive ? "mb-4 disabled" : "mb-4"}>
							<p>
								Project Tokens: {this.props.balance}
								<Button
									size="sm"
									className="ml-2"
									color="primary"
									onClick={(_) => {
										this.setState({
											tokenModal: true,
											balance: this.props.balance,
										});
									}}
								>
									+
								</Button>
							</p>

							<Modal isOpen={this.state.tokenModal} toggle={this.toggleTokenModal}>
								<ModalHeader toggle={this.toggleTokenModal}>
									Update Tokens
								</ModalHeader>
								<ModalBody>
									New Tokens
									<Input
										type="number"
										onChange={(e) => this.setState({ balance: e.target.value })}
										value={this.state.balance}
									/>
								</ModalBody>
								<ModalFooter>
									<Button color="primary" onClick={this.updateProjectTokens}>
										Update
									</Button>{" "}
									<Button color="secondary" onClick={this.toggleTokenModal}>
										Cancel
									</Button>
								</ModalFooter>
							</Modal>

							<p>
								Inventory:{" "}
								{this.props.inventoryBalance > 0 ? "Enabled" : "Disabled"}
								<Button
									size="sm"
									className="ml-2"
									color="primary"
									onClick={(_) => {
										this.setState({
											inventoryModal: true,
											inventoryBalance: this.props.inventoryBalance,
										});
									}}
								>
									+
								</Button>
							</p>

							<Modal
								isOpen={this.state.inventoryModal}
								toggle={this.toggleInventoryModal}
							>
								<ModalHeader toggle={this.toggleInventoryModal}>
									Update Inventory Tokens
								</ModalHeader>
								<ModalBody>
									New Tokens
									<Input
										type="number"
										onChange={(e) =>
											this.setState({ inventoryBalance: e.target.value })
										}
										value={this.state.inventoryBalance}
									/>
								</ModalBody>
								<ModalFooter>
									<Button color="primary" onClick={this.updateInventoryTokens}>
										Update
									</Button>{" "}
									<Button color="secondary" onClick={this.toggleInventoryModal}>
										Cancel
									</Button>
								</ModalFooter>
							</Modal>

							<p>
								Microsite:{" "}
								{this.props.micrositeBalance > 0 ? "Enabled" : "Disabled"}
								<Button
									size="sm"
									className="ml-2"
									color="primary"
									onClick={(_) => {
										this.setState({
											micrositeModal: true,
											micrositeBalance: this.props.micrositeBalance,
										});
									}}
								>
									+
								</Button>
							</p>

							<Modal
								isOpen={this.state.micrositeModal}
								toggle={this.toggleMicrositeModal}
							>
								<ModalHeader toggle={this.toggleMicrositeModal}>
									Update Microsite Tokens
								</ModalHeader>
								<ModalBody>
									New Tokens
									<Input
										type="number"
										onChange={(e) =>
											this.setState({ micrositeBalance: e.target.value })
										}
										value={this.state.micrositeBalance}
									/>
								</ModalBody>
								<ModalFooter>
									<Button color="primary" onClick={this.updateMicrositeTokens}>
										Update
									</Button>{" "}
									<Button color="secondary" onClick={this.toggleMicrositeModal}>
										Cancel
									</Button>
								</ModalFooter>
							</Modal>
						</div>

						<Button
							size="sm"
							className="ml-2"
							color="primary"
							onClick={(_) => {
								this.setState({
									handoverModal: true,
									email: "",
									name: "",
								});
							}}
						>
							Handover Account
						</Button>

						<Modal isOpen={this.state.handoverModal} toggle={this.toggleHandoverModal}>
							<ModalHeader toggle={this.toggleHandoverModal}>
								Handover Account
							</ModalHeader>
							<ModalBody>
								Name
								<Input
									onChange={(e) => this.setState({ name: e.target.value })}
									value={this.state.name}
								/>
								Email
								<Input
									type="email"
									onChange={(e) => this.setState({ email: e.target.value })}
									value={this.state.email}
								/>
							</ModalBody>
							<ModalFooter>
								<Button color="primary" onClick={this.doHandover}>
									OK
								</Button>{" "}
								<Button color="secondary" onClick={this.toggleHandoverModal}>
									Cancel
								</Button>
							</ModalFooter>
						</Modal>

						<ResetPasswordButton userId={this.props.userId} />

						<Button
							size="sm"
							className="ml-2 mt-2"
							color="primary"
							onClick={() => {
								if (
									window.confirm(
										"Do you really want to permanently remove user " +
											this.props.email +
											"?"
									)
								) {
									axios({
										method: "Delete",
										url: `${CORE_API_ROOT}/users/delete?email=${encodeURIComponent(
											this.props.email
										)}`,
									})
										.then((res) => {
											alert("Deleted user, please refresh");
											//todo clear user list
										})
										.catch(() => {
											alert("Failed to delete user");
										});
								}
							}}
						>
							Archive User
						</Button>

						<Button
							size="sm"
							className="ml-2 mt-2"
							color="primary"
							onClick={() => {
								
									axios({
										method: "Post",
										url: `${CORE_API_ROOT}/users/impersonate?userId=${this.props.userId}`,
									})
										.then((res) => {
											const { token } = res.data;
											localStorage.setItem("jwtToken", token);
											alert("Please refresh");
											//todo clear user list
										})
										.catch(() => {
											alert("Failed to impersonate");
										});
								
							}}
						>
							Impersonate
						</Button>
						<div className="password mb-4">
							{/* {this.props.isActive ? (
								this.state.edit ? (
									<p onClick={this.handleEditSave}>Save</p>
								) : (
									<p onClick={this.disableAccount} className="disableButton">
										Disable Account
									</p>
								)
							) : (
								<p onClick={this.activateAccount} className="activateButton">
									Activate Account
								</p>
							)} */}
						</div>
					</div>
				)}
			</div>
		);
	}
}
export default UserDetail;
