import React, { Component } from "react";
import Spinner from "../../components/common/Spinner";

import ContentBox from "../../components/common/ContentBox";
import Axios from "axios";
import { CORE_API_ROOT } from "../../config/api-config";

class ProjectHistory extends Component {
	componentDidMount() {
		Axios({
			method: "GET",
			url: `${CORE_API_ROOT}/projects/${this.props.match.params.id}/history`
		})
			.then(res => {
				console.log(res);
				this.setState({ history: res.data });
			})
			.catch(err => {
				console.log(err);
				alert("Error");
			});
	}

	render() {
		const history = this.state && this.state.history;

		if (!history) return <Spinner />;

		return (
			<React.Fragment>
				<ContentBox title="Project History" width="12" />
				{history.reverse().map((diff, idx) => {
					const date = diff.changeDate;
					const accountHolder = diff.accountHolder;
					delete diff.changeDate;
					delete diff.accountHolder;
					return (
						<div key={idx}>
							<h5>
								Change on {date} by <b>{accountHolder}</b>
							</h5>
							<pre>{JSON.stringify(diff, null, 2)}</pre>
						</div>
					);
				})}
			</React.Fragment>
		);
	}
}

export { ProjectHistory };
