import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

// import Spinner from "../common/Spinner";
import Spinner from "../../components/common/Spinner";
import { getUserProjects, getUserProjectTokens } from "../../actions/projectActions";
import { getCurrentProfile } from "../../actions/profileActions";
import { clearErrors } from "../../actions/errorActions";
import { redirect } from "../../actions/authActions";
import ContentBox from "../../components/common/ContentBox";
import { Table, Input } from "reactstrap";
import { Link } from "react-router-dom";
import { StatusEnum, getEmissions } from "../../lib/Emissions";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "@fortawesome/free-solid-svg-icons";
import { getSymbol } from "../../lib/Symbols";

import { OverviewGraph } from "./OverviewGraph";
import { getPublicProjects } from "../../actions/projectActions";
import { ShareButton } from "../../components/common/ShareButton";

const projectedColor = "#C9E5C9";
const registeredColor = "#5e9841";
const retiredColor = "#FBC12F";

function getSimpleSymbol(status) {
	if (status === StatusEnum.Projected)
		return <FontAwesomeIcon style={{ color: "#8884d8" }} icon={faCircle} />;
	if (status === StatusEnum.Registered)
		return <FontAwesomeIcon style={{ color: "#82ca9d" }} icon={faCircle} />;
	if (status === StatusEnum.Cancelled)
		return <FontAwesomeIcon style={{ color: "tomato" }} icon={faCircle} />;
	if (status === StatusEnum.Retired)
		return <FontAwesomeIcon style={{ color: "#ffc658" }} icon={faCircle} />;
	if (status === "Remaining") {
		return <FontAwesomeIcon style={{ color: "#82ca9d" }} icon={faCircle} />;
	}

	// for overview
	if (status === "RegisteredOverview") {
		return <FontAwesomeIcon style={{ color: registeredColor }} icon={faCircle} />;
	}
	if (status === "ProjectedOverview") {
		return <FontAwesomeIcon style={{ color: projectedColor }} icon={faCircle} />;
	}
	if (status === "RetiredOverview") {
		return <FontAwesomeIcon style={{ color: retiredColor }} icon={faCircle} />;
	}
}

function ShowInOverview(project) {
	if (project?.programType === "Inventory") return false;
	//if (project.programType === ProgramTypeEnum.PurchasedOffsets) return false;
	if (project?.programType === "Infrastructure Canada") return false;

	return true;
}

class Overview extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showProjects: true,
		};
	}

	componentDidMount() {
		this.props.getCurrentProfile(this.props.user.id);
		this.props.getUserProjects(this.props.user.certserialnumber);
		this.props.getUserProjectTokens();
		this.props.clearErrors();
		this.props.getPublicProjects();

		this.setState({
			loading: false,
		});
	}
	componentWillUnmount() {}

	componentDidUpdate() {
		// if (!this.isLoaded()) {
		// 	return;
		// }
		// if (
		// 	!this.props.profile.profile.contactName ||
		// 	!this.props.profile.profile.mailingAddress ||
		// 	!this.props.profile.profile.contactTel
		// ) {
		// 	this.props.redirect("/profile/edit", this.props.history);
		// 	return;
		// }
		// if (this.props.userProjects.projects.length <= 0) {
		// 	// No projects yet, guide the user to creating one
		// 	this.props.redirect("/create-project", this.props.history);
		// 	return;
		// }
	}

	isLoadedPublic() {
		const { publicProjects } = this.props.publicProjects;

		if (publicProjects == null || publicProjects.loading) {
			return false;
		} else {
			return true;
		}
	}

	isLoaded() {
		const { loading } = this.state;
		const { projects } = this.props.userProjects;
		const { profile } = this.props.profile;
		const { publicProjects } = this.props.publicProjects;

		if (
			loading ||
			profile == null ||
			profile.loading ||
			projects == null ||
			projects.loading ||
			projects.tokens_loading ||
			publicProjects == null ||
			publicProjects.loading
		) {
			return false;
		} else {
			return true;
		}
	}

	render() {
		const showPublic = this.props.match.path === "/overview/:id";

		let projects;
		let loaded;
		if (showPublic) {
			projects = this.props.publicProjects.publicProjects
				? this.props.publicProjects.publicProjects.filter(
						(x) => x.projectSerial.split("-")[0] === this.props.match.params.id
				  )
				: undefined;
			loaded = this.isLoadedPublic();
		} else {
			projects = this.props.userProjects.projects;
			loaded = this.isLoaded();
		}

		//const { projects } = this.props.userProjects;

		if (!loaded || !projects) {
			return <Spinner />;
		}

		const e2 = getEmissions(projects);
		const emissionArray = Object.keys(e2).map((v) => e2[v]);

		const logo =
			(projects.length > 0 && projects[0].organizationLogo) ||
			(this.props.profile && this.props.profile.profile && this.props.profile.profile.logo);

		const orgName =
			(projects.length > 0 && projects[0].organization) ||
			(this.props.profile &&
				this.props.profile.profile &&
				this.props.profile.profile.organization);

		//const orgName = projects.length > 0 && projects[0].organization;



		return (
			<ContentBox
				title={
					<div>
						<p className="mb-0">
							{logo && (
								<img
									src={logo}
									alt=""
									style={{ maxHeight: 80, maxWidth: 150, borderRadius: "5%" }}
									className="mr-2"
								/>
							)}
							Emission Reductions Overview{" "}
							{showPublic || (
								<ShareButton
									url={window.location + this.props.user.certserialnumber}
								/>
							)}
						</p>
					</div>
				}
				subtitle={
					<React.Fragment>
						This page provides an overview of the {orgName} emission reduction (ERs)
						projects in the registry. The chart shows the projected and achieved ERs, as
						well as any purchased offsets that were added to the Registry.
					</React.Fragment>
				}
				width="12"
			>
				<OverviewGraph height="300px" emissionArray={emissionArray} />

				<div className="mt-5 ml-4">
					<Input
						className="pl-3"
						type="checkbox"
						checked={this.state.showProjects}
						onChange={(e) => {
							this.setState({ showProjects: e.target.checked });
						}}
					/>{" "}
					Show Project Breakdown
				</div>

				<Table striped size="sm" borderless className="mt-4">
					<thead>
						<tr>
							<th>Vintage</th>
							<th>{getSimpleSymbol("ProjectedOverview")} Projected</th>
							<th>{getSimpleSymbol("RegisteredOverview")} Registered</th>
							<th>{getSimpleSymbol("RetiredOverview")} Retired</th>
							<th> Remaining</th>
							<th>YTD Not Retired</th>
						</tr>
					</thead>
					<tbody>
						{Object.keys(e2)
							.reverse()
							.map((year, idx) => (
								<React.Fragment key={year}>
									<tr
										key={year}
										style={
											this.state.showProjects
												? { backgroundColor: "#F2F2F2" }
												: {}
										}
									>
										<td>
											<b>{year}</b>
										</td>
										<td>
											<b>
												{getSymbol(StatusEnum.Projected)}{" "}
												{e2[year].projected}
											</b>
										</td>
										<td>
											<b>
												{getSymbol(StatusEnum.Registered)}{" "}
												{e2[year].registered}
											</b>
										</td>
										<td>
											<b>
												{getSymbol(StatusEnum.Retired)} {e2[year].retired}
											</b>
										</td>
										<td>
											<b>
												{getSymbol("Remaining")} {e2[year].remaining}
											</b>
										</td>
										<td>
											<b>
												{Object.keys(e2)
													.map((x) => (x <= year ? e2[x].remaining : 0))
													.reduce((a, b) => a + b)}
											</b>
										</td>
									</tr>

									{this.state.showProjects &&
										Object.keys(e2[year].projects)
											.filter(
												(projectSerial) =>
													ShowInOverview(
														projects.find(
															(p) => p.projectSerial === projectSerial
														)
													) === true
											)
											.map((projectSerial) => (
												<tr
													key={year + projectSerial}
													style={
														this.state.showProjects
															? { backgroundColor: "white" }
															: {}
													}
												>
													<td className="pl-4">
														<Link
															to={
																showPublic
																	? `/projects/${projectSerial}`
																	: `/profile/drafts/${projectSerial}`
															}
														>
															{
																projects.find(
																	(p) =>
																		p.projectSerial ===
																		projectSerial
																)?.title
															}
														</Link>
													</td>
													<td className="">
														{e2[year].projects[projectSerial].projected}
													</td>
													<td className="">
														{
															e2[year].projects[projectSerial]
																.registered
														}
													</td>
													<td className="">
														{e2[year].projects[projectSerial].retired}
													</td>
													<td className="">
														{e2[year].projects[projectSerial].remaining}
													</td>
													<td className="">
														{Object.keys(e2)
															.map((x) =>
																x <= year &&
																e2[x].projects[projectSerial]
																	? e2[x].projects[projectSerial]
																			.remaining
																	: 0
															)
															.reduce((a, b) => a + b)}
													</td>
												</tr>
											))}
								</React.Fragment>
							))}
					</tbody>
				</Table>
			</ContentBox>
		);
	}
}
Overview.propTypes = {
	getCurrentProfile: PropTypes.func.isRequired,
	getUserProjects: PropTypes.func.isRequired,
	user: PropTypes.object.isRequired,
	userProjects: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
	profile: state.profile,
	user: state.auth.user,
	userProjects: state.project,
	userTokens: state.project.projectTokens,
	settings: state.settings,
	publicProjects: state.project,
});
export default connect(mapStateToProps, {
	getUserProjects,
	getCurrentProfile,
	getUserProjectTokens,
	redirect,
	clearErrors,
	getPublicProjects,
})(Overview);
