import {
	GET_REQUESTS,
	GET_USERS,
	GET_USER_DATA,
	GET_ADMIN_PROJECTS,
	UPDATE_ADMIN_PROJECTS,
	CLEAR_USER_DATA,
	SET_USERS_LOADING,
	SET_REQUESTS_LOADING,
	SET_PROJECTS_LOADING,
	SET_USER_DATA_LOADING
} from "../actions/types";

const initialState = {
	loading: false,
	requests: null,
	users: null,
	userData: null,
	projects: null,
	projectOccurrences: null,
	usersLoading: false,
	requestsLoading: false,
	projectsLoading: false,
	userDataLoading: false
};

export default function(state = initialState, action) {
	switch (action.type) {
		case SET_USER_DATA_LOADING:
			return {
				...state,
				userDataLoading: true
			};
		case SET_USERS_LOADING:
			return {
				...state,
				usersLoading: true
			};
		case SET_REQUESTS_LOADING:
			return {
				...state,
				requestsLoading: true
			};
		case SET_PROJECTS_LOADING:
			return {
				...state,
				projectsLoading: true
			};
		case CLEAR_USER_DATA:
			return {
				...state,
				userData: null
			};
		case GET_REQUESTS:
			return {
				...state,
				requests: action.payload,
				requestsLoading: false
			};
		case GET_USERS:
			return {
				...state,
				users: action.payload,
				usersLoading: false
			};
		case GET_USER_DATA:
			return {
				...state,
				userData: action.payload,
				userDataLoading: false
			};
		case GET_ADMIN_PROJECTS:
			return {
				...state,
				projects: action.payload,
				projectOccurrences: action.payload,//.projectOccurrences,
				projectsLoading: false
			};
		case UPDATE_ADMIN_PROJECTS:
			return {
				...state,
				projects: action.payload.projectList,
				projectOccurrences: action.payload.projectOccurrences,
				projectsLoading: false
			};
		default:
			return state;
	}
}
