import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import {
	getRequests,
	getUsers,
	getUserData,
	getAllProjects,
	clearUserData,
	getProjects
} from "../../actions/adminActions";
import ProjectList from "../../components/admin/ProjectList";
import Spinner from "../../components/common/Spinner";

import "./Admin.css";
import ContentBox from "../../components/common/ContentBox";

class Admin extends Component {
	constructor(props) {
		super(props);

		this.handleProjectTabClick = this.handleProjectTabClick.bind(this);
	}
	componentDidMount() {
		this.props.getAllProjects();
		this.props.getUsers();
		
		//window.scrollTo(0, 0);
	}

	handleProjectTabClick(projectStatus) {
		this.props.getProjects(projectStatus);
	}

	render() {
		var projects = this.props.projects;
		const { admin, projectOccurrences, statusFilter } = this.props;
		const showHistory = this.props.showHistory === undefined ? true : this.props.showHistory;

		return (
			<ContentBox title="Admin Projects" width="10">
				{!projects || admin.projectsLoading ? (
					<Spinner />
				) : (
					<ProjectList
						projects={
							statusFilter
								? (projects = projects.filter(x => x.status === statusFilter))
								: projects
						}
						users={this.props.users? this.props.users: []}
						showHistory={showHistory}
						projectOccurrences={projectOccurrences}
						handleProjectTabClick={this.handleProjectTabClick}
					/>
				)}
			</ContentBox>
		);
	}
}

Admin.propTypes = {
	getRequests: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
	admin: state.admin,
	projects: state.admin.projects,
	projectOccurrences: state.admin.projectOccurrences,
	users: state.admin.users,
});

export default connect(
	mapStateToProps,
	{ getRequests, getUsers, getUserData, getAllProjects, clearUserData, getProjects }
)(Admin);
