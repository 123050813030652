import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import axios from "axios";

import {
	getRequests,
	getUsers,
	getUserData,
	getAllProjects,
	clearUserData,
	getProjects
} from "../../actions/adminActions";
import UserDetail from "../../components/admin/UserDetail";
import UserList from "../../components/admin/UserList";
import Spinner from "../../components/common/Spinner";
import { API_ROOT } from "../../config/api-config";

import "./Admin.css";
import ContentBox from "../../components/common/ContentBox";

class Admin extends Component {
	constructor(props) {
		super(props);

		this.handleAccountClick = this.handleAccountClick.bind(this);
		this.handleResetPassword = this.handleResetPassword.bind(this);
		this.handleActivateAccount = this.handleActivateAccount.bind(this);
		this.handleDisableAccount = this.handleDisableAccount.bind(this);
	}
	componentDidMount() {
		this.props.getUsers();
	}
	handleActivateAccount(id) {
		axios({
			method: "POST",
			url: `${API_ROOT}/admin/users/activate`,
			data: {
				id: id
			}
		}).then(res => {
			this.props.getUserData(res.data);
		});
	}
	handleDisableAccount(id) {
		axios({
			method: "POST",
			url: `${API_ROOT}/admin/users/disable`,
			data: {
				id: id
			}
		}).then(res => {
			this.props.getUserData(res.data);
		});
	}
	handleAccountClick(id) {
		this.props.getUserData(id);
	}

	handleResetPassword(email) {
		axios({
			method: "POST",
			url: `${API_ROOT}/users/forgot`,
			data: {
				email: email
			}
		})
			.then(res => {
				console.log(res);
				alert("successfully sent");
			})
			.catch(err => {
				console.log(err);
				alert("Error");
			});
	}
	render() {
		const { users, userData, admin } = this.props;
		return (
			<div>
				{!users || admin.usersLoading ? (
					<Spinner />
				) : (
					<ContentBox title="Admin Users" width="12">
						<div className="listColumnsUser">
							<UserList users={users} accountClick={this.handleAccountClick} />
							{userData ? (
								<UserDetail
									logo={userData.logo}
									org={userData.organization}
									isActive={true}
									contactName={userData.contactName ? userData.contactName : ""}
									contactTel={userData.contactTel ? userData.contactTel : ""}
									projects={userData.projects}
									balance={userData.projectTokens}
									inventoryBalance={userData.inventoryTokens}
									micrositeBalance={userData.micrositeTokens}
									email={userData.email}
									handleResetPassword={this.handleResetPassword}
									handleDisableAccount={this.handleDisableAccount}
									handleActivateAccount={this.handleActivateAccount}
									userId={userData.serial}
									getUserData={this.props.getUserData}
									loading={this.props.admin.userDataLoading}
								/>
							) : null}
						</div>
					</ContentBox>
				)}
			</div>
		);
	}
}

Admin.propTypes = {
	getRequests: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
	admin: state.admin,
	requests: state.admin.requests,
	users: state.admin.users,
	userData: state.admin.userData,
	projects: state.admin.projects,
	projectOccurrences: state.admin.projectOccurrences
});

export default connect(mapStateToProps, {
	getRequests,
	getUsers,
	getUserData,
	getAllProjects,
	clearUserData,
	getProjects
})(Admin);
