import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import {
	AreaChart,
	Area,
	XAxis,
	YAxis,
	CartesianGrid,
	Tooltip,
	ResponsiveContainer,
	Legend,
	Label,
} from "recharts";

import { SmoothStepFunc } from "../../lib/Helpers";
import { addExtraYear } from "../../lib/Emissions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "@fortawesome/free-solid-svg-icons";

const projectedColor = "#C9E5C9"
const registeredColor = "#5e9841"
const retiredColor = "#FBC12F"

const CustomTooltip = ({ active, payload, label }) => {
	const colors = [registeredColor, projectedColor, retiredColor];
	if (active) {
		return (
			<div
				className="recharts-default-tooltip"
				style={{
					margin: 0,
					padding: 10,
					backgroundColor: "rgb(255, 255, 255)",
					border: "1 solid rgb(204, 204, 204)",
					borderColor: "rgb(204, 204, 204)",
					borderStyle: "solid",
					borderWidth: "1px",
					whiteSpace: "nowrap",
				}}
				
			>
				<p className="label mb-1">{`Year: ${label}`}</p>
				{payload?.map((x, i) => (
					<Fragment>
						<p className="desc mb-1">
							<FontAwesomeIcon style={{ color: colors[i] }} icon={faCircle} />{" "}
							{x.name}: {x.value}
						</p>
					</Fragment>
				))}
				{/* <p className="intro">{label)}</p> */}
			</div>
		);
	}

	return null;
};


class OverviewGraph extends Component {

	
	render() {
		const emissionArray = addExtraYear(this.props.emissionArray);
		const type = SmoothStepFunc;

		return (
			<div style={{ width: "100%", height: this.props.height }}>
				<ResponsiveContainer>
					<AreaChart
						data={emissionArray}
						margin={{
							top: 10,
							right: 30,
							left: 0,
							bottom: 0,
						}}
					>
						<CartesianGrid strokeDasharray="3 3" />
						<XAxis dataKey="year" />
						<YAxis>
							<Label
								value="Tonnes CO₂e"
								position="right"
								offset={-50}
								// position="top"
								// offset={-120}
								angle={270}
							/>
						</YAxis>
						<Tooltip content={<CustomTooltip />}/>
						{/* <Legend iconType="circle" /> */}
						<Legend
							payload={[
								{
									inactive: false,
									dataKey: "projected",
									type: "circle",
									color: projectedColor,
									value: "Projected",
								},
								{
									inactive: false,
									dataKey: "registered",
									type: "circle",
									color: registeredColor,
									value: "Registered",
								},								
								{
									inactive: false,
									dataKey: "retired",
									type: "circle",
									color: retiredColor,
									value: "Retired",
								},
							]}
						/>

						<Area
							// name={
							// 	<React.Fragment>
							// 		{getSymbol("Remaining")} Remaining
							// 	</React.Fragment>
							// }
							name="Registered"
							dataKey="registered"
							stackId="2"
							// stroke="#82ca9d"
							// fill="#82ca9d"
							stroke="#5e9841"
							strokeWidth="1.5"
							fill="#94B783"
							fillOpacity="1"
							type={type}
						/>
						<Area
							// name={
							// 	<React.Fragment>
							// 		{getSymbol(StatusEnum.Projected)} Projected
							// 	</React.Fragment>
							// }
							name="Projected"
							dataKey="projected"
							stackId="2"
							stroke="#5e9841"
							fill="#94B783"
							fillOpacity="0.1"
							strokeDasharray="8 8"
							type={type}
						/>
						<Area
							// Icons are not shown in tooltip. Don't use for now.
							// name={
							// 	<React.Fragment>
							// 		{getSymbol(StatusEnum.Retired)} Retired
							// 	</React.Fragment>
							// }
							name="Retired"
							dataKey="retired"
							stackId="1"
							stroke="#FBC12F"
							fill="#F3D078"
							fillOpacity="1"
							strokeWidth="1.5"
							type={type}
						/>
						{/* <Area
							// name={
							// 	<React.Fragment>
							// 		{getSymbol(StatusEnum.Projected)} Projected
							// 	</React.Fragment>
							// }
							name="Inventory"
							type="monotone"
							dataKey="inventory"
							stackId="2"
							stroke="#f94040"
							fill="#ff8484"
						/> */}
					</AreaChart>
				</ResponsiveContainer>
			</div>
		);
	}
}
OverviewGraph.propTypes = {
	height: PropTypes.string.isRequired,
	emissionArray: PropTypes.array.isRequired,
};

export { OverviewGraph };
