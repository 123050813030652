import React, { Component } from "react";
import { Button } from "reactstrap";

class Request extends Component {
	approveClick = () => {
		this.props.approve(this.props.id);
	};
	rejectClick = () => {
		this.props.reject(this.props.id);
	};
	render() {
		return (
			<li className="listColumnsRequests">
				<p>{this.props.org}</p>
				<p>{this.props.email}</p>
				<div>
					<Button
						color="primary"
						size="sm"
						style={{ margin: 3 }}
						onClick={this.approveClick}
					>
						Approve
					</Button>
					<Button
						color="primary"
						size="sm"
						style={{ margin: 3 }}
						onClick={() => {
							if (
								window.confirm("Do you really want to reject " + this.props.email+"?")
							) {
								this.rejectClick();
							}
						}}
					>
						Reject
					</Button>
					{/* <p onClick={this.props.decline}>Decline</p> */}
				</div>

				<p style={{ gridColumn: "span 3" }}>
					Projects: {this.props.projects} Inventory: {this.props.inventory}t Comment:{" "}
					{this.props.comment}
				</p>
			</li>
		);
	}
}

export default Request;
