import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPrint } from "@fortawesome/free-solid-svg-icons";

// import Spinner from "../common/Spinner";
import Spinner from "../../components/common/Spinner";
import { getUserProjects } from "../../actions/projectActions";
import { getCurrentProfile } from "../../actions/profileActions";
import { getSettingsPricing } from "../../actions/settingsActions";
import ProjectItem from "../../components/projects/ProjectItem";
import { CORE_API_ROOT } from '../../config/api-config';

class UserProfile extends Component {
	constructor(props) {
		super(props);
		this.state = {
			selected: 0,
			loading: true,
			projectTokens: 0,
			stripeLoading: true,
			orderAmount: 1,
			projectLength: 0,
			perProjectAmount: 0,
			perProjectCustomAmount: "",
			hasCheckedTerms: false
		};
		this.onStripeUpdate = this.onStripeUpdate.bind(this);
		this.loadStripe = this.loadStripe.bind(this);
		this.decrement = this.decrement.bind(this);
		this.increment = this.increment.bind(this);
		this.onAmountChange = this.onAmountChange.bind(this);
		this.onExtraChange = this.onExtraChange.bind(this);
		this.calcTotal = this.calcTotal.bind(this);
		this.handleCheck = this.handleCheck.bind(this);
	}
	decrement() {
		if (this.state.orderAmount > 1) {
			this.setState({ orderAmount: this.state.orderAmount - 1 });
		}
	}
	increment() {
		this.setState({ orderAmount: this.state.orderAmount + 1 });
	}
	loadStripe(onload) {
		if (!window.StripeCheckout) {
			const script = document.createElement("script");
			script.onload = function() {
				console.log("Stripe script loaded");
				onload();
			};
			script.src = "https://checkout.stripe.com/checkout.js";
			document.head.appendChild(script);
		} else {
			onload();
		}
	}
	// Get users project tokens
	getProjectTokens() {
		axios
			.get(`${CORE_API_ROOT}/users/current`)
			.then(res => this.setState({ projectTokens: res.data.projectTokens }));
	}
	componentDidMount() {
		this.props.getCurrentProfile(this.props.user.id); // ✅
		this.props.getUserProjects(this.props.user.id);
		this.props.getSettingsPricing(pricing => {
			this.setState({
				selected: pricing.data.low,
				perProjectAmount: pricing.data.low
			});
		});
		this.getProjectTokens();
		this.loadStripe(() => {
			this.stripeHandler = window.StripeCheckout.configure({
				key: "pk_test_PLQhaS2QwJKD8QOilDU0SQMH",
				image: "https://stripe.com/img/documentation/checkout/marketplace.png",
				locale: "auto",
				token: token => {
					axios
						.post(`${CORE_API_ROOT}/users/payment`, {
							stripeToken: token.id,
							orderAmount: this.state.orderAmount,
							total: this.calcTotal() * 100
						})
						.then(res =>
							this.setState({
								projectTokens: res.data.projectTokens
							})
						);
				}
			});

			this.setState({
				stripeLoading: false,
				loading: false
			});
		});
	}
	componentWillUnmount() {
		if (this.stripeHandler) {
			this.stripeHandler.close();
		}
	}
	onAmountChange(e) {
		this.setState({
			perProjectAmount: e.target.value,
			selected: e.target.value,
			perProjectCustomAmount: ""
		});
	}
	onExtraChange(e) {
		console.log(e.target.value ? "yes" : "no");
		e.target.value
			? this.setState({
					perProjectCustomAmount: e.target.value,
					selected: "",
					perProjectAmount: ""
			  })
			: this.setState({
					perProjectCustomAmount: "",
					perProjectAmount: "500",
					selected: "500"
			  });
	}

	onStripeUpdate(e) {
		this.stripeHandler.open({
			name: "LC Registry",
			description: "Project Registration Fee",
			panelLabel: "Purchase",
			billingAddress: true,
			zipCode: true,
			amount: this.calcTotal() * 100 * 1.05,
			allowRememberMe: false,
			taxPercent: 5
		});
		e.preventDefault();
	}
	calcTotal() {
		const quantityDollarAmount = this.state.orderAmount;
		const DollarAmount = this.state.perProjectAmount
			? parseInt(this.state.perProjectAmount, 10)
			: parseInt(this.state.perProjectCustomAmount, 10);
		const total = quantityDollarAmount * DollarAmount;
		return total;
	}
	handleCheck(e) {
		this.setState({ hasCheckedTerms: e.target.checked });
	}
	render() {
		const { stripeLoading, loading } = this.state;
		const { projects } = this.props.userProjects;
		const { organization } = this.props.user;
		const { profile } = this.props.profile;
		const { pricing } = this.props.settings;
		let projectItems;
		if (projects === null || loading) {
			projectItems = <Spinner />;
		} else {
			if (projects.length > 0) {
				projectItems = projects.map(project => (
					<ProjectItem
						key={project._id}
						project={project}
						style={{
							width: "49%",
							margin: "0 0 30px 0",
							img: { height: "350px" }
						}}
					/>
				));
			} else {
				projectItems = <h4>No Projects found...</h4>;
			}
		}
		return (
			<div className="profileContainer">
				{profile ? (
					<div>
						<div className="container">
							<p>Organization Name: {organization}</p>
							<Link to="/profile/edit">Edit Account Information</Link>
						</div>
						<div className="container">
							<h1>My Projects</h1>
							<Link to="/profile/drafts">My Drafts</Link>
							<div className="d-flex flex-wrap justify-content-between mt-5 mb-5">
								{projectItems}
							</div>
						</div>
						<p># of Projects Available For Registration: {this.state.projectTokens}</p>
						{this.state.projectTokens > 0 ? (
							<Link className="register-btn" to="/create-project">
								Register a project
							</Link>
						) : (
							<p className="grey-register register-btn">Register a project</p>
						)}
						<div className="d-flex border w-75 mx-auto p-3">
							<div className="buyToken" style={{ width: "50%" }}>
								{stripeLoading ? (
									<p>loading..</p>
								) : (
									<div>
										<div>
											<p>How many projects do you want to pay for?</p>
											<div className="d-flex">
												<p className="text-muted">Number of Projects</p>
												<div className="ml-3">
													<button onClick={this.decrement}>-</button>
													<span>{this.state.orderAmount}</span>
													<button onClick={this.increment}>+</button>
												</div>
											</div>
										</div>
										<div>
											<p>Select a payment option</p>
											<input
												type="radio"
												name="perProjectAmount"
												value={pricing ? pricing.low : "500"}
												onChange={this.onAmountChange}
												checked={
													pricing
														? pricing.low === this.state.selected
														: false
												}
											/>
											${pricing ? pricing.low : "500"}
											<input
												type="radio"
												name="perProjectAmount"
												value={pricing ? pricing.mid : "850"}
												onChange={this.onAmountChange}
												checked={
													pricing
														? pricing.mid === this.state.selected
														: false
												}
											/>
											${pricing ? pricing.mid : "850"}
											<input
												type="radio"
												name="perProjectAmount"
												value={pricing ? pricing.top : "1250"}
												onChange={this.onAmountChange}
												checked={
													pricing
														? pricing.top === this.state.selected
														: false
												}
											/>
											${pricing ? pricing.top : "1250"}
											<br />$
											<input
												type="number"
												name="perProjectAmount"
												onChange={this.onExtraChange}
												min={pricing ? pricing.low : "500"}
												value={this.state.perProjectCustomAmount}
												pattern="(^([0])$)|(^([1-9][0-9]{0,9})$)"
												placeholder={
													pricing
														? `Value greater than $${pricing.low}`
														: "500"
												}
												style={{ width: "200px" }}
											/>
										</div>
									</div>
								)}
							</div>
							<div className="w-50">
								<div className="p-3 border">
									<div className="border-bottom">
										<FontAwesomeIcon icon={faPrint} />
										<p>Total</p>
										<div className="d-flex justify-content-between">
											<p>Project Fee ({this.state.orderAmount})</p>
											<p>CAD {this.calcTotal().toFixed(2)}</p>
										</div>
										<div className="d-flex justify-content-between">
											<p>GST 5%</p>
											<p>CAD {(this.calcTotal() * 0.05).toFixed(2)}</p>
										</div>
									</div>
									<div className="d-flex justify-content-between">
										<p>Total Charged to Card</p>
										<p>CAD {(this.calcTotal() * 1.05).toFixed(2)}</p>
									</div>
								</div>
								<div>
									<p>
										Note: Submissions take approximately 5 business days before
										approval. We will confirm if all project details have been
										provided before adding your project to the registry
									</p>
									<p>
										<input
											type="checkbox"
											name="terms"
											onChange={e => this.handleCheck(e)}
										/>{" "}
										I agree with the{" "}
										<Link to="/terms-and-conditions">terms and conditions</Link>
									</p>
								</div>
								<button
									onClick={this.onStripeUpdate}
									disabled={!this.state.hasCheckedTerms}
								>
									Buy Project Registration Fee
								</button>
							</div>
						</div>
					</div>
				) : (
					<div>
						<div>Update profile before continuing</div>
						<Link to="/profile/edit">Update Profile Information</Link>
					</div>
				)}
			</div>
		);
	}
}
UserProfile.propTypes = {
	getCurrentProfile: PropTypes.func.isRequired,
	getUserProjects: PropTypes.func.isRequired,
	user: PropTypes.object.isRequired,
	userProjects: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
	profile: state.profile,
	user: state.auth.user,
	userProjects: state.project,
	settings: state.settings
});
export default connect(
	mapStateToProps,
	{ getUserProjects, getCurrentProfile, getSettingsPricing }
)(UserProfile);
