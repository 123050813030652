import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { getPublicProjects } from "../../actions/projectActions";
import ContentBox from "../../components/common/ContentBox";
import { Row, Col } from "reactstrap";
import Spinner from "../../components/common/Spinner";
import ProjectsMap from "../../components/ProjectsMap";
import ProjectItem from "../../components/projects/ProjectItem";

class Microsite extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	componentDidMount() {
		this.props.getPublicProjects();
	}

	componentDidUpdate(prevProps) {
		// this.checkForChangedProject(prevProps);
	}

	render() {
		const { publicProjects } = this.props.project;

		if (!publicProjects) {
			return <Spinner></Spinner>;
		}

		const projects = publicProjects.filter(
			(x) => x.projectStatus === "Proposed" && x.visibility === "Public"
		);

		return (
			<React.Fragment>
				<ContentBox title={`Projects Seeking Funding`}>
					<ProjectsMap projects={projects} fitMap={true}></ProjectsMap>
				</ContentBox>
				<ContentBox title={`Projects Seeking Funding`}>
					<Row>
						{projects.map((project) => (
							<Col md="4" sm="6" key={project.projectSerial}>
								<ProjectItem project={project} />
							</Col>
						))}
					</Row>
				</ContentBox>
			</React.Fragment>
		);
	}
}

Microsite.propTypes = {
	getPublicProjects: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({ project: state.project });

export default connect(mapStateToProps, { getPublicProjects })(Microsite);
