import axios from "axios";
import {
	GET_REQUESTS,
	GET_USERS,
	GET_USER_DATA,
	CLEAR_USER_DATA,
	GET_ADMIN_PROJECTS,
	UPDATE_ADMIN_PROJECTS,
	SET_USERS_LOADING,
	SET_REQUESTS_LOADING,
	SET_PROJECTS_LOADING,
	SET_USER_DATA_LOADING
} from "./types";
import { CORE_API_ROOT } from "../config/api-config";

// Register User
export const getRequests = () => dispatch => {
	dispatch(setRequestsLoading());
	axios
		.get(`${CORE_API_ROOT}/users/applicants/`)
		.then(res => {
			dispatch({
				type: GET_REQUESTS,
				payload: res.data
			});
		})
		.catch(err =>
			dispatch({
				type: GET_REQUESTS,
				payload: null
			})
		);
};
export const getUsers = () => dispatch => {
	dispatch(setUsersLoading());
	axios
		.get(`${CORE_API_ROOT}/users`)
		.then(res => {
			dispatch({
				type: GET_USERS,
				payload: res.data
			});
		})
		.catch(err => {
			dispatch({
				type: GET_USERS,
				payload: null
			});
		});
};
export const getUserData = id => dispatch => {
	dispatch(setUserDataLoading());
	axios
		.get(`${CORE_API_ROOT}/users/${id}`)
		.then(userData =>
			dispatch({
				type: GET_USER_DATA,
				payload: userData.data
			})
		)
		.catch(err => {
			dispatch({
				type: GET_USER_DATA,
				payload: null
			});
		});
};
export const clearUserData = () => {
	return {
		type: CLEAR_USER_DATA
	};
};

export const getAllProjects = () => dispatch => {
	dispatch(setProjectsLoading());
	axios
		.get(`${CORE_API_ROOT}/projects`)
		.then(projects => {
			dispatch({
				type: GET_ADMIN_PROJECTS,
				payload: projects.data
			});
		})
		.catch(err => {
			dispatch({
				type: GET_ADMIN_PROJECTS,
				payload: null
			});
		});
};
export const getProjects = projectStatus => dispatch => {
	console.log(projectStatus);
	dispatch(setProjectsLoading());
	axios
		.get(`${CORE_API_ROOT}/projects`)
		.then(projects => {
			if (projectStatus || projectStatus === 0) {
				const filteredProjects = projects.data.projectList.filter(
					project => project.status === projectStatus
				);
				projects.data.projectList = filteredProjects;
			}
			dispatch({
				type: UPDATE_ADMIN_PROJECTS,
				payload: projects.data
			});
		})
		.catch(err => {
			dispatch({
				type: UPDATE_ADMIN_PROJECTS,
				payload: null
			});
		});
};
export const setUsersLoading = () => {
	return { type: SET_USERS_LOADING };
};
export const setRequestsLoading = () => {
	return { type: SET_REQUESTS_LOADING };
};
export const setProjectsLoading = () => {
	return { type: SET_PROJECTS_LOADING };
};
export const setUserDataLoading = () => {
	return { type: SET_USER_DATA_LOADING };
};
