import React, { Component } from "react";
import {
	Carousel,
	CarouselItem,
	CarouselControl,
	CarouselIndicators,
	CarouselCaption
} from "reactstrap";
import { Link } from "react-router-dom";

function LinkIfUrlSet(props) {
	return props.url ? <Link to={props.url}>{props.children}</Link> : props.children;
}

class LCRCarousel extends Component {
	constructor(props) {
		super(props);
		this.state = { activeIndex: 0 };
		this.next = this.next.bind(this);
		this.previous = this.previous.bind(this);
		this.goToIndex = this.goToIndex.bind(this);
		this.onExiting = this.onExiting.bind(this);
		this.onExited = this.onExited.bind(this);
	}

	onExiting() {
		this.animating = true;
	}

	onExited() {
		this.animating = false;
	}

	next() {
		if (this.animating) return;
		const nextIndex =
			this.state.activeIndex === this.props.items.length - 1 ? 0 : this.state.activeIndex + 1;
		this.setState({ activeIndex: nextIndex });
	}

	previous() {
		if (this.animating) return;
		const nextIndex =
			this.state.activeIndex === 0 ? this.props.items.length - 1 : this.state.activeIndex - 1;
		this.setState({ activeIndex: nextIndex });
	}

	goToIndex(newIndex) {
		if (this.animating) return;
		this.setState({ activeIndex: newIndex });
	}

	render() {
		const { activeIndex } = this.state;
		const items = this.props.items;
		const showCaption = this.props.showCaption !== false

		//const slideContent =

		const slides = items.map(item => (
			<CarouselItem onExiting={this.onExiting} onExited={this.onExited} key={item.src}>
				<div style={{ width: "100%", paddingBottom: "75%", background: "gray" }}>
					<LinkIfUrlSet url={item.url}>
						<div style={{ position: "absolute", top: 0, bottom: 0, left: 0, right: 0 }}>
							<img
								className="d-block"
								src={item.src && item.src + ".600.jpg"}
								alt={item.altText}
								style={{
									marginLeft: "auto",
									marginRight: "auto",
									maxWidth: "100%",
									height: "100%"
								}}
							/>
						</div>
					</LinkIfUrlSet>

					{showCaption && <div style={{ position: "absolute", bottom: 0, left: 0, right: 0 }}>
						<CarouselCaption
							captionText=""
							captionHeader={item.caption}
							style={{ background: "rgba(65,117,5,.1)" }}
							className="custom-tag d-block"
						/>
					</div>}
				</div>

				{/* <div className="d-inline-block w-100">
					<div style={{ background: "rgba(100,100,100,1)" }} className="">
						<img
							className="d-block"
							src={item.src}
							alt={item.altText}
							style={{ height: 300, marginLeft: "auto", marginRight: "auto" }}
						/>
					</div>

					<CarouselCaption
						//captionText={item.caption}
						captionHeader={item.caption}
						style={{ background: "rgba(65,117,5,.1)" }}
						className="custom-tag d-block"
					/>

					
				</div>{" "}
				*/}
			</CarouselItem>
		));

		return (
			<div>
				<style>
					{`.custom-tag {
                // max-width: 100%;
                background: rgba(65,117,5,.75);
                position: static;
                bottom: 0
              }`}
				</style>

				<div style={{ width: "100%", paddingBottom: "75%", background: "gray" }}>
					<div style={{ position: "absolute", top: 0, bottom: 0, left: 0, right: 0 }}>
						<Carousel
							activeIndex={activeIndex}
							next={this.next}
							previous={this.previous}
						>
							<CarouselIndicators
								items={items}
								activeIndex={activeIndex}
								onClickHandler={this.goToIndex}
							/>
							{slides}
							<CarouselControl
								direction="prev"
								directionText="Previous"
								onClickHandler={this.previous}
							/>
							<CarouselControl
								direction="next"
								directionText="Next"
								onClickHandler={this.next}
							/>
						</Carousel>
					</div>
				</div>
			</div>
		);
	}
}

export default LCRCarousel;
