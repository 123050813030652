import React, { Component } from "react";
import axios from "axios";
import { API_ROOT } from '../../config/api-config';

class Settings extends Component {
	constructor() {
		super();
		this.state = { id: "" };
		this.onChange = this.onChange.bind(this);
		this.onSave = this.onSave.bind(this);
	}
	componentDidMount() {
		axios.get(`${API_ROOT}/settings`).then(settings => {
			console.log(settings);
			this.setState({
				id: settings.data._id,
				low: settings.data.pricing.low,
				mid: settings.data.pricing.mid,
				top: settings.data.pricing.top
			});
		});
	}
	onChange(e) {
		this.setState({ [e.target.name]: e.target.value });
	}
	onSave() {
		axios.post(`${API_ROOT}/settings`, this.state);
	}
	render() {
		return (
			<div>
				<div>
					<p>Project Pricing</p>
					<div>
						<label htmlFor="low">Low Tier Price</label>
						<input
							name="low"
							id="low"
							type="number"
							value={this.state.low || ""}
							onChange={this.onChange}
						/>
					</div>
					<div>
						<label htmlFor="mid">Mid Tier Price</label>
						<input
							name="mid"
							id="mid"
							type="number"
							value={this.state.mid || ""}
							onChange={this.onChange}
						/>
					</div>
					<div>
						<label htmlFor="top">Top Tier Price</label>
						<input
							name="top"
							id="top"
							type="number"
							value={this.state.top || ""}
							onChange={this.onChange}
						/>
					</div>
				</div>
				<p onClick={this.onSave}>Save</p>
			</div>
		);
	}
}
export default Settings;
