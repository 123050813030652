import axios from "axios";

import { GET_DRAFTS, GET_DRAFT, DRAFTS_LOADING } from "./types";
import { API_ROOT, CORE_API_ROOT } from "../config/api-config"
import { setProjectLoading } from "./projectActions.js"

// Get current User Drafts
export const getDrafts = currentUser => dispatch => {
	dispatch(setDraftsLoading());
	axios
		.get(`${API_ROOT}/projects/drafts`, currentUser)
		.then(res => {
			dispatch({
				type: GET_DRAFTS,
				payload: res.data
			});
		})
		.catch(err =>
			dispatch({
				type: GET_DRAFTS,
				payload: null
			})
		);
};
// Get Draft Project
export const getDraft = (id) => dispatch => {
	dispatch(setProjectLoading());
	axios
		.get(`${CORE_API_ROOT}/projects/${id}`, {
			// params: {
			// 	user
			// }
		})
		.then(res => {
			dispatch({
				type: GET_DRAFT,
				payload: res.data
			});
		})
		.catch(err => {
			dispatch({
				type: GET_DRAFT,
				payload: null
			});
		});
};
// Project loading
export const setDraftsLoading = () => {
	return {
		type: DRAFTS_LOADING
	};
};
