import React, { Component } from "react";
import ContentBox from "../../components/common/ContentBox";

class Terms extends Component {
	render() {
		return (
			<ContentBox title="Terms And Conditions" width="10">
				<p>
					{/* Terms and Conditions Version 1.1 */}
					The Local Carbon Registry and GHG Accounting Services Ltd. (“GHG Accounting”)
					are not, at any time either during or after providing registry services to the
					client, acting as the client’s agent or legal counsel in connection with any
					greenhouse gas emission reduction reporting or reporting requirements. The
					registry services are limited to the aspects related to greenhouse gas
					accounting and registry listing. For greater certainty, GHG Accounting or the
					Local Carbon Registry will not be providing any financial or legal advice as it
					relates to the reporting of greenhouse gas emissions and greenhouse gas
					reduction information to the various entities selected by the client. It is the
					sole responsibility of the client to establish the accuracy, completeness,
					suitability and validity of any information they are providing to any reporting
					entity - whether or not the information in the Local Carbon Registry system is
					used. Furthermore, the client will not hold GHG Accounting or the Local Carbon
					Registry liable for any errors, omissions, or delays in information reported in
					regards to greenhouse gas inventory or greenhouse gas reductions, or any losses
					or damages arising from the disclosure of such information. All registry
					services are provided on an “as-is” basis and the registry is provided as a tool
					for responsible use by the client in accordance with these terms and conditions.
				</p>

				{/* <p>
					<a href="mailto:Support@LocalCarbonRegistry.org">
						Support@LocalCarbonRegistry.org
					</a>
					.
				</p> */}
			</ContentBox>
		);
	}
}

export default Terms;
