import { GET_ERRORS, CLEAR_ONE_ERROR } from "../actions/types";

const initialState = {};

export default function(state = initialState, action) {
	switch (action.type) {
		case GET_ERRORS:
			return action.payload;
		case CLEAR_ONE_ERROR:
			let {[action.payload]: omit, ...res} = state
			return res;
		default:
			return state;
	}
}
