import React from "react";
import { UncontrolledTooltip } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";

const Tooltip = ({ name, content, left, style, largeMargin,  }) => {
	return (
		<React.Fragment>
			<FontAwesomeIcon
                //style={style}
                style={{height:"0.9rem", width:"0.9rem", marginLeft:largeMargin?20:4}}
				className={left ? "" : "float-right"}
				icon={faQuestionCircle}
				color="gray"
                id={"tip_" + name}
                height="1rem"
                width="1rem"
			/>

			<UncontrolledTooltip placement="right" target={"tip_" + name}>
				{content}
			</UncontrolledTooltip>
		</React.Fragment>
	);
};

export default Tooltip;
