import React, { Component } from "react";
import UserItem from "./UserItem";

class UserList extends Component {
	render() {
		return (
			<div className="users">
				<div className="listColumns headings">
					<p>Account Number</p>
					<p>Account Holder</p>
					<p>Organization</p>
					<p>Email</p>
					<p>Roles</p>
				</div>
				<div>
					<ul className="listRows">
						{this.props.users.map((users, idx) => (
							<UserItem
								key={idx}
								id={users.serial}
								serial={users.serial}
								org={users.organization}
								email={users.email}
								// submitted={
								// 	users.projects.filter(project => {
								// 		return project.status === 0;
								// 	}).length
								// }
								// active={
								// 	users.projects.filter(project => {
								// 		return project.status === 2;
								// 	}).length
								// }
								roles={users.roles}
								name={users.name}								
								accountClick={this.props.accountClick}
							/>
						))}
					</ul>
				</div>
			</div>
		);
	}
}
export default UserList;
