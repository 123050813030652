import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

// import Spinner from "../common/Spinner";
import Spinner from "../../components/common/Spinner";
import { getUserProjects, getUserProjectTokens } from "../../actions/projectActions";
import { getCurrentProfile } from "../../actions/profileActions";
import { clearErrors } from "../../actions/errorActions";
import { redirect } from "../../actions/authActions";
import ContentBox from "../../components/common/ContentBox";
import { Table, Button } from "reactstrap";
import { Link } from "react-router-dom";
import { ProgramTypeEnum } from "../../lib/Enums";

//const statusNames = ["Pending Review", "Draft", "Approved", "Rejected"];

class MyProjects extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	componentDidMount() {
		this.props.getCurrentProfile(this.props.user.id);
		this.props.getUserProjects(this.props.user.certserialnumber);
		this.props.getUserProjectTokens();
		this.props.clearErrors();

		this.setState({
			loading: false,
		});
	}
	componentWillUnmount() {}

	componentDidUpdate() {
		if (!this.isLoaded()) {
			return;
		}

		if (
			!this.props.profile.profile.contactName ||
			!this.props.profile.profile.mailingAddress ||
			!this.props.profile.profile.contactTel
		) {
			this.props.redirect("/profile/edit", this.props.history);
			return;
		}
	}

	isLoaded() {
		const { loading } = this.state;
		const { projects } = this.props.userProjects;
		const { profile } = this.props.profile;

		if (
			loading ||
			profile == null ||
			profile.loading ||
			projects == null ||
			projects.loading ||
			projects.tokens_loading
		) {
			return false;
		} else {
			return true;
		}
	}

	render() {
		if (!this.isLoaded()) {
			return <Spinner />;
		}

		const isInventory = this.props.inventory;
		const isOffsets = this.props.offsets;
		let projects;
		const inventoryFilter = (x) => x.programType === "Inventory";
		const offsetsFilter = (x) => x.programType === ProgramTypeEnum.PurchasedOffsets || x.programType === ProgramTypeEnum.TransferredOffsets ;
		const inventoryAndOffsetsFilter = (x) =>
			x.programType === "Inventory" || x.programType === ProgramTypeEnum.PurchasedOffsets || x.programType === ProgramTypeEnum.TransferredOffsets;
		if (isInventory) {
			projects = this.props.userProjects.projects.filter((x) => inventoryFilter(x));
		} else if (isOffsets) {
			projects = this.props.userProjects.projects.filter((x) => offsetsFilter(x));
		} else {
			projects = this.props.userProjects.projects.filter(
				(x) => !inventoryAndOffsetsFilter(x)
			);
		}

		var displaySubTitle = isInventory ? (
			<React.Fragment>
				You can add the GHG inventory managed by your organization.
				<br />
				As a local government, you can add corporate and community wide GHG inventory.
				<br />
				As a company, you can add GHG inventory from your operations or from a corporate
				event (e.g., conference, workshop, etc).
				<br />
			</React.Fragment>
		) : isOffsets ? (
			<React.Fragment>
				You can add purchased offsets, which can be used to retire a specific GHG inventory.
				<br />
			</React.Fragment>
		) : (
			<React.Fragment></React.Fragment>
		);

		return (
			<ContentBox
				title={
					<div>
						{this.props.profile.profile.logo && (
							<img
								src={this.props.profile.profile.logo}
								alt=""
								style={{ maxHeight: 100, width: 100 }}
								className="mr-2"
							/>
						)}
						{this.props.profile.profile.organization}'s{" "}
						{isInventory ? "Inventory" : isOffsets ? "Purchased Offsets" : "Projects"}
					</div>
				}
				width="12"
				subtitle={displaySubTitle}
			>
				{!isInventory && !isOffsets && (
					<div>
						<div
						// className="float-left"
						>
							Number of Projects Available to Register: {this.props.userTokens}
						</div>
						<Button
							color="primary"
							className="mr-3"
							onClick={() => this.props.history.push("/create-project")}
						>
							Add Project
						</Button>
					</div>
				)}
				{isInventory && (
					<div>
						<Button
							color="primary"
							className="mr-3"
							onClick={() => this.props.history.push("/inventory/create")}
						>
							Add Inventory
						</Button>
					</div>
				)}
				{isOffsets && (
					<div>
						<Button
							color="primary"
							onClick={() => this.props.history.push("/inventory/create-offsets")}
						>
							Add Offsets
						</Button>
					</div>
				)}
				<Table striped size="sm" borderless className="mt-4">
					<thead>
						<tr>
							<th>{isInventory || isOffsets ? "Title" : "Project"}</th>
							<th>Date Registered</th>
							<th>Status</th>
							<th />
						</tr>
					</thead>
					<tbody>
						{projects.map((project) => (
							<tr key={project.projectSerial}>
								<td>
									{isOffsets &&
										project.offsetProvider &&
										project.offsetProvider + " - "}
									{project.title}
								</td>
								<td>
									{new Date(Date.parse(project.date)).toLocaleDateString("en-us")}
								</td>
								<td>{project.status}</td>
								<td>
									<Link to={`/profile/drafts/${project.projectSerial}`}>
										View
									</Link>
								</td>
							</tr>
						))}
					</tbody>
				</Table>
			</ContentBox>
		);
	}
}
MyProjects.propTypes = {
	getCurrentProfile: PropTypes.func.isRequired,
	getUserProjects: PropTypes.func.isRequired,
	user: PropTypes.object.isRequired,
	userProjects: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
	profile: state.profile,
	user: state.auth.user,
	userProjects: state.project,
	userTokens: state.project.projectTokens,
	settings: state.settings,
});
export default connect(mapStateToProps, {
	getUserProjects,
	getCurrentProfile,
	getUserProjectTokens,
	redirect,
	clearErrors,
})(MyProjects);
