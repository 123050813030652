import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { BrowserRouter as Router } from "react-router-dom";
import jwt_decode from "jwt-decode";
import setAuthToken from "./utils/setAuthToken";
import { setCurrentUser, logoutUser, setTokenExpirePopup } from "./actions/authActions";
import { clearCurrentProfile } from "./actions/profileActions";
import { getSettingsApiKeys } from "./actions/settingsActions";

import { Provider } from "react-redux";
import store from "./store";

// import Header from "./components/layout/Header";
// import Footer from "./components/layout/Footer";
//import Routes from "./routes";

//import { DefaultLayout } from './container/DefaultLayout';
//import DefaultLayout form from './container/DefaultLayout';
import DefaultLayout from "./container/DefaultLayout";

//import "./Reset.css";
import "./App.scss";

// Check for token
if (localStorage.jwtToken) {
	// Set auth token header auth
	setAuthToken(localStorage.jwtToken);
	// Decode token and get use info and exp
	const decoded = jwt_decode(localStorage.jwtToken);

	// set some helper variables
	decoded.isAdmin =
		decoded.role === "Administrator" ||
		(decoded.role && decoded.role.indexOf("Administrator") >= 0);

	// Set user and isAuthenticated
	store.dispatch(setCurrentUser(decoded));

	// Check for expired token
	const currentTime = Date.now() / 1000;
	const expireTime = (decoded.exp - currentTime) * 1000;

	if (decoded.exp > currentTime) {
		setTimeout(function() {
			store.dispatch(setTokenExpirePopup(true));
		}, expireTime);
	}

	if (decoded.exp < currentTime) {
		// Logout user
		store.dispatch(logoutUser());
		// TODO: Clear current Profile
		store.dispatch(clearCurrentProfile());
		// Redirect to login
		// window.location.href = "/login";
	}
}
store.dispatch(getSettingsApiKeys());

//import React from "react";

class ScrollToTopInternal extends React.Component {
	componentDidUpdate(prevProps) {
		if (this.props.location.pathname !== prevProps.location.pathname) {
			window.scrollTo(0, 0);
		}
	}

	render() {
		return null;
	}
}

const ScrollToTop = withRouter(ScrollToTopInternal);

class App extends Component {
	render() {
		return (
			<Provider store={store}>
				<Router>
					<React.Fragment>
						<ScrollToTop />
						<DefaultLayout />
					</React.Fragment>
				</Router>
			</Provider>
		);
	}
}

export default App;
