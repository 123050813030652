import { ProjectStatusEnum } from "./Enums";

var StatusEnum = Object.freeze({
	none: "",
	Projected: "Projected",
	Registered: "Registered",
	Retired: "Retired",
	RetiredInventory: "RetiredInventory",
	Transferred: "Transferred",
	//TransferredInventory: "TransferredInvntory",
	Cancelled: "Cancelled",
	Inventory: "Inventory",
	ProjectedInventory: "ProjectedInventory",
	Project: "Project",
	Reduced: "Reduced"
});

function calcSerial(start, count) {
	return (
		splitSerial(start).project +
		"-" +
		(parseInt(splitSerial(start).serial) + count).toString().padStart(6, "0")
	);
}

function splitSerial(serial) {
	const split = serial.split("-");
	return { serial: split[split.length - 1], project: split.slice(0, split.length - 1).join("-") };
}

function calcCount(min, max) {
	if (!min || !max) {
		return null;
	}
	return parseInt(splitSerial(max).serial) - parseInt(splitSerial(min).serial) + 1;
}

function SplitEmissions(data) {
	var draft = data
		.filter(x => !x.serialMin)
		.map(x => ({
			year: x.year,
			status: x.state,
			isSumEmpty: x.isEmpty,
			retiredAgainst: x.retiredAgainst,
			inventoryYear: x.inventoryYear,
			inventoryProject: x.inventoryProject,
			transferredTo: x.transferredTo,
			quantity: x.count,
			isOpen: x.isOpen
		}));
	var registered = data.filter(x => x.serialMin);
	registered = registered.map(x => ({
		year: x.year,
		status: x.state,
		isSumEmpty: x.isEmpty,
		isCountEmpty: !x.count && x.count !== 0 ? true : undefined,
		serialMin: x.serialMin,
		serialMax: x.count ? calcSerial(x.serialMin, x.count - 1) : undefined,
		retiredAgainst: x.retiredAgainst,
		inventoryYear: x.inventoryYear,
		inventoryProject: x.inventoryProject,
		transferredTo: x.transferredTo,
		edited: x.edited,
		isOpen: x.isOpen
	}));

	return { draft: draft, registered: registered };
}

function CombineEmissions(data) {
	var registered = data.registered.map((x, idx) => ({
		year: x.year,
		state: x.status,
		isEmpty: x.isSumEmpty,
		count: x.isCountEmpty ? null : x.serialMax ? calcCount(x.serialMin, x.serialMax) : 0,
		serialMin: x.serialMin,
		retiredAgainst: x.retiredAgainst,
		inventoryYear: x.inventoryYear,
		inventoryProject: x.inventoryProject,
		transferredTo: x.transferredTo,
		edited: x.edited,
		isOpen: x.isOpen,
		errorIdx: "registered_" + idx
	}));

	var draft = data.draft.map((x, idx) => ({
		year: x.year,
		state: x.status,
		isEmpty: x.isSumEmpty,
		count: x.quantity,
		retiredAgainst: x.retiredAgainst,
		inventoryYear: x.inventoryYear,
		inventoryProject: x.inventoryProject,
		transferredTo: x.transferredTo,
		errorIdx: "draft_" + idx,
		isOpen: x.isOpen
	}));

	var combined = registered.concat(draft);

	return combined.sort((a, b) => a.year - b.year);
}

function addEntry(emissions, year, projectSerial) {
	year = Number(year)
	if (!emissions[year]) {
		emissions[year] = {
			year: year,
			registered: 0,
			retired: 0,
			projected: 0,
			cancelled: 0,
			remaining: 0,
			inventory: 0,
			unretiredInventory: 0,
			projectedInventory: 0,
			offsets: 0,
			projects: {}
		};
	}

	if (!emissions[year].projects[projectSerial]) {
		emissions[year].projects[projectSerial] = {
			registered: 0,
			retired: 0,
			projected: 0,
			cancelled: 0,
			remaining: 0,
			inventory: 0,
			unretiredInventory: 0,
			projectedInventory: 0,
			offsets: 0,
			offsetsPerYear: {},
			er: []
		};
	}
}

function getEmissions(projects, includeDraftProjects) {
	var emissions = {};
	projects
		.filter(x => includeDraftProjects || x.status === ProjectStatusEnum.Approved || ProjectStatusEnum.Received )
		.forEach(project => {
			// don't use hidden projects for calculations
			if (project.overviewTracking === "Hidden") return;

			project.emissionReductions.registered.forEach(e => {
				addEntry(emissions, e.year, project.projectSerial);

				const p = emissions[e.year].projects[project.projectSerial];

				if (e.status === StatusEnum.Registered) {
					const count = calcCount(e.serialMin, e.serialMax);
					p.registered += count;
					p.remaining += count;
					emissions[e.year].registered += count;
					emissions[e.year].remaining += count;
				}

				if (e.status === StatusEnum.Retired) {
					const count = calcCount(e.serialMin, e.serialMax);
					p.retired += count;
					p.registered += count;
					emissions[e.year].retired += count;
					emissions[e.year].registered += count;
				}

				if (e.status === StatusEnum.RetiredInventory) {
					const count = calcCount(e.serialMin, e.serialMax);

					// same as retired
					p.retired += count;
					p.registered += count;
					emissions[e.year].retired += count;
					emissions[e.year].registered += count;

					// additionally count offsets
					countInventory(e, emissions, project, count);
				}

				if (e.status === StatusEnum.Cancelled) {
					const count = calcCount(e.serialMin, e.serialMax);
					p.cancelled += count;
					p.remaining -= count;
					p.registered -= count;
					emissions[e.year].cancelled += count;
					emissions[e.year].remaining -= count;
					emissions[e.year].registered -= count;
				}

				//p.er.push(e);
			});

			project.emissionReductions.draft.forEach(e => {
				if (e.quantity !== 0) {
					addEntry(emissions, e.year, project.projectSerial);

					const p = emissions[e.year].projects[project.projectSerial];

					if (e.status === StatusEnum.Projected) {
						p.projected += e.quantity;
						emissions[e.year].projected += e.quantity;
					}

					if (e.status === StatusEnum.Inventory) {
						p.inventory += e.quantity;
						emissions[e.year].inventory += e.quantity;

						p.unretiredInventory += e.quantity;
						emissions[e.year].unretiredInventory += e.quantity;
					}

					if (e.status === StatusEnum.ProjectedInventory) {
						p.projectedInventory += e.quantity;
						emissions[e.year].projectedInventory += e.quantity;

						// p.unretiredInventory += e.quantity;
						// emissions[e.year].unretiredInventory += e.quantity;
					}

					if (e.status === StatusEnum.Registered) {
						// same as non draft
						const count = e.quantity;
						p.registered += count;
						p.remaining += count;
						emissions[e.year].registered += count;
						emissions[e.year].remaining += count;
					}

					if (e.status === StatusEnum.Retired) {
						// same as non draft
						const count = e.quantity;
						p.retired += count;
						p.registered += count;
						emissions[e.year].retired += count;
						emissions[e.year].registered += count;
					}

					// external offsets might not be serialized
					if (e.status === StatusEnum.RetiredInventory) {
						const count = e.quantity;
						// same as retired
						p.retired += count;
						p.registered += count;
						emissions[e.year].retired += count;
						emissions[e.year].registered += count;

						// additionally count offsets
						countInventory(e, emissions, project, count);
					}
				}
			});
		});

	//predictedOffsets(emissions);
	return emissions;
}

function countInventory(e, emissions, project, count) {
	const inventoryYear = e.inventoryYear;
	if(inventoryYear == null) {
		console.log("error countInventory");
		console.log(e);
		return;
	}
	addEntry(emissions, inventoryYear, project.projectSerial);
	emissions[inventoryYear].projects[project.projectSerial].offsets += count;
	const offsetsPerYear = emissions[inventoryYear].projects[project.projectSerial].offsetsPerYear;
	if (!offsetsPerYear[e.year]) offsetsPerYear[e.year] = 0;
	offsetsPerYear[e.year] += count;
	emissions[inventoryYear].offsets += count;
	emissions[inventoryYear].unretiredInventory -= count;

	// per inventory retired
	if (e.inventoryProject) {
		const inventoryProject = e.inventoryProject;
		addEntry(emissions, inventoryYear, inventoryProject);
		emissions[inventoryYear].projects[inventoryProject].offsets += count;
		emissions[inventoryYear].projects[inventoryProject].unretiredInventory -= count;
	}
}

// function predictedOffsets(emissions) {
// 	//console.log(Object.keys(emissions))
// 	Object.keys(emissions)
// 		.sort()
// 		.foreach(year => {
// 			const gap = Object.keys(emissions)
// 				.map(x =>
// 					x <= year ? emissions[x].remaining - emissions[x].projectedInventory : 0
// 				)
// 				.reduce((a, b) => a + b);

// 			emissions[year]["gap"] = gap;
// 			console.log(gap);
// 			// if(gap > 0) {
// 			// }
// 		});
// }

function getEmissionsArray(projects, includeDraftProjects, fillGaps) {
	const e2 = getEmissions(projects, includeDraftProjects);

	//predictedOffsets(e2);

	if(fillGaps) {
		const min = Math.min(...Object.keys(e2).map(x=>Number(x)));
		const max = Math.max(...Object.keys(e2).map(x=>Number(x)));

		for (var i = min; i <= max; i++) {
			if(!e2[i]) {
				e2[i] = {
					year: i,
					registered: 0,
					retired: 0,
					projected: 0,
					cancelled: 0,
					remaining: 0,
					inventory: 0,
					unretiredInventory: 0,
					projectedInventory: 0,
					offsets: 0,
					projects: {}
				}
			}
		}

	}
	console.log(e2)

	const emissionArray = Object.keys(e2).map(v => e2[v]);

	return emissionArray;
}

function addExtraYear(emissionArray_) {
	var emissionArray = emissionArray_;
	if (emissionArray.length > 0) {
		emissionArray = [
			...emissionArray,
			{
				year: emissionArray.slice(-1)[0].year + 1,
				registered: 0,
				retired: 0,
				projected: 0,
				cancelled: 0,
			},
		];
	}
	return emissionArray;
}

const toCheck = [
	"year",
	"status",
	"quantity",
	"serialMin",
	"serialMax",
	"retiredAgainst",
	"inventoryYear",
	"inventoryProject"
];

function isElementChanged(a, b) {
	for (var prop in toCheck) {
		if (a[toCheck[prop]] !== b[toCheck[prop]]) return true;
	}

	return false;
}

function isEmissionsChanged(a, b) {
	if (a.registered.length !== b.registered.length) return true;
	if (a.draft.length !== b.draft.length) return true;

	var i;
	for (i = 0; i < a.registered.length; i++) {
		if (isElementChanged(a.registered[i], b.registered[i])) return true;
	}

	for (i = 0; i < a.draft.length; i++) {
		if (isElementChanged(a.draft[i], b.draft[i])) return true;
	}

	return false;
}

export {
	SplitEmissions,
	CombineEmissions,
	StatusEnum,
	calcCount,
	getEmissions,
	getEmissionsArray,
	addExtraYear,
	isEmissionsChanged
};
