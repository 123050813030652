import React, { Component } from "react";
import ProjectItem from "./ProjectItem";
import moment from "moment";
import classnames from "classnames";
import { ProgramTypeEnum } from "../../lib/Enums";

function dateCompare(a, b) {
	var dateA = new Date(a.date),
		dateB = new Date(b.date);
	return dateB - dateA;
}

class ProjectList extends Component {
	tabClick = tab => {
		this.props.handleProjectTabClick(tab);
	};

	getUsername(serial, users) {
		const match = users.find(x => x.serial === serial);

		return !match ? "" : match.organization;
	}

	render() {
		const { projects, /*projectOccurrences*/ showHistory, users } = this.props;
		return (
			<div>
				{/* <div className="listColumnsProjects">
					<p onClick={() => this.tabClick()}>All</p>
					<p onClick={() => this.tabClick(1)}>Draft ({projectOccurrences[1] || 0})</p>
					<p onClick={() => this.tabClick(0)}>
						Pending Review ({projectOccurrences[0] || 0})
					</p>
					<p onClick={() => this.tabClick(2)}>Approved ({projectOccurrences[2] || 0})</p>
					<p onClick={() => this.tabClick(3)}>Rejected ({projectOccurrences[3] || 0})</p>
					<p onClick={() => this.tabClick(4)}>Archived ({projectOccurrences[4] || 0})</p>
				</div> */}
				<div
					className={classnames(
						"headings",
						showHistory ? "listColumnsWithHistory" : "listColumnsWithoutHistory"
					)}
				>
					<p>Project Serial</p>
					<p>Project Name</p>
					{showHistory && <p>Type</p>}
					{showHistory && <p>Account Holder</p>}
					<p>Date Submitted</p>
					<p>Status</p>
					{showHistory && <p>History</p>}
				</div>
				<div>
					<ul className="listRows">
						{projects.sort(dateCompare).map(project => (
							<ProjectItem
								key={project.projectSerial}
								projectSerial={project.projectSerial}
								title={project.title}
								organization={this.getUsername(project.accountHolder, users)}
								date={moment(project.date).format("ll")}
								status={project.status}
								projectId={project.projectSerial}
								showHistory={showHistory}
								type={
									project.programType !== ProgramTypeEnum.Inventory &&
									project.programType !== ProgramTypeEnum.PurchasedOffsets
										? "Project"
										: project.programType
								}
							/>
						))}
					</ul>
				</div>
			</div>
		);
	}
}
export default ProjectList;
