import {
	faChartLine,
	faRecycle,
	faPlusCircle,
	faCheckCircle,
	faTimes,

	//Map
	faTree,
	faDumpster,
	faFan,
	faBuilding,
	faTruck,
	faSubway,
	faDiceD6,
	faBolt,
} from "@fortawesome/free-solid-svg-icons";
import { StatusEnum } from "./Emissions";
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { renderToStaticMarkup } from "react-dom/server";
import { divIcon } from "leaflet";

function getSymbol(status) {
	if (status === StatusEnum.Projected)
		return <FontAwesomeIcon style={{ color: "#8884d8" }} icon={faChartLine} />;
	if (status === "Realized")
		return <span style={{ fontSize: "1.2rem", fontWeight: "bold" }}>Σ</span>;
	if (status === StatusEnum.Registered)
		return <FontAwesomeIcon style={{ color: "#3498DB" }} icon={faPlusCircle} />;
	if (status === StatusEnum.Cancelled)
		return <FontAwesomeIcon style={{ color: "tomato" }} icon={faTimes} />;
	if (status === StatusEnum.Retired || status === StatusEnum.RetiredInventory)
		return <FontAwesomeIcon style={{ color: "#ffc658" }} icon={faCheckCircle} />;
	if (status === "Remaining") {
		return <FontAwesomeIcon style={{ color: "#82ca9d" }} icon={faRecycle} />;
	}
}

const mapSymbols = {
	"Energy Generation": { icon: faFan, popupAnchor: [7, 0] },
	"Natural Assets": { icon: faTree, popupAnchor: [7, 0] },
	"Energy Efficiency": { icon: faBolt, popupAnchor: [7, 0] },
	Fleet: { icon: faTruck, popupAnchor: [12, 0] },
	Transportation: { icon: faSubway, popupAnchor: [12, 0] },
	Waste: { icon: faDumpster, popupAnchor: [15, 0] },
	Infrastructure: { icon: faBuilding, popupAnchor: [15, 0] },
	Other: { icon: faDiceD6, popupAnchor: [11, 0] },
};

function getMapSymbolNames() {
	return Object.keys(mapSymbols);
}

function getInternalSymbol(type) {
	var symbol = mapSymbols[type];
	if (!symbol) {
		symbol = mapSymbols["Other"];
	}
	return symbol;
}

function getMapSymbol(type) {
	const symbol = getInternalSymbol(type);
	const faSymbol = (
		<FontAwesomeIcon style={{ color: "rgb(117, 153, 76)" }} icon={symbol.icon} size="2x" />
	);

	return faSymbol;
}

function getMapIcon(type) {
	const symbol = getInternalSymbol(type);
	return divIcon({
		html: renderToStaticMarkup(
			<FontAwesomeIcon
				style={{ color: "rgb(117, 153, 76)" }}
				icon={symbol.icon}
				className="shadow"
				size="2x"
			/>
		),
		popupAnchor: symbol.popupAnchor,
	});
}

export { getSymbol, getMapIcon, getMapSymbolNames, getMapSymbol };
