import React, { Component, createRef } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import { Map, Marker, Popup, TileLayer, FeatureGroup } from "react-leaflet";

import L from "leaflet";

// import ProjectMapLegend from "./ProjectMapLegend";
import { getMapIcon, getMapSymbolNames, getMapSymbol } from "../lib/Symbols";

import Control from "react-leaflet-control";
import { UncontrolledCollapse } from "reactstrap";
import { faAngleDoubleDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class ProjectsMap extends Component {
	constructor() {
		super();
		this.state = {
			openMarker: null,
			filter: getMapSymbolNames()
		};

		this.mapRef = createRef();
		this.groupRef = createRef();
	}

	fitBounds() {
		// center map, this is a bit hacky, but I don't have a better idea at the moment
		setTimeout(() => {
			try {
				const map = this.mapRef.current.leafletElement; //get native Map instance
				const group = this.groupRef.current.leafletElement; //get native featureGroup instance
				const bounds = group.getBounds();
				if (bounds.isValid()) {
					map.fitBounds(group.getBounds());
				}
			} catch {
				console.log("error center map");
			}
		}, 10);
	}

	componentDidMount() {
		if (this.props.fitMap) {
			this.fitBounds();
		}
	}

	componentDidUpdate() {
		this.mapRef.current.leafletElement.invalidateSize();
		if (this.props.fitMap) {
			this.fitBounds();
		}
	}

	onFilterChanged(filter) {
		this.setState({ filter: filter });		
	}

	render() {
		const { projects } = this.props;

		const ready = projects;

		// create an array with marker components
		let LeafletMarkers;
		if (ready) {
			LeafletMarkers = projects
				.filter(x => x.location.latitude && x.location.longitude)
				.filter(x => this.state.filter.indexOf(x.projectType) >= 0)
				.map((project, index) => (
					<Marker
						key={index}
						position={{
							lat: parseFloat(project.location.latitude),
							lng: parseFloat(project.location.longitude)
						}}
						icon={getMapIcon(project.projectType)}
					>
						<Popup>
							<div>
								<p>{project.title}</p>
								{/* <p>{project.user.organization}</p> */}
								<p>Program Type: {project.programType}</p>
								<p>Project Type: {project.projectType}</p>
								<Link to={`/projects/${project.projectSerial}`}>View</Link>
							</div>
						</Popup>
					</Marker>
				));
		}

		const allSelected = this.state.filter.length >= getMapSymbolNames().length;
		return (
			<React.Fragment>
				<Map
					style={{ border: "1px solid #434343" }}
					className="dashboard-map"
					center={[49.240242, -122.59637]}
					zoom={8}
					minZoom={2}
					maxBoundsViscosity={0.5}
					maxBounds={L.latLngBounds(
						L.latLng(-89.98155760646617, -180),
						L.latLng(89.99346179538875, 180)
					)}
					ref={this.mapRef}
				>
					<TileLayer
						url="https://cartodb-basemaps-{s}.global.ssl.fastly.net/light_all/{z}/{x}/{y}.png"
						attribution='&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>, &copy; <a href="https://carto.com/attribution">CARTO</a>'
					/>

					{}
					<FeatureGroup ref={this.groupRef}>{LeafletMarkers}</FeatureGroup>
					{/* <ProjectMapLegend
						filter={this.filter}
						onFilterChanged={f => this.setFilter(f)}
					/> */}

					<Control position="topright">
						<div className="info legend">
							<button
								id="toggler"
								className="link-button no-focus"
								style={{ color: "rgba(0, 0, 0, 0.5)" }}
							>
								Legend & Filter{" "}
								<FontAwesomeIcon
									style={{ marginLeft: 58 }}
									icon={faAngleDoubleDown}
								/>
							</button>

							{/* <a id="toggler" hrefclassName="nav-link">Legend & Filter{" "} <FontAwesomeIcon className="ml-5" icon={faAngleDoubleDown} /></a> */}
							<UncontrolledCollapse toggler="#toggler">
								<hr
									style={{
										marginTop: 5,
										marginBottom: -2,
										marginLeft: -8,
										marginRight: -8
									}}
								/>
								<table className="mt-2">
									<tbody>
										<tr>
											<td>
												<input
													type="checkbox"
													className="mr-1"
													checked={allSelected}
													onChange={e => {
														this.setState({
															filter: allSelected
																? []
																: getMapSymbolNames()
														});
													}}
												></input>
												<span></span>
											</td>

											<td>
												<button
													className="link-button"
													style={{
														marginLeft: -4,
														color: "rgba(0, 0, 0, 0.5)"
													}}
													href="#"
													onClick={e => {							
														this.setState({
															filter: allSelected
																? []
																: getMapSymbolNames()
														});
													}}
												>
													All
												</button>
											</td>
										</tr>

										{getMapSymbolNames().map((symbol, i) => (
											<tr key={i}>
												{/* {i != 0 && <br />} */}
												<td>
													<input
														type="checkbox"
														className="mr-1"
														checked={
															this.state.filter.indexOf(symbol) >= 0
														}
														onChange={e => {
															var updated;
															if (
																this.state.filter.indexOf(symbol) >=
																0
															) {
																updated = this.state.filter.filter(
																	x => x !== symbol
																);
															} else {
																updated = this.state.filter.slice(
																	0
																);
																updated.push(symbol);
															}

															this.setState({ filter: updated });
														}}
													></input>
													<span
														style={{ fontSize: "0.55rem" }}
														className="mr-2"
													>
														{getMapSymbol(symbol)}
													</span>
												</td>

												<td>
													{/* <button tag={a}>Test</button> */}
													<button
														className="link-button"
														style={{
															marginLeft: -4,
															color: "rgba(0, 0, 0, 0.5)"
														}}
														href="#"
														onClick={e => {
															e.preventDefault();
															this.onFilterChanged([symbol]);
															return false;
														}}
													>
														{symbol}
													</button>
												</td>

												{/* <Input type="radio"></Input> */}
											</tr>
										))}
									</tbody>
								</table>
							</UncontrolledCollapse>
						</div>
					</Control>
				</Map>
			</React.Fragment>
		);
	}
}

ProjectsMap.propTypes = {
	fitMap: PropTypes.bool
};

export default ProjectsMap;
