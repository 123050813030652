import React, { Component } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle, faTimes, faTrash, faImage, faPlus } from "@fortawesome/free-solid-svg-icons";
import { Row, Col } from "reactstrap";
import Axios from "axios";
import { CORE_API_ROOT } from "../../config/api-config";
import SmallSpinner from "./SmallSpinner";
import Tooltip from "./Tooltip";
import { TextDictionary } from "../../lib/TextDictionary";

class FileUploadFieldGroup extends Component {
	constructor(props) {
		super(props);
		this.state = {
			working: false,
		};

		this.fileUpload = this.fileUpload.bind(this);
	}

	componentDidMount() {
		window["simulate_" + this.props.name] = (url, filename) => {
			this.callbackOnChange(url, filename);
		};
	}

	onRemove = (event) => {
		event.preventDefault();
		this.callbackOnChange(null, null);
	};
	onClick = (e) => {
		e.target.value = null;
	};

	callbackOnChange(url, filename) {
		const e = { target: { name: this.props.name, value: { url: url, filename: filename } } };
		this.props.onChange(e);
	}

	fileUpload(e) {
		this.setState({ working: true });
		const file = e.target.files[0];

		const ext = file.name.substring(file.name.lastIndexOf(".") + 1).toLowerCase();
		const isImage = this.props.file === "images";

		if (file.size > (isImage ? 5500000 : 27000000)) {
			this.setState({ working: false });
			alert(`File is too big! Maximum ${isImage ? "5MB" : "25MB"}.`);
			return;
		}

		if (this.props.pdfOnly && ext !== "pdf") {
			this.setState({ working: false });
			alert(TextDictionary.FileUpload.NotPdf);
			return;
		}

		const endpoint = isImage ? "upload/image" : "upload/file";

		if (file) {
			var formData = new FormData();
			formData.append("file", file);

			Axios.post(`${CORE_API_ROOT}/${endpoint}`, formData, {
				headers: {
					"Content-Type": "multipart/form-data",
				},
			})
				.then((res) => {
					this.callbackOnChange(res.data.url, file.name);
					this.setState({ working: false });
				})
				.catch((err) => {
					console.log(err);
					this.setState({ working: false });
					alert(
						err.response && err.response.data
							? err.response.data.error
							: "Request Error"
					);
				});
		}
	}
	render() {
		const { label, name, file, value, type, error, readOnly, tooltip } = this.props;

		const filename = !value || !value.filename ? "" : value.filename;
		const url = !value || !value.url ? "" : value.url;

		return (
			<div>
				{file === "images" || file === "logo" ? (
					<div>
						<p style={{ margin: 0, padding: "0 0.3rem", paddingBottom: "0.25rem" }}>
							{label}
							{!this.props.optional && !url && !readOnly && (
								<span className="text-danger"> *</span>
							)}
							{url && !readOnly && (
								<FontAwesomeIcon
									icon={faTrash}
									color="#f86c6b"
									onClick={this.onRemove}
								/>
							)}
							{tooltip && (
								<Tooltip
									content={this.props.tooltip}
									name={name}
									left={true}
									style={{ marginLeft: 5 }}
								/>
							)}
						</p>

						<label
							className={classnames("form-control", "imgBorder", {
								"is-invalid": error,
							})}
						>
							<input
								type={type}
								name={name}
								onChange={this.fileUpload}
								onClick={this.onClick}
								file={file}
								style={{
									height: "0.1px",
									width: "0.1px",
									opacity: 0,
									overflow: "hidden",
									position: "absolute",
									zIndex: -1,
								}}
								className="form-control"
							/>
							<div className="imgContainer">
								<img
									src={file === "images" ? url && url + ".300.jpg" : url}
									alt=""
									id={this.props.name + "_img"}
								/>
								<div className="uploadButton">
									<span>
										{this.state.working ? (
											<SmallSpinner show={true} width="50" />
										) : (
											!readOnly && (
												<React.Fragment>
													<span
														className="fa-stack fa-2x"
														style={{
															width: "5em",
														}}
													>
														{url === "" && (
															<FontAwesomeIcon
																icon={faImage}
																className="fa-stack-2x"
															/>
														)}
														<FontAwesomeIcon
															icon={faPlus}
															className="fa-stack-2x"
															style={{
																height: 25,
																width: 25,
																color: "white",
															}}
														/>
														<FontAwesomeIcon
															icon={faPlusCircle}
															className="fa-stack-2x"
															style={{
																height: 25,
																width: 25,
																color: "#20a8d8",
															}}
														/>
													</span>
												</React.Fragment>
											)
										)}
									</span>
								</div>
							</div>
						</label>
						{error && (
							<div className="invalid-feedback" id={name + "_error"}>
								{error}
							</div>
						)}
					</div>
				) : null}

				{file === "documents" ? (
					<div
						className="form-group"
						style={{ marginBottom: "0.12rem" }}
						// style={{ display: "grid", gridTemplateColumns: "0.70fr 1.25fr" }}
					>
						<Row>
							<Col xs="4" style={{ paddingRight: 0 }}>
								<p style={{ margin: 0 }} onClick={this.onClick}>
									{label}
									{!this.props.optional && !readOnly && (
										<i className="text-danger"> *</i>
									)}
									{tooltip && (
										<Tooltip content={this.props.tooltip} name={name} />
									)}
								</p>
							</Col>
							<Col xs="8">
								<label
									className={classnames("form-control", {
										"is-invalid": error,
									})}
								>
									<input
										type={type}
										name={name}
										onChange={this.fileUpload}
										file={file}
										style={{
											height: "0.1px",
											width: "0.1px",
											opacity: 0,
											overflow: "hidden",
											position: "absolute",
											zIndex: -1,
										}}
										onClick={this.onClick}
										className={classnames({
											"is-invalid": error,
										})}
										disabled={this.props.readOnly}
									/>
									{this.state.working ? (
										<div className="d-flex justify-content-center">
											<SmallSpinner show={true} width="20px" />
										</div>
									) : filename ? (
										<div className="d-flex justify-content-center">
											{/* style={{ color: "blue", margin: " 0 10px 0 0" }} */}
											<div className="mr-1 text-nowrap text-truncate">
												<a
													href={url}
													target="_blank"
													rel="noopener noreferrer"
												>
													<div>{filename}</div>
												</a>
											</div>

											{!readOnly && (
												<div className="text-nowrap">
													<div className="m-0 mr-2 d-inline-block">
														<FontAwesomeIcon
															icon={faPlusCircle}
															color="#20a8d8"
														/>{" "}
														<div className="d-none d-xl-inline-block">
															Replace
														</div>
													</div>
													<div
														onClick={this.onRemove}
														style={{ margin: 0 }}
														className="d-inline-block"
													>
														<FontAwesomeIcon
															icon={faTimes}
															color="#f86c6b"
														/>{" "}
														<div className="d-none d-xl-inline-block">
															Remove
														</div>
													</div>
												</div>
											)}
										</div>
									) : (
										!readOnly && (
											<p className="m-0">
												<FontAwesomeIcon
													icon={faPlusCircle}
													color="#20a8d8"
												/>{" "}
												Upload File {this.props.pdfOnly && "(PDF only)"}
											</p>
										)
									)}
								</label>
								{error && (
									<div className="invalid-feedback" id={name + "_error"}>
										{error}
									</div>
								)}
							</Col>
						</Row>

						<div />
					</div>
				) : null}
			</div>
		);
	}
}

FileUploadFieldGroup.propTypes = {
	//	label: PropTypes.string.isRequired,
	name: PropTypes.string.isRequired,
	onChange: PropTypes.func.isRequired,
	value: PropTypes.object.isRequired,
	type: PropTypes.string.isRequired,
};

FileUploadFieldGroup.defaultProps = {
	type: "file",
};

export default FileUploadFieldGroup;
