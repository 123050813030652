import React, { Component } from "react";
import { Row } from "reactstrap";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import Spinner from "../../components/common/Spinner";
import { getPublicProjects } from "../../actions/projectActions";
import ContentBox from "../../components/common/ContentBox";
import qs from "querystring";

import { Link } from "react-router-dom";

class Projects extends Component {
	componentDidMount() {
		this.props.getPublicProjects();
	}

	lookupSerial(serial, projects) {
		const array = serial.split("-");

		if (array.length !== 3) return null;

		const account = array[0];
		const projectId = array[1];
		const seq = parseInt(array[2]);

		projects = projects.filter(x => x.projectSerial === account + "-" + projectId);

		if (projects.length !== 1) return null;

		const project = projects[0];

		const reductions = project.emissionReductions.registered;

		const filteredReductions = reductions.filter(
			x =>
				parseInt(x.serialMin.split("-")[2]) <= seq &&
				parseInt(x.serialMax.split("-")[2]) >= seq
		);

		if (filteredReductions.length !== 1) {
			return null;
		}

		return { project: project, reduction: filteredReductions[0] };
	}

	render() {
		const { publicProjects, loading } = this.props.project;
		let projectItems;
		var projects = publicProjects;
		var subtitle = undefined;

		if (!projects || loading) {
			return <Spinner />;
		}

		const params = qs.parse(this.props.location.search.slice(1)); //{ ignoreQueryPrefix: true }

		if (params.serial && this.lookupSerial(params.serial, projects)) {
			const { project, reduction } = this.lookupSerial(params.serial, projects);

			const details = [
				["Project", <Link to={`/projects/${project.projectSerial}`}>{project.title}</Link>],
				["Year", reduction.year],
				["Status", reduction.status],
				["Retired Against", reduction.retiredAgainst]
			];

			projectItems = (
				<div className="bd-example ml-3">
					<dl className="row">
						{details.map(item => (
							<React.Fragment>
								<dt className="col-sm-6">{item[0]}</dt>
								<dd className="col-sm-6">{item[1]}</dd>
							</React.Fragment>
						))}
					</dl>
				</div>
			);

			subtitle = "Serial Number: " + params.serial;
		} else {
			projectItems = <h4 className="ml-3">Serial Number Not Found...</h4>;
		}

		return (
			<ContentBox title="Serial Number Details" width="12" subtitle={subtitle}>
				<Row>{projectItems}</Row>
			</ContentBox>
		);
	}
}

Projects.propTypes = {
	getPublicProjects: PropTypes.func.isRequired,
	project: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
	project: state.project
});

export default connect(
	mapStateToProps,
	{ getPublicProjects }
)(Projects);
