import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import TextFieldGroup from "../../components/common/TextFieldGroup";
import TextAreaFieldGroup from "../../components/common/TextAreaFieldGroup";
import { Button, Col, Row } from "reactstrap";
import ContentBox from "../../components/common/ContentBox";
import SmallSpinner from "../../components/common/SmallSpinner";
import { CORE_API_ROOT } from "../../config/api-config";
import axios from "axios";
import ReCAPTCHA from "react-google-recaptcha";
import qs from "querystring";

class Contact extends Component {
	constructor() {
		super();
		this.state = {
			name: "",
			email: "",
			message: "",
			errors: {},
			working: false,
			captcha: "",
			enabled: false
		};

		this.onChange = this.onChange.bind(this);
		this.onChangeRe = this.onChangeRe.bind(this);
	}

	componentDidMount() {}

	componentWillReceiveProps(nextProps) {}

	onChange(e) {
		this.setState({ [e.target.name]: e.target.value });
	}

	onChangeRe(value) {
		this.setState({ captcha: value, enabled: true });
	}

	onSubmit(e, projectId) {
		e.preventDefault();
		this.setState({ errors: {}, working: true });

		const data = {
			name: this.state.name,
			email: this.state.email,
			message: this.state.message,
			captcha: this.state.captcha,
			projectId: projectId
		};

		axios({
			method: "POST",
			url: `${CORE_API_ROOT}/contact`,
			data: data
		})
			.then(res => {
				alert("Your message has been sent.");
				this.setState({
					working: false,
					name: "",
					email: "",
					message: "",
					errors: {},
					captcha: "",
					enabled: false
				});
				this.captcha.reset();
			})
			.catch(res => {
				this.setState({ errors: res.response.data, working: false });
				alert("Error sending message.");
			});
	}
	render() {
		const { errors } = this.state;
		const params = qs.parse(this.props.location.search.slice(1));
		const projectId = params.projectId;
		const name = params.name;
		//const projectId = this.props.match.params.id

		return (
			<ContentBox width="6" title={`Contact ${name ? name : ""}`} subtitle={`Send ${name ? name : "us"} an email`}>			

				<form noValidate onSubmit={e => this.onSubmit(e, projectId)}>
					<TextFieldGroup
						label="Your Name"
						placeholder=""
						name="name"
						type="text"
						value={this.state.name}
						onChange={this.onChange}
						error={errors.name}
					/>
					<TextFieldGroup
						label="Your Email"
						placeholder=""
						name="email"
						type="email"
						value={this.state.email}
						onChange={this.onChange}
						error={errors.email}
					/>
					<TextAreaFieldGroup
						label="Message"
						placeholder=""
						name="message"
						type="message"
						value={this.state.message}
						onChange={this.onChange}
						error={errors.message}
					/>
					{this.props.apiKeys && (					
						
						<ReCAPTCHA
							ref={r => (this.captcha = r)}
							sitekey={
								(this.props.apiKeys.keys.captchaKey) || ""
							}
							onChange={this.onChangeRe}
						/>
					)}

					<Row className="pt-3">
						<Col xs="6">
							<Button disabled={!this.state.enabled} color="primary" className="px-4">
								<SmallSpinner width="20px" show={this.state.working} />
								Submit
							</Button>
						</Col>
					</Row>
				</form>
			</ContentBox>
		);
	}
}

const mapStateToProps = state => ({
	apiKeys: state.settings.apiKeys
});

export default connect(
	mapStateToProps,
	{}
)(withRouter(Contact));
