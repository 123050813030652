import { combineReducers } from "redux";
import authReducer from "./authReducer";
import errorReducer from "./errorReducer";
import profileReducer from "./profileReducer";
import projectReducer from "./projectReducer";
import dashboardReducer from "./dashboardReducer";
import draftsReducer from "./draftsReducer";
import adminReducer from "./adminReducer";
import settingsReducer from "./settingsReducer";

export default combineReducers({
	auth: authReducer,
	errors: errorReducer,
	profile: profileReducer,
	project: projectReducer,
	dashboard: dashboardReducer,
	drafts: draftsReducer,
	admin: adminReducer,
	settings: settingsReducer
});
