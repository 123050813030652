import React, { Component } from "react";
import { connect } from "react-redux";

import { Link } from "react-router-dom";
import axios from "axios";
import ContentBox from "../common/ContentBox.js";
import TextFieldGroup from "../../components/common/TextFieldGroup";
import { getUserProjects } from "../../actions/projectActions";
import { getCurrentProfile } from "../../actions/profileActions";
import { getSettingsPricing } from "../../actions/settingsActions";
import { CORE_API_ROOT } from "../../config/api-config";
import { Button, Row, Col, Input } from "reactstrap";
import SmallSpinner from "../common/SmallSpinner";

class PaymentContact extends Component {
	constructor(props) {
		super(props);

		this.pricing = { low: "500", mid: "850", top: "1250" };

		this.state = {
			projectCount: null,
			inventoryTonnes: null,
			hasCheckedTerms: false,
			working: false,
		};
	}

	handleCheck(e) {
		this.setState({ hasCheckedTerms: e.target.checked });
	}

	onChange(e) {
		this.setState({ [e.target.name]: e.target.value });
	}

	onSubmit(e, isInventory) {
		e.preventDefault();
		this.setState({ working: true });
		const note = isInventory
			? `Inventory: ${this.state.inventoryTonnes} Tonnes`
			: `Reduction Projects: ${this.state.projectCount} Projects`;

		const data = {
			note: note,
		};

		axios({
			method: "POST",
			url: `${CORE_API_ROOT}/paymentForm`,
			data: data,
		})
			.then((res) => {
				alert("Your request has been sent.");
				this.setState({
					working: false,
					projectCount: null,
					inventoryTonnes: null,
					hasCheckedTerms: false,
				});
			})
			.catch((res) => {
				this.setState({ errors: res.response.data, working: false });
				alert("Error sending message.");
			});
	}

	renderProject() {
		const enabled =
			this.state.projectCount && this.state.hasCheckedTerms && !this.state.working;
		return (
			<ContentBox title="Project Registration Fee" width="6">
				<p>
					The fee for registering your emission reduction project on the Local Carbon
					Registry is $500 plus GST per project. The fee goes towards the general
					maintenance and administration of the registry. It will also help us continue to
					add enhancements to the registry and develop new initiatives to promote local
					carbon reductions.
				</p>
				<p>
					To pay for the project registration, please fill out the form below. Once we
					receive your request, an invoice will be sent to your email in the next 24
					hours. Payment options will be displayed on the invoice. You can register a new
					project as soon as your payment is processed.
				</p>

				<form noValidate onSubmit={(e) => this.onSubmit(e, false /*isIventory*/)}>
					<TextFieldGroup
						label="How many project do you want to register?"
						placeholder=""
						name="projectCount"
						type="text"
						value={this.state.projectCount}
						onChange={(e) => this.onChange(e)}
						// error={errors.name}
					/>

					<div>
						<p className="mt-4">
							<input
								id="terms"
								type="checkbox"
								name="terms"
								onChange={(e) => this.handleCheck(e)}
							/>{" "}
							I agree with the{" "}
							<Link to="/terms-and-conditions" target="_blank">
								terms and conditions
							</Link>
						</p>
					</div>
					<Row className="pt-3">
						<Col xs="6">
							<Button
								id="submit"
								disabled={!enabled}
								color={enabled ? "primary" : "secondary"}
								className="px-4"
							>
								<SmallSpinner width="20px" show={this.state.working} />
								Submit
							</Button>
						</Col>
					</Row>
				</form>
			</ContentBox>
		);
	}

	renderInventory() {
		const enabled =
			this.state.inventoryTonnes && this.state.hasCheckedTerms && !this.state.working;
		return (
			<ContentBox title="Inventory and Offsets Access Fee" width="6">
				<p>
					In addition to emission project database, the registry provides inventory and
					offsets functionalities, which allow clients to enter their corporate inventory
					and purchased offsets and track their carbon neutrality. The{" "}
					<i>GHG True-up Overview</i>
					functionality is equipped with visual analytics that will provide insights to
					emission reduction strategy. There is a one-time access fee to unlock these
					functionalities.
				</p>
				<p>
					To gain access to inventory and purchased offsets functionalities, please fill
					out the form below. Once we receive your request, an invoice will be sent to
					your email in the next 24 hours. Payment options will be displayed on the
					invoice. You can add your inventory and purchased offsets as soon as your
					payment is processed.
				</p>

				<form noValidate onSubmit={(e) => this.onSubmit(e, true /* isIventory */)}>
					<TextFieldGroup
						id="inventory"
						label="What is your organization’s most recent inventory (in t CO2e)?"
						placeholder=""
						name="inventoryTonnes"
						type="text"
						value={this.state.inventoryTonnes}
						onChange={(e) => this.onChange(e)}
						// error={errors.name}
					/>

					<div>
						<p className="mt-4 ml-4">
							<Input
								id="terms"
								type="checkbox"
								name="terms"
								onChange={(e) => this.handleCheck(e)}
							/>{" "}
							I agree with the{" "}
							<Link to="/terms-and-conditions" target="_blank">
								terms and conditions
							</Link>
						</p>
					</div>
					<Row className="pt-3">
						<Col xs="6">
							<Button
								id="submit"
								disabled={!enabled}
								color={enabled ? "primary" : "secondary"}
								className="px-4"
							>
								<SmallSpinner width="20px" show={this.state.working} />
								Submit
							</Button>
						</Col>
					</Row>
				</form>
			</ContentBox>
		);
	}

	render() {
		const isInventory = this.props.inventory;
		return isInventory ? this.renderInventory() : this.renderProject();
	}
}

const mapStateToProps = (state) => ({
	profile: state.profile,
	user: state.auth.user,
	userProjects: state.project,
	settings: state.settings,
});
export default connect(mapStateToProps, { getUserProjects, getCurrentProfile, getSettingsPricing })(
	PaymentContact
);
