import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
// import { getProject } from "../../actions/projectActions";
import { getPublicProjects } from "../../actions/projectActions";
import ContentBox from "../../components/common/ContentBox";
import NotFound from "../notFound/NotFound";
import { Row, Col } from "reactstrap";

//import { Link } from "react-router-dom";
//import { getEmissions } from "../../lib/Emissions";
import { ProgramTypeEnum } from "../../lib/Enums";
import Spinner from "../../components/common/Spinner";
//import { InventoryGraph } from "../overview/InventoryGraph";
//import { Table } from "reactstrap";
import ProjectsMap from "../../components/ProjectsMap";
import ProjectItem from "../../components/projects/ProjectItem";

class Microsite extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	componentDidMount() {
		this.props.getPublicProjects();
		//this.checkForChangedProject({ project: { publicProjects: undefined } });
	}

	onErChange(newData) {
		this.setState({ emissionReductions: newData });
	}

	// checkForChangedProject(prevProps) {
	// 	if (
	// 		this.props.project.publicProjects &&
	// 		prevProps.project.publicProjects !== this.props.project.publicProjects
	// 	) {
	// 		const project = this.getProject();
	// 		if (project) {
	// 			this.setState({ emissionReductions: project.emissionReductions });
	// 		}
	// 	}
	// }

	componentDidUpdate(prevProps) {
		// this.checkForChangedProject(prevProps);
	}

	getFirstProject() {
		const { publicProjects } = this.props.project;
		const id = this.props.match.params.id;
		const orgName = id.toLowerCase();

		var project = publicProjects.find(
			x => x.organization.toLowerCase().replace(/\s/g, "") === orgName && x.organizationSite > 0
		);

		// no custom microsite, try id instead
		if (!project) {
			project = publicProjects.find(x => x.projectSerial.startsWith(id + "-"));
		}

		return project;
	}

	getProjects(orgSerial) {
		const { publicProjects } = this.props.project;

		const search = orgSerial + "-";
		const projects = publicProjects.filter(x => x.projectSerial.startsWith(search));

		return projects;
	}

	getIncludedProjects(orgSerial) {
		// todo: move to user resource
		const includedOrganizationsData = { "5BD2F": ["6FB36", "35718"] };

		var projects = this.getProjects(orgSerial);

		const includedOrgs = includedOrganizationsData[orgSerial];
		if (includedOrgs) {
			for (var org in includedOrgs) {
				projects = projects.concat(this.getProjects(includedOrgs[org]));
			}
		}
		return projects;
	}

	getOrganizationSerial() {
		const project = this.getFirstProject();
		if (!project) return null;

		return project.projectSerial.split("-")[0];
	}

	render() {
		const { publicProjects } = this.props.project;

		if (!publicProjects) {
			return <Spinner></Spinner>;
		}

		const firstProject = this.getFirstProject();

		const orgSerial = this.getOrganizationSerial();

		if (!orgSerial) {
			return <NotFound />;
		}

		const orgName = firstProject.organization;

		//const e2 = getEmissions(this.getProjects(orgSerial));
		//const emissionArray = Object.keys(e2).map(v => e2[v]);

		const projectsAndPurchased = this.getIncludedProjects(orgSerial).filter(
			x => x.programType !== ProgramTypeEnum.Inventory && x.visibility === "Public"
		);

		return (
			<React.Fragment>
				{firstProject.organizationSite > 0 && (
					<ContentBox title={`${orgName} Map`}>
						<ProjectsMap projects={projectsAndPurchased} fitMap={true}></ProjectsMap>
					</ContentBox>
				)}

				<ContentBox title={`${orgName} Projects`}>
					<Row>
						{projectsAndPurchased.map(project => (
							<Col md="4" sm="6" key={project.projectSerial}>
								<ProjectItem project={project} />
							</Col>
						))}
					</Row>
				</ContentBox>

				{/* <ContentBox title={`${orgName} (${orgSerial})`}>
					<Link to={`/inventory/overview/${orgSerial}`}>Detailed Inventory Overview</Link>
					<InventoryGraph height="300px" emissionArray={emissionArray} />
				</ContentBox> */}
				{/* 
				<ContentBox title={`Projects`}>
					<Table striped size="sm" borderless className="mt-4">
						<thead>
							<tr>
								<th>Project Name</th>
							</tr>
						</thead>
						<tbody>
							{projectsAndPurchased
								.filter(
									x =>
										x.programType !== ProgramTypeEnum.Inventory &&
										x.programType !== ProgramTypeEnum.PurchasedOffsets
								)
								.map(project => (
									<tr>
										<td>
											<Link to={`/projects/${project.projectSerial}`}>
												{project.title}
											</Link>
										</td>
									</tr>
								))}
						</tbody>
					</Table>
				</ContentBox>
				<ContentBox title={`Purchased Offsets`}>
					<Table striped size="sm" borderless className="mt-4">
						<thead>
							<tr>
								<th>Project Name</th>
							</tr>
						</thead>
						<tbody>
							{projectsAndPurchased
								.filter(x => x.programType === ProgramTypeEnum.PurchasedOffsets)
								.map(project => (
									<tr>
										<td>
											<Link to={`/projects/${project.projectSerial}`}>
												{project.title}
											</Link>
										</td>
									</tr>
								))}
						</tbody>
					</Table>
				</ContentBox> */}
			</React.Fragment>
		);
	}
}

Microsite.propTypes = {
	getPublicProjects: PropTypes.func.isRequired
};

const mapStateToProps = state => ({ project: state.project });

export default connect(mapStateToProps, { getPublicProjects })(Microsite);
