import React, { Component } from "react";
import ContentBox from "../../components/common/ContentBox";

class RegisterComplete extends Component {
	render() {
		return (
			<ContentBox title="Registration Submitted">
				<p>
					Thank you for registering to be a participant in the Local Carbon Registry. Our
					registry promotes transparency and credibility for GHG emission reductions and
					offsets from local emission reduction projects. The registry is designed to
					highlight important details about each project’s current and anticipated
					environmental impact in a user-friendly way.
				</p>
				<p>Please allow up to 3 business days to receive your account activation notice.</p>
			</ContentBox>
		);
	}
}

export default RegisterComplete;
