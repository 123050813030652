import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";

import { Input } from "reactstrap";
import Tooltip from "./Tooltip";

const SelectListGroup = ({
	name,
	label,
	value,
	error,
	info,
	onChange,
	options,
	style,
	readOnly,
	tooltip
}) => {
	const selectOptions = options.map(option => (
		<option
			key={option.label}
			value={option.value}
			disabled={option.disabled}
			// selected={option.value === value ? true : false}
		>
			{option.label}
		</option>
	));
	return (
		<div className="form-group" style={style ? style.container : null}>
			{label ? (
				<label className="pl-2" htmlFor={name}>
					{label}
				</label>
			) : null}
			{tooltip && <Tooltip name={name} content={tooltip} />}

			<Input
				type="select"
				id={name}
				className={classnames("form-control", {
					"is-invalid": error
				})}
				name={name}
				onChange={onChange}
				value={value || ""}
				readOnly={readOnly}
			>
				<option value="" disabled />
				{selectOptions}
			</Input>
			{info && <small className="form-text text-muted">{info}</small>}
			{error && (
				<div className="invalid-feedback" id={name + "_error"}>
					{error}
				</div>
			)}
		</div>
	);
};

SelectListGroup.propTypes = {
	name: PropTypes.string.isRequired,
	info: PropTypes.string,
	error: PropTypes.string,
	onChange: PropTypes.func.isRequired,
	options: PropTypes.array.isRequired
};

export default SelectListGroup;
