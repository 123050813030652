import React, { Component } from "react";
import axios from "axios";
import { CORE_API_ROOT } from "../../config/api-config";
import qs from "querystring";
import TextFieldGroup from "../../components/common/TextFieldGroup";
import ContentBox from "../../components/common/ContentBox";
import {Row, Col, Button} from "reactstrap"

export default class ResetPassword extends Component {
	constructor() {
		super();
		this.state = {
			token: "",
			email: "",
			newPassword: "",
			verifyPassword: ""
		};
		this.handleChange = this.handleChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
	}
	componentDidMount() {
		const params = qs.parse(this.props.location.search.slice(1)); //{ ignoreQueryPrefix: true }
		this.setState({ token: params.token, email: params.email });
	}
	handleChange(e) {
		this.setState({ [e.target.name]: e.target.value });
		console.log(this.state.newPassword);
	}
	handleSubmit(e) {
		e.preventDefault();

		axios({
			method: "POST",
			url: `${CORE_API_ROOT}/users/resetPassword`,
			data: {
				email: this.state.email,
				code: this.state.token,
				password: this.state.newPassword,
				confirmPassword: this.state.verifyPassword
			}
		})
			.then(res => {
				alert("Your password has been updated.");
				this.props.history.push("/login");
			})
			.catch(err => {
				console.log(err);
				alert("Error: " + JSON.stringify(err.response.data));
			});
	}
	render() {
		return (
			<ContentBox width="6" title="Password Reset">
				<p className="text-muted">Reset Your Password</p>

				<form noValidate onSubmit={this.handleSubmit}>
					
					<TextFieldGroup
						readOnly="True"
						label="Email"
						placeholder=""
						name="email"
						type="email"
						value={this.state.email}
						onChange={this.handleChange}
					/>
					<TextFieldGroup
						label="New Password"
						placeholder=""
						name="newPassword"
						type="password"
						value={this.state.newPassword}
						onChange={this.handleChange}
						//error={errors.password}
					/>
					<TextFieldGroup
						label="Confirm Password"
						placeholder=""
						name="verifyPassword"
						type="password"
						value={this.state.verifyPassword}
						onChange={this.handleChange}
						//error={errors.password2}
					/>
					<Row>
						<Col xs="6">
							<Button color="primary" className="px-4">
								Submit
							</Button>
						</Col>
						
					</Row>
				</form>
			</ContentBox>

			// <div>
			// 	<h3>Password confirmation!</h3>
			// 	<form onSubmit={this.handleSubmit}>
			// 		<label for="newPassword">New Password</label>
			// 		<input
			// 			type="password"
			// 			name="newPassword"
			// 			onChange={this.handleChange}
			// 			required
			// 		/>
			// 		<label for="verifyPassword">Confirm Password</label>
			// 		<input
			// 			type="password"
			// 			name="verifyPassword"
			// 			onChange={this.handleChange}
			// 			required
			// 		/>
			// 		<input type="submit" name="submit" value="Reset Password" />
			// 	</form>
			// </div>
		);
	}
}
