import React from "react";
const TextDictionary = Object.freeze({
	ProjectSumbitted:
		"Your project has been submitted. You cannot make anymore changes. Allow up to 5 business days to review your project. ",
	ProjectRejected: "Your project has been rejected — Please contact us for details!",
	UpdateEmissionsRequireApproval:
		"The changes you made require approval. While you project is awaiting approval it will not be visible to the public.",
	UpdateEmissionsNoApproval:
		"The changes you made don't require approval. Changes will be directly visible to the public.",
	SubmitNote:
		"Submissions take approximately 5 business days before approval. We will confirm if all project details have been provided before " +
		"adding your project to the registry.",
	ProjectNotOwned: "This project was created by another user.",
	ProjectSubmitError: "Complete required fields before submitting",
	Tooltip: Object.freeze({
		ProgramType: "Select the framework or standards that this project was designed to follow.",
		ProjectType: "Select the category this project falls under.",
		ProjectStatus: (
			<React.Fragment>
				<i>Planning</i> = project in planning stage
				<br />
				<i>Proposed - Seeking Funding</i> = project proposed with financial plan attached
				<br />
				<i>Active</i> = in progress
				<br />
				<i>Inactive</i> = project crediting period ended
			</React.Fragment>
		),
		ProgramOfActivities: (
			<React.Fragment>
				Choose <i>Single Project</i> if this project only affects one location.
				<br /> Choose <i>Program of Activities</i> if the same project task occurs at multiple locations.
			</React.Fragment>
		),
		Funding:"Enter the total funding required. Make sure to add a financial plan under Project Documents.",
		Location: "Provide the written address of the primary location. For example: 123 Main St. If your project is a Program of Activities, only add one location.",
		MapLocation:
			"Provide the Latitude and Longitude of the project’s primary location, or edit the map and add a pin to identify the project location. If your project is a Program of Activities, only add one location." +
			"This is used to identify the exact location of the project.",
		Timeline:
			"Enter the start and end dates of the project. This will affect the number of years of Emission Reductions that are available.",
		FeaturedImage:
			"Add an image to represent this project. This image is used on the registry Home page and on the public Project List screen.",
		ProgramOfActivityImage:
			"Provide a map image that identifies the location of each program activity. The map on this screen is used to show the primary location only.",
		ProjectPlanDocument: "Add the project document that explains the project in detail.",
		FinancialPlanDocument: "Add the financial plan document that shows the total funding required for this proposed project.",
		ValidationDocument:
			"Add a self-reported document that validates that the project details are correct.",
		VerificationDocument:
			"Add a document from a third-party reviewer that verifies that the project does not contain any errors.",
		OtherDocument: "Add any additional documents that are relevant to the project.",
		GHGInventoryDocument /* obsolete? */:
			"Add the document outlining the greenhouse gas inventory for a location. You can create another inventory for another location",
		InventoryAnualDocument:
			"Add the document outlining the greenhouse gas inventory for a location. You can create another inventory for another location",
		ERRetiredAgainst: (
			<React.Fragment>
				You can only add values to this column if there are retired tonnes of CO2e. From the
				drop down list, select which greenhouse gas inventory you are retiring against. If
				you do not have any inventory recorded in the Local Carbon Registry, select{" "}
				<i>Other</i> and enter what you are retiring against in text.
			</React.Fragment>
		),
		ERInventoryYear:
			"For each year, choose if the listed <i>Tonnes CO2e</i> in the next column are the anticipated amount (Projected) or the actual amount (Realized). " +
			"If Realized, choose if the tonnes of emission reduction will simply be added to the registry (Add to Registry) and assigned a serial number, " +
			"or used to offset (Retired) a specific greenhouse gas inventory.",
		InvTrackOverview: (
			<React.Fragment>
				Select if the inventory can be used by projects to retire emission reductions
				<br/>
				Include = the inventory is included in the <i>GHG True-up Overview</i> page, and is available on project pages as an option to retire emission reductions.
				<br/>
				Exclude =  the inventory is only available on the Inventory List page.
			</React.Fragment>
			),
		InvTimeline:
			"Enter the start and end year of the inventory. You can add more inventory going forward by updating the end year.",
		InvReport:
			"Add the document outlining the greenhouse gas inventory for the corresponding year.",
		OffsetProvider:
			"Where you bought the carbon offsets.",
		OffsetVis:
			"Choose if this purchased offset will be listed on the public Local Carbon Registry website. If you choose Private, the purchased offset will only be accessible by others if you share one of the Overview pages.",
		OffsetTimeline:
			"Enter the start and end dates of the project.",
		OffsetYear:
			"For each year, choose if the listed <i>Tonnes CO2e</i> in the next column are the anticipated amount (Projected) or the actual amount (Realized). " +
			"If Realized, choose if the tonnes of emission reduction will simply be added to the registry (Add to Register), " +
			"or used to offset (Retired) a specific greenhouse gas inventory.",
	}),

	FileUpload: {
		NotPdf: "File must be PDF."
	}
});
export { TextDictionary };