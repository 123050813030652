import axios from "axios";
import setAuthToken from "../utils/setAuthToken";
import jwt_decode from "jwt-decode";
import { API_ROOT, CORE_API_ROOT } from "../config/api-config";

import {
	GET_ERRORS,
	SET_CURRENT_USER,
	REDIRECT,
	SET_TOKEN_EXPIRE_POPUP,
	SET_WORKING
} from "./types";

// Register User
export const registerUser = (userData, history) => dispatch => {
	dispatch({
		type: SET_WORKING,
		payload: true
	});
	axios
		.post(`${CORE_API_ROOT}/users/registerWithInfo`, userData)
		.then(res => {
			dispatch({
				type: SET_WORKING,
				payload: false
			});
			history.push("/register/complete");
		})
		.catch(err => {
			dispatch({
				type: SET_WORKING,
				payload: false
			});
			dispatch({
				type: GET_ERRORS,
				payload: err.response.data
			});
		});
};

export const renewSession = id => dispatch => {
	axios
		.post(`${API_ROOT}/users/sessionRenew`, id)
		.then(res => {
			// Save to localStorage
			const { token } = res.data;
			// Set token to ls
			localStorage.setItem("jwtToken", token);
			// Set token to Auth header
			setAuthToken(token);
			// Decode token to get user data
			const decoded = jwt_decode(token);

			// set some helper variables
			decoded.isAdmin =
				decoded.role === "Administrator" || decoded.role.indexOf("Administrator") >= 0;

			// Set current user
			dispatch(setCurrentUser(decoded));
			dispatch(setTokenExpirePopup(false));

			const currentTime = Date.now() / 1000;
			const expireTime = (decoded.exp - currentTime) * 1000;

			setTimeout(function() {
				dispatch(setTokenExpirePopup(true));
			}, expireTime);
		})
		.catch(err =>
			dispatch({
				type: GET_ERRORS,
				payload: err.response.data
			})
		);
};

export const dontRenewSession = () => dispatch => {
	dispatch(setTokenExpirePopup(false));
	window.location.href = "/login";
};

// Login - Get User Token
export const loginUser = userData => dispatch => {
	axios
		.post(`${CORE_API_ROOT}/users/login`, userData)
		.then(res => {
			// Save to localStorage
			const { token } = res.data;
			// Set token to ls
			localStorage.setItem("jwtToken", token);
			// Set token to Auth header
			setAuthToken(token);
			// Decode token to get user data
			const decoded = jwt_decode(token);

			// set some helper variables
			decoded.isAdmin =
				decoded.role === "Administrator" || decoded.role.indexOf("Administrator") >= 0;

			// Set current user
			dispatch(setCurrentUser(decoded));
			dispatch(setTokenExpirePopup(false));
			const currentTime = Date.now() / 1000;
			const expireTime = (decoded.exp - currentTime) * 1000;

			setTimeout(function() {
				dispatch(setTokenExpirePopup(true));
			}, expireTime);
		})
		.catch(err =>
			dispatch({
				type: GET_ERRORS,
				payload: err.response.data
			})
		);
};

// Set logged in user
export const setCurrentUser = decoded => {
	return {
		type: SET_CURRENT_USER,
		payload: decoded
	};
};

export const setTokenExpirePopup = popup => {
	return {
		type: SET_TOKEN_EXPIRE_POPUP,
		payload: popup
	};
};

// Log user out
export const logoutUser = () => dispatch => {
	// Remove roken from localStorage
	localStorage.removeItem("jwtToken");
	// Remove auth header for futur requests
	setAuthToken(false);
	// Set current user to {} which will set isAuthenticated to false
	dispatch(setCurrentUser({}));
};

// Redirect
export const redirect = (path, history) => dispatch => {
	// dummy reducer to simplify debugging
	dispatch({ type: REDIRECT, payload: path });
	history.push(path);
};
