import React, { Component } from "react";
import { Route, Switch, withRouter } from "react-router-dom";
import { Container } from "reactstrap";
import { connect } from "react-redux";

import {
	// AppAside,
	// AppBreadcrumb,
	AppFooter
	// AppHeader,
	// AppSidebar,
	// AppSidebarFooter,
	// AppSidebarForm,
	// AppSidebarHeader,
	// AppSidebarMinimizer,
	// AppSidebarNav,
} from "@coreui/react";
// sidebar nav config
// import navigation from '../../_nav';
// routes config
import routes from "../../routes";
// import DefaultAside from './DefaultAside';
import DefaultFooter from "./DefaultFooter";
// import DefaultHeader from './DefaultHeader';
import BootstrapHeader from "./BootstrapHeader";

import { renewSession, setTokenExpirePopup, dontRenewSession } from "../../actions/authActions";

class DefaultLayout extends Component {
	render() {
		const { tokenExpiredPopup } = this.props.auth;

		return (
			<div className="app">
				<BootstrapHeader />
				{/* This div will prevent content from being hidden behind the navbar. Coreui AppHeader does it with pure css. */}
				<div style={{ marginTop: 55 }} />
				<div
					className="app-body"
					style={{
						marginTop: "0"
					}} /* override marginTop otherwise there is a gap on small devices */
				>
					<main className="main">
						{/* <AppBreadcrumb appRoutes={routes}/> */}
						{tokenExpiredPopup ? (
							<div className="sessionPopupContainer">
								<p>Your Session Has Expired</p>
								<p>Please login again</p>
								<div>									
									<button
										className="btn btn-secondary"
										onClick={() => this.props.dontRenewSession()}
									>
										OK
									</button>
								</div>
							</div>
						) : null}
						<Container>
							<Switch>
								{routes.map((route, idx) => {
									return route.component ? (
										<Route
											key={idx}
											path={route.path}
											exact={route.exact}
											name={route.name}
											render={props => <route.component {...props} />}
										/>
									) : null;
								})}
							</Switch>
						</Container>
					</main>
				</div>
				<AppFooter>
					<DefaultFooter />
				</AppFooter>
			</div>
		);
	}
}

const mapStateToProps = state => ({
	auth: state.auth
});

export default withRouter(
	connect(
		mapStateToProps,
		{ renewSession, setTokenExpirePopup, dontRenewSession }
	)(DefaultLayout)
);
