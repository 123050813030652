import React, { Component } from "react";
import { Row, Col } from "reactstrap";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import Spinner from "../../components/common/Spinner";
import { getPublicProjects } from "../../actions/projectActions";
import ProjectItem from "../../components/projects/ProjectItem";
import ContentBox from "../../components/common/ContentBox";
import qs from "querystring";
//import { ProgramTypeEnum } from "../../lib/Enums";

class Projects extends Component {
	componentDidMount() {
		this.props.getPublicProjects();
	}

	render() {
		const { publicProjects, loading } = this.props.project;
		let projectItems;
		var projects = publicProjects && publicProjects.filter(x => x.visibility === "Public");
		var subtitle = undefined;

		if (!projects || loading) {
			return <Spinner />;
		}

		const params = qs.parse(this.props.location.search.slice(1)); //{ ignoreQueryPrefix: true }

		if (params.serial) {
			projects = projects.filter(x =>
				x.projectSerial.startsWith(params.serial.substring(0, 12))
			);

			subtitle = "Containing Serial: " + params.serial;
		}

		if (projects.length > 0) {
			projectItems = projects.map(project => (
				<Col md="4" sm="6" key={project.projectSerial}>
					<ProjectItem project={project} />
				</Col>
			));
		} else {
			projectItems = <h4>No Projects found...</h4>;
		}

		return (
			<ContentBox title="Projects" width="12" subtitle={subtitle}>
				<Row>{projectItems}</Row>
			</ContentBox>
		);
	}
}

Projects.propTypes = {
	getPublicProjects: PropTypes.func.isRequired,
	project: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
	project: state.project
});

export default connect(
	mapStateToProps,
	{ getPublicProjects }
)(Projects);
