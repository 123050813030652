import { GET_ERRORS, CLEAR_ONE_ERROR } from "./types";

export const clearErrors = decoded => {
	return {
		type: GET_ERRORS,
		payload: {}
	};
};

export const clearOneError = prop => dispatch => {
	dispatch({
		type: CLEAR_ONE_ERROR,
		payload: prop
	});
	return;
};
