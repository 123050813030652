import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";
import { Input, InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap';

const IconTextFieldGroup = ({
	name,
	placeholder,
	value,
	error,
	info,
	type,
	onChange,
	disabled,
	autoComplete,
	icon,
	className
}) => {
	return (
		<InputGroup className={className ? className : "mb-3"}>
			<InputGroupAddon addonType="prepend">
				<InputGroupText>
					<i className={"icon-" + icon}></i>
				</InputGroupText>
			</InputGroupAddon>
			<Input type={type} id={name} className={classnames("form-control", {
				"is-invalid": error
			})}
				placeholder={placeholder}
				name={name}
				value={value || ""}
				onChange={onChange}
				disabled={disabled}
				autoComplete={autoComplete} />
			{info && <small className="form-text text-muted">{info}</small>}
			{error && <div className="invalid-feedback">{error}</div>}
		</InputGroup>
	);
};

IconTextFieldGroup.propTypes = {
	name: PropTypes.string.isRequired,
	placeholder: PropTypes.string,
	info: PropTypes.string,
	error: PropTypes.string,
	type: PropTypes.string.isRequired,
	onChange: PropTypes.func.isRequired,
	disabled: PropTypes.string,
	icon: PropTypes.string,
};

IconTextFieldGroup.defaultProps = {
	type: "text"
};

export default IconTextFieldGroup;
