import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import axios from "axios";

import TextFieldGroup from "../../components/common/TextFieldGroup";
import FileUploadFieldGroup from "../../components/common/FileUploadFieldGroup";

import { updateProfile } from "../../actions/profileActions";
import { CORE_API_ROOT } from "../../config/api-config";

import { Button, Col, Row } from "reactstrap";
import ContentBox from "../../components/common/ContentBox";

const required = function (text, show) {
	return (
		<React.Fragment>
			{text}
			{(show === undefined || show) && <i className="text-danger"> *</i>}
		</React.Fragment>
	);
};

class EditProfile extends Component {
	constructor(props) {
		super(props);
		this.state = {
			contactName: "",
			contactTel: "",
			mailingAddress: "",
			organization: "",
			logo: "",
			loading: true,
			errors: {},
		};

		this.onChange = this.onChange.bind(this);
		this.onSubmit = this.onSubmit.bind(this);
	}
	componentDidMount() {
		axios
			.get(`${CORE_API_ROOT}/users/current`)
			.then((res) => {
				res.data
					? this.setState({
							contactName: res.data.contactName,
							contactTel: res.data.contactTel,
							mailingAddress: res.data.mailingAddress,
							logo: { url: res.data.logo },
							email: res.data.email,
							organization: res.data.organization,
							loading: false,
					  })
					: this.setState({
							loading: false,
					  });
			})
			.catch((err) => console.log(err));
	}
	componentWillReceiveProps(nextProps) {
		if (nextProps.errors) {
			this.setState({ errors: nextProps.errors });
		}
	}

	onChange(e) {
		this.setState({ [e.target.name]: e.target.value });
	}
	onSubmit(e) {
		e.preventDefault();

		const profileData = {
			contactName: this.state.contactName,
			organization: this.state.organization,
			contactTel: this.state.contactTel,
			mailingAddress: this.state.mailingAddress,
			// make sure we send an empty string otherwise it will not be updated.
			logo: this.state.logo.url === null ? "" : this.state.logo.url,
		};

		this.props.updateProfile(profileData, this.props.history); // ✅
	}

	render() {
		const { errors } = this.state;
		return (
			<ContentBox
				width="10"
				title="Edit Your Profile"
				subtitle={
					<React.Fragment>Please complete your account information.<br/>* Indicates required field</React.Fragment>
				}
			>
				{this.state.loading ? (
					<div
						style={{
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
							height: "500px",
						}}
					>
						Loading...
					</div>
				) : (
					<form noValidate onSubmit={this.onSubmit}>
						<div>
							<Row>
								<Col md="6">
									<TextFieldGroup
										name="contactName"
										placeholder=""
										value={this.state.contactName}
										label={required("Contact Person")}
										onChange={this.onChange}
										error={errors.name}
									/>
								</Col>

								<Col md="6">
									<TextFieldGroup
										name="email"
										placeholder=""
										value={this.state.email}
										label="Email Address"
										onChange={this.onChange}
										error={errors.organizationName}
										readOnly={true}
									/>
								</Col>
							</Row>
							<Row>
								<Col md="6">
									<TextFieldGroup
										name="organization"
										placeholder=""
										value={this.state.organization}
										label={required("Organization Name")}
										onChange={this.onChange}
										error={errors.organization}
									/>
								</Col>
								{/* <Col md="6">
										<TextFieldGroup
											name="jobTitle"
											placeholder=""
											value={this.state.jobTitle}
											label="Job Title"
											onChange={this.onChange}
											error={errors.jobTitle}
										/>
									</Col> */}
							</Row>

							<Row>
								<Col md="6">
									<TextFieldGroup
										name="mailingAddress"
										placeholder=""
										value={this.state.mailingAddress}
										label={required("Mailing Address")}
										onChange={this.onChange}
										error={errors.mailingAddress}
									/>
								</Col>
								<Col md="6">
									<TextFieldGroup
										name="contactTel"
										placeholder=""
										value={this.state.contactTel}
										label={required("Contact Number")}
										onChange={this.onChange}
										error={errors.contactTel}
									/>
								</Col>
							</Row>

							<Row>
								<Col md="6">
									<FileUploadFieldGroup
										label="Organization Logo"
										name="logo"
										onChange={this.onChange}
										value={this.state.logo}
										file="logo"
										readOnly={false}
									/>
								</Col>
							</Row>

							<Row>
								<Col md="12" className="text-right">
									<Button color="primary" style={{ minWidth: "200px" }}>
										Save
									</Button>
								</Col>
							</Row>
						</div>
					</form>
				)}
			</ContentBox>
		);
	}
}
EditProfile.propTypes = {
	// profile: PropTypes.object.isRequired,
	errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
	// profile: state.profile,
	errors: state.errors,
	profile: state.profile,
});

export default connect(mapStateToProps, { updateProfile })(withRouter(EditProfile));
