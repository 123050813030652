import React, { Component } from "react";
import axios from "axios";

import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Input } from "reactstrap";
import { CORE_API_ROOT } from "../../config/api-config";

class RestPasswordButton extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showModal: false,
			password: ""
		};
	}

	toggleModal = () => {
		this.setState({ showModal: !this.state.showModal });
	};

	updatePassword = () => {
		axios({
			method: "PUT",
			url: `${CORE_API_ROOT}/users/${this.props.userId}/password`,
			data: {
				password: this.state.password
			}
		})
			.then(res => {
				alert("Password has been updated.");
				this.toggleModal();
			})
			.catch(err => alert("Password Update Failed: " + err.response.data))
			.finally(() => {});
	};

	render() {
		return (
			<div>
				<Button
					size="sm"
					className="ml-2 mt-2"
					color="primary"
					onClick={_ => {
						this.setState({
							showModal: true,
							password: ""
						});
					}}
				>
					Update Password
				</Button>

				<Modal isOpen={this.state.showModal} toggle={this.toggleModal}>
					<ModalHeader toggle={this.toggleModal}>Update Password</ModalHeader>
					<ModalBody>
						New Password
						<Input
							onChange={e => this.setState({ password: e.target.value })}
							value={this.state.password}
						/>
					</ModalBody>
					<ModalFooter>
						<Button color="primary" onClick={this.updatePassword}>
							OK
						</Button>{" "}
						<Button color="secondary" onClick={this.toggleModal}>
							Cancel
						</Button>
					</ModalFooter>
				</Modal>
			</div>
		);
	}
}

export default RestPasswordButton;
