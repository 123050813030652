import React, { Component } from "react";
import { Link } from "react-router-dom";

class ProjectItem extends Component {
	render() {
		let status = this.props.status;

		return (
			<li>
				<Link
					className={
						this.props.showHistory
							? "listColumnsWithHistory"
							: "listColumnsWithoutHistory"
					}
					to={`/profile/drafts/${this.props.projectId}`}
				>
					<p>{this.props.projectSerial}</p>
					<p>{this.props.title}</p>
					{this.props.showHistory && <p>{this.props.type}</p>}
					{this.props.showHistory && <p>{this.props.organization}</p>}
					<p>{this.props.date}</p>
					<p>{status}</p>
					{this.props.showHistory && (
						<p>
							<Link to={`/projects/history/${this.props.projectId}`}>History</Link>
						</p>
					)}
				</Link>
			</li>
		);
	}
}

export default ProjectItem;
