import React, { Component } from "react";
import ContentBox from "../../components/common/ContentBox";

class About extends Component {
	render() {
		return (
			<ContentBox title="Local Carbon Registry" width="12">
				<p>
					Welcome to the Local Carbon Registry. Our registry promotes transparency and
					credibility for GHG emission reductions and offsets from local emission
					reduction projects. The registry will make your project count even more by
					featuring important details on each project’s current and projected
					environmental impacts in a user-friendly way.
				</p>
				<p>
					Sign up for an account and raise the profile of your projects for the world to
					see. Our mobile application has been custom-built for easy browsing and sharing
					of projects and learnings. Your project registration fees fund the operation and
					maintenance of the online registry.
				</p>
				<p>
					It’s easy to get started. First, go to ‘Register’ to request an account. Our
					team will get back to you within 3 business days, and you will be able to
					complete your account registration. Once your account is set up, you’ll need to
					make your project registration fee payment to start adding your projects to the
					registry.
				</p>
				<p>
					The project registration fee is a contribution to cover the cost of running the
					registry and providing registry-related services. We ask that you make a minimum
					contribution of $500, however if you like what you see, please consider
					contributing a bit more. This will allow us to offer additional services and
					more knowledge sharing functions. Each project registration fee payment will
					offer you the ability to list, register and feature one project. For each
					additional project, you will need to make another project registration fee
					contribution.
				</p>
				<p>
					For each project you would like to submit, we ask that you provide in-depth
					project details, such as: project status, project description, project documents
					(e.g. Project Verification Report), site pictures, etc. You will input all of
					this information on to a single project page, and then submit the information to
					the registration team. The team will review your submission and either approve
					the registration or they will provide you with feedback and assistance on how
					the project information can be improved. Alternatively, we also offer a service
					to set up your projects for you. Just ask us about that service.
				</p>
				<p>
					Once the project details have been approved, we will send you a confirmation
					email stating that your project is now registered and available for viewing by
					the general public.
				</p>
				<p>
					Each project will receive a unique project identifier in the form of a serial
					number. In addition, you will be able to register each tonne of CO2e of emission
					reductions in the registry under your project. Each tonne will also be issued
					with a unique identifier in the form of a serial number. Both of which will add
					not only a clear reference but also quality criteria to your project.
				</p>
				<p>
					The serial number can be used if you are communicating about the achieved
					emission reductions and if you are using them as offsets. If you are using the
					emission reductions as offsets, you will be able to retire each tonne of CO2e of
					emission reductions to reduce the carbon footprint of an emissions intensive
					activity. In all cases in which you are communicating about your emission
					reductions with the serial number, the information recipient is able to visit
					the registry and verify the information about the originating project of the
					emission reductions. This way, you can add significant value and boost the
					credibility of your project, as well as publicly acknowledging your success.
				</p>
				<p>
					Give our team a shout if you have any questions during this process –we’re here
					to help. Please contact us anytime at:
				</p>

				<p>
					<a href="mailto:Support@LocalCarbonRegistry.info">
						Support@LocalCarbonRegistry.info
					</a>
					.
				</p>
			</ContentBox>
		);
	}
}

export default About;
