import React, { Component } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { Map, Marker, TileLayer } from "react-leaflet";
import { faPencilAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class LocationPicker extends Component {
	constructor(props) {
		super(props);
		this.state = { show: false, latitude: 0, longitude: 0, mapZoom: 8 };
	}

	toggelModal = () => {
		this.setState({ show: !this.state.show });
	};

	//componentWillReceiveProps(newProps) {
	componentDidUpdate(prevProps, prevState, snapshot) {
		const newProps = this.props;
		if (prevState.show !== this.state.show && !prevState.show)
			this.setState({ latitude: newProps.latitude, longitude: newProps.longitude });
	}

	render() {
		return (
			<div>
				<Button
					disabled={this.props.disabled}
					size="sm"
					color="primary"
					onClick={_ => {
						this.setState({
							show: true
						});
					}}
				>
					<FontAwesomeIcon style={{ width: 20, height: 20 }} icon={faPencilAlt} />
				</Button>

				<Modal isOpen={this.state.show} toggle={this.toggelModal} size="xl">
					<ModalHeader toggle={this.toggelModal}>
						Select Location (Click on map to update location)
					</ModalHeader>
					<ModalBody>
						<div width={1000} height={1000}>
							<Map
								ref={ref => {
									this.map = ref;
								}}
								className="picker-map"
								center={[
									parseFloat(this.state.latitude) || 49.26038,
									parseFloat(this.state.longitude) || -123.11336
								]}
								zoom={this.state.mapZoom}
								onClick={e => {
									console.log(this.map.leafletElement.getZoom());
									this.setState({
										latitude: e.latlng.lat,
										longitude: e.latlng.lng,
										mapZoom: this.map.leafletElement.getZoom()
									});
								}}
							>
								<TileLayer
									url="https://cartodb-basemaps-{s}.global.ssl.fastly.net/light_all/{z}/{x}/{y}.png"
									attribution='&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>, &copy; <a href="https://carto.com/attribution">CARTO</a>'
								/>
								{this.state.latitude && this.state.longitude && (
									<Marker
										position={{
											lat: parseFloat(this.state.latitude) || 0,
											lng: parseFloat(this.state.longitude) || 0
										}}
									/>
								)}
							</Map>
						</div>
					</ModalBody>
					<ModalFooter>
						<Button
							color="primary"
							onClick={() => {
								this.props.onChange(this.state);
								this.toggelModal();
							}}
						>
							Update
						</Button>{" "}
						<Button color="secondary" onClick={this.toggelModal}>
							Cancel
						</Button>
					</ModalFooter>
				</Modal>
			</div>
		);
	}
}

export default LocationPicker;
