import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import axios from "axios";

import {
	getRequests,
	getUsers,
	getUserData,
	getAllProjects,
	clearUserData,
	getProjects
} from "../../actions/adminActions";
import RequestList from "../../components/admin/RequestList";
import Spinner from "../../components/common/Spinner";
import { CORE_API_ROOT } from "../../config/api-config";

import "./Admin.css";
import ContentBox from "../../components/common/ContentBox";

class AdminRequests extends Component {
	constructor(props) {
		super(props);

		this.handleApproveClick = this.handleApproveClick.bind(this);
		this.handleRejectClick = this.handleRejectClick.bind(this);
	}
	componentDidMount() {
		this.props.getRequests();
	}
	handleApproveClick(id) {
		axios({
			method: "POST",
			url: `${CORE_API_ROOT}/users/approve/${id}`,
			data: {}
		}).then(res => {
			// TODO create dispatch on backend instead
			this.props.getRequests();
		});
	}
	handleRejectClick(id) {
		axios({
			method: "POST",
			url: `${CORE_API_ROOT}/users/reject/${id}`,
			data: {
				id: id
			}
		}).then(res => {
			// TODO create dispatch on backend instead
			this.props.getRequests();
		});
	}
	render() {
		const { requests, admin } = this.props;
		return (
			<div>
				{!requests || admin.requestsLoading ? (
					<Spinner />
				) : (
					<ContentBox title="User Requests" width="12">
						<RequestList
							requests={requests}
							handleApproveClick={this.handleApproveClick}
							handleRejectClick={this.handleRejectClick}
						/>
					</ContentBox>
				)}
			</div>
		);
	}
}

AdminRequests.propTypes = {
	getRequests: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
	admin: state.admin,
	requests: state.admin.requests,
	users: state.admin.users,
	userData: state.admin.userData,
	projects: state.admin.projects,
	projectOccurrences: state.admin.projectOccurrences
});

export default connect(
	mapStateToProps,
	{ getRequests, getUsers, getUserData, getAllProjects, clearUserData, getProjects }
)(AdminRequests);
