import React, { Component } from "react";
import { Table, Col, Row } from "reactstrap";
import classnames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faAngleDown,
	faAngleRight,
} from "@fortawesome/free-solid-svg-icons";

import { add } from "../../lib/Helpers";
import { StatusEnum, CombineEmissions, SplitEmissions } from "../../lib/Emissions";
import { getSymbol } from "../../lib/Symbols";

function onlyUnique(value, index, self) {
	return self.indexOf(value) === index;
}

function calcSerial(start, count) {
	return (
		splitSerial(start).project +
		"-" +
		(parseInt(splitSerial(start).serial) + count).toString().padStart(6, "0")
	);
}

function splitSerial(serial) {
	const split = serial.split("-");
	return { serial: split[split.length - 1], project: split.slice(0, split.length - 1).join("-") };
}

//const EmissionReduction = ({ onChange, erRow, erUpdate, value }) => {

/*
	Special model states (old):
	- isEmpty: Only on first element of a year, means that the summary field is empty
	- count == null: Count field empty

	Special model states (new):
	- isSumEmpty: Only on first element of a year, means that the summary field is empty
	- isRowEmpty: Count field empty

*/

class EmissionReductionView extends Component {
	constructor(props) {
		super(props);

		this.onChangeSum = this.onChangeSum.bind(this);
	}
	totalTons(data, year) {
		return data
			.filter(x => x.year === year)
			.map(x => (x.state === StatusEnum.Cancelled ? 0 : parseInt(x.count) || 0))
			.reduce(add, 0);
	}

	registerdTons(data, year) {
		return data
			.filter(x => x.year === year && x.state === StatusEnum.Registered)
			.map(x => parseInt(x.count) || 0)
			.reduce(add, 0);
	}

	cancelledTons(data, year) {
		return data
			.filter(x => x.year === year && x.state === StatusEnum.Cancelled)
			.map(x => parseInt(x.count) || 0)
			.reduce(add, 0);
	}

	getLastElementIdx(newData, year) {
		var lastIdx = 0;
		while (
			lastIdx < newData.length &&
			(lastIdx !== newData.length - 1 && newData[lastIdx + 1].year <= year)
		) {
			lastIdx++;
		}
		return lastIdx;
	}

	isAdjust(newData, lastIdx) {
		if (
			(newData[lastIdx].state === StatusEnum.Registered ||
				newData[lastIdx].state === StatusEnum.Projected) &&
			!newData[lastIdx].serialMin
		) {
			return true;
		} else {
			return false;
		}
	}

	addOffsetTons(newData, diff, year, idx) {
		if (diff !== 0) {
			// find last entry of that year
			var lastIdx = 0;
			while (
				lastIdx < newData.length &&
				(lastIdx !== newData.length - 1 && newData[lastIdx + 1].year <= year)
			) {
				lastIdx++;
			}

			if (
				(newData[lastIdx].state === StatusEnum.Registered ||
					newData[lastIdx].state === StatusEnum.Projected) &&
				!newData[lastIdx].serialMin
			) {
				newData[lastIdx].count = parseInt(newData[lastIdx].count) + diff;
				// remove empty row
				// but only if it's not the only row for that year
				if (
					newData[lastIdx].count === 0 &&
					lastIdx !== 0 &&
					newData[lastIdx - 1].year === year
				) {
					newData.splice(lastIdx, 1);
				}
			} else {
				newData.splice(lastIdx + 1, 0, {
					year: year,
					state: StatusEnum.Registered,
					count: diff
				});
			}
		}
	}

	onChangeSum(year, e, idx) {
		const oldData = this.props.value;
		// console.log(e)
		// console.log(erRow)
		const calculatedSum = this.totalTons(oldData, year);
		const newValue = e.target.value;

		const diff = (parseInt(newValue) || 0) - calculatedSum;

		var newData = oldData.slice(0);

		if (newValue === "") {
			newData[idx].isEmpty = true;
		} else {
			delete newData[idx].isEmpty;
		}

		// add a registered row if we added some co2
		this.addOffsetTons(newData, diff, year, idx);

		//newData[idx].reductionSum = newValue

		this.props.onChange(newData);
		//console.log(diff);
	}

	onChangeCount(idx, e) {
		const newData = this.props.value.map(a => Object.assign({}, a)); //oldData.slice(0);
		const diff = (parseInt(e.target.value) || 0) - (parseInt(newData[idx].count) || 0);
		newData[idx].count = parseInt(e.target.value);

		if (newData[idx].serialMin) {
			// element with serial needs special handling
			if (
				idx < newData.length - 1 &&
				newData[idx + 1].year === newData[idx].year &&
				newData[idx + 1].serialMin ===
					calcSerial(newData[idx].serialMin, (newData[idx].count || 0) - diff) &&
				newData[idx + 1].state === StatusEnum.Registered
			) {
				// there is an adjacent block
				newData[idx + 1].count += -diff;
				newData[idx + 1].serialMin = calcSerial(newData[idx + 1].serialMin, diff);

				if (newData[idx + 1].count < 0 || newData[idx].count < 0) {
					// not allowed to create negative rows with serial
					console.log("not allowed");
					return;
				}

				if (newData[idx + 1].count === 0) {
					newData.splice(idx + 1, 1);
				}
			} else {
				if (diff > 0) {
					// not allowed to create negative rows with serial
					console.log("not allowed");
					return;
				}
				// split the block
				newData.splice(idx + 1, 0, {
					year: newData[idx].year,
					state: StatusEnum.Registered,
					count: -diff,
					serialMin: calcSerial(newData[idx].serialMin, newData[idx].count || 0)
				});
			}
		} else {
			//if (newData[idx].state !== StatusEnum.Registered) {
			if (
				idx === newData.length - 1 ||
				(newData[idx + 1].year !== newData[idx].year &&
					newData[idx].state === StatusEnum.Registered)
			) {
				// add a new row if we mauannly adjust the last element
				newData.splice(idx + 1, 0, {
					year: newData[idx].year,
					state: StatusEnum.Registered,
					count: 0
				});
			}
			this.addOffsetTons(newData, -diff, newData[idx].year, idx);
		}

		this.props.onChange(newData);
		//console.log(diff);
	}

	onRemoveRow(idx) {
		const newData = this.props.value.map(a => Object.assign({}, a));
		var oldValue = parseInt(newData[idx].count) || 0;
		this.addOffsetTons(newData, oldValue, newData[idx].year, -1 /* usused */);

		// keep the year open when the first element is removed.
		if (newData[idx].isOpen) {
			newData[idx + 1].isOpen = true;
		}

		newData.splice(idx, 1);

		this.props.onChange(newData);
	}

	onChangeStatus(idx, e) {
		const newData = this.props.value.slice(0);

		if (newData[idx].state === StatusEnum.Projected) {
			newData[idx].isOpen = true;
		}

		newData[idx].state = e.target.value;

		// add a flag to allow editing blocks that have a serial but are not commited in the backend yet.
		if (newData[idx].serialMin) {
			newData[idx].edited = true;
		}

		this.props.onChange(newData);
	}

	onChangeNote(idx, e) {
		const newData = this.props.value.slice(0);
		newData[idx].retiredAgainst = e.target.value;

		this.props.onChange(newData);
	}

	renderYearOverview(obj, rows, restricted) {
		const idx = obj.idx;

		const isProjected = obj.state === StatusEnum.Projected;

		return (
			<React.Fragment>
				<tr
					className="erTableData"
					style={{
						backgroundColor:
							obj.state === StatusEnum.Projected ? "lightblue" : "rgba(0,0,0,.05)"
					}}
				>
					<th scope="row" className="align-middle">
						<div className="text-nowrap text-right">
							<button
								className="link-button"
								onClick={e => {
									e.preventDefault();
									const newData = this.props.value.slice(0);
									newData[idx].isOpen = !newData[idx].isOpen;
									this.props.onChange(newData);
								}}
							>
								{!isProjected && obj.isOpen && (
									<FontAwesomeIcon icon={faAngleDown} className="mr-1" />
								)}

								{!isProjected && !obj.isOpen && (
									<FontAwesomeIcon icon={faAngleRight} className="mr-1" />
								)}

								{obj.year}
							</button>
						</div>
					</th>

					<td className="text-center">
						{obj.reductionSum || this.totalTons(rows, obj.year)}
					</td>
					<td>
						<div className="d-inline-block">{getSymbol(isProjected ? "Projected" : "Realized")}</div>
						<div className="d-none d-sm-inline-block pl-1">
							{isProjected ? "Projected" : "Realized"}{" "}
						</div>
					</td>
					<td />
					<td />
				</tr>
			</React.Fragment>
		);
	}

	renderRow(obj, rows, restricted, isOpen) {
		// assumption: ordered by years
		const idx = obj.idx;
		//const showYear = idx === 0 || rows[idx - 1].year !== obj.year;
		const isProjected = obj.state === StatusEnum.Projected;

		const isHidden = isProjected;

		const state = obj.state === StatusEnum.RetiredInventory?"Retired": obj.state;


		var retiredAgainst = obj.retiredAgainst;
		if(obj.state === StatusEnum.RetiredInventory) {			
			retiredAgainst = this.props.projectNames[obj.inventoryProject] +"-"+ obj.inventoryYear;
		}


		return (
			<React.Fragment key={idx}>
				{!isHidden && (
					<tr
						className={
							classnames({ erTableData: true, collapse: true, show: isOpen }) //"erTableData collapse" + isOpen ? " show" : ""
						}
					>
						<th scope="row" />
						<td className="text-center">{obj.count}</td>
						<td>
							<div className="d-inline-block">{getSymbol(obj.state)}</div>
							<div className="d-none d-sm-inline-block pl-1">{state} </div>
						</td>
						<td>{retiredAgainst}</td>
						<td className="align-top">
							{obj.serialMin ? (
								<React.Fragment>
									{obj.serialMin}{" "}
									{obj.count > 1 &&
										" to " +
											splitSerial(calcSerial(obj.serialMin, obj.count - 1))
												.serial}
								</React.Fragment>
							) : (
								""
							)}
						</td>
					</tr>
				)}
			</React.Fragment>
		);
	}

	render() {
		//({ onChange, erRow, erUpdate, value } = this.props);
		//const onChange = this.props.onChange;
		const erRow = this.props.value.map((obj, idx) => ({ idx: idx, ...obj }));

		const years = this.props.value
			.map(obj => obj.year)
			.filter(onlyUnique)
			.reverse();

		const restricted = this.props.restricted;

		return (
			<div>
				<Row>
					<Col lg="10">
						<div className="erBorder">
							<Table borderless size="sm">
								<thead>
									<tr>
										<th
											style={{
												width:
													"1px" /* Keep the year colum as small as possible*/
											}}
										>
											Vintage
										</th>
										<th>
											<div className="d-none d-md-block">
												{/* Reduction in <br /> */}
											</div>
											<div className="d-none d-md-inline-block">Tonnes&nbsp;</div>
											CO 
											<sub>2</sub>e
										</th>
										<th>Status</th>
										<th>Retired Against</th>
										<th>Serial Range</th>
									</tr>
								</thead>
								<tbody>
									{years.map(year => {
										const elements = erRow.filter(x => x.year === year);
										return (
											<React.Fragment key={year}>
												{this.renderYearOverview(
													elements[0],
													erRow,
													restricted
												)}
												{erRow
													.filter(x => x.year === year)
													.map(obj =>
														this.renderRow(
															obj,
															erRow,
															restricted,
															elements[0].isOpen
														)
													)}
											</React.Fragment>
										);
									})}
								</tbody>
							</Table>
						</div>
					</Col>
				</Row>
				{/* Test: 
					<p className="m-0">{JSON.stringify(obj, null, 2)}</p>
				))}
				{this.splitEmissions(this.props.value).draftEmissionReductions.map(obj => (
					<p className="m-0">{JSON.stringify(obj, null, 2)}</p>
				))} 
				<pre>{JSON.stringify(this.splitEmissions(this.props.value), null, 2)}</pre>

				Combined:
				{this.combineEmissions(this.splitEmissions(this.props.value)).map(obj => (
					<p className="m-0">{JSON.stringify(obj, null, 2)}</p>
				))} */}
			</div>
		);
	}
}

class SplitEmissionReductionView extends Component {

	render() {
		if (!this.props.value) return null;
		return (
			<EmissionReductionView
				value={CombineEmissions(this.props.value)}
				onChange={x => this.props.onChange(SplitEmissions(x))}
				restricted={this.props.restricted}
				readOnly={this.props.readOnly}
				error={this.props.error || {}}
				projectNames={this.props.projectNames}
			/>
		);
	}
}

export default SplitEmissionReductionView;
