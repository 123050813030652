import axios from "axios";

import {
	GET_ERRORS,
	PROJECT_LOADING,
	PROJECT_TOKENS_LOADING,
	GET_PROJECTS,
	GET_PUBLIC_PROJECTS,
	GET_USER_PROJECTS,
	GET_PROJECT,
	GET_FEATURED_PROJECTS,
	GET_RANGE,
	GET_TOKENS,
	PROJECT_CLEAR,
} from "./types";
import { API_ROOT, USER_HOME_PATH, CORE_API_ROOT } from "../config/api-config";
import { redirect } from "./authActions";
import { getDraft } from "./draftActions";
import { clearErrors } from "./errorActions";

// Get current User projects
export const getUserProjects = (currentUser) => (dispatch) => {
	dispatch(setProjectLoading());
	axios
		.get(`${CORE_API_ROOT}/projects?AccountHolder=${currentUser}`)
		.then((res) => {
			dispatch({
				type: GET_USER_PROJECTS,
				payload: res.data,
			});
		})
		.catch((err) =>
			dispatch({
				type: GET_USER_PROJECTS,
				payload: null,
			})
		);
};

async function save_project(projectData) {
	var res;

	if (projectData.projectSerial) {
		// update existing project
		res = await axios.put(
			`${CORE_API_ROOT}/projects/${projectData.projectSerial}`,
			projectData
		);
	} else {
		//create new project
		delete projectData.id;
		res = await axios.post(`${CORE_API_ROOT}/projects`, projectData);
	}

	const projectSerial = projectData.projectSerial || res.data.projectSerial;

	return projectSerial;
}

// Create Project
export const createProject = (projectData, history) => async (dispatch) => {
	try {
		await save_project(projectData);

		dispatch(clearProject());
		dispatch(redirect(USER_HOME_PATH, history));
	} catch (err) {
		if (err.response && err.response.status === 400) {
			dispatch({
				type: GET_ERRORS,
				payload: err.response.data,
			});
		} else {
			alert(err.toString());
			console.log(err);
			return null;
		}
	}

	return null;
};

export const submitProject = (projectData, history) => async (dispatch) => {
	var projectSerial = "";
	try {
		projectSerial = await save_project(projectData);

		await axios.post(`${CORE_API_ROOT}/projects/${projectSerial}/submit`);

		dispatch(clearErrors());
		dispatch(clearProject());
		dispatch(redirect(`/profile/drafts/${projectSerial}`, history));
		dispatch(getDraft(projectSerial));
		dispatch(getPublicProjects());
	} catch (err) {
		if (err.response && err.response.status === 400) {
			dispatch({
				type: GET_ERRORS,
				payload: err.response.data,
			});
		} else {
			alert(err.toString());
			console.log(err);
			return null;
		}
	}

	// return project serial to be able to update the state if needed
	return projectSerial;
};

export const approveProject = (projectSerial) => async (dispatch) => {
	try {
		await axios.post(`${CORE_API_ROOT}/projects/${projectSerial}/approve`);

		dispatch(getDraft(projectSerial));
	} catch (err) {
		alert("Error Approving Project");
		console.log(err);
	}

	return;
};

export const rejectProject = (projectSerial) => async (dispatch) => {
	try {
		await axios.post(`${CORE_API_ROOT}/projects/${projectSerial}/reject`);

		dispatch(getDraft(projectSerial));
	} catch (err) {
		alert("Error Rejecting Project");
		console.log(err);
	}
	return;
};

export const reopenProject = (projectSerial) => async (dispatch) => {
	try {
		await axios.post(`${CORE_API_ROOT}/projects/${projectSerial}/reopen`);

		dispatch(getDraft(projectSerial));
	} catch (err) {
		alert("Error Reopening Project");
		console.log(err);
	}

	return;
};

export const deleteProject = (projectSerial) => async (dispatch) => {
	try {
		await axios.delete(`${CORE_API_ROOT}/projects/${projectSerial}`);

		//dispatch(redirect(`/admin/projects`, history));
		dispatch(clearProject());
	} catch (err) {
		alert("Error Deleting Project");
		console.log(err);
	}

	return;
};

export const featureProject = (projectSerial, isFeatured) => async (dispatch) => {
	try {
		await axios.put(`${CORE_API_ROOT}/projects/${projectSerial}/featured`, {
			featured: isFeatured,
		});

		dispatch(getDraft(projectSerial));
	} catch (err) {
		alert("Error Featuring Project");
		console.log(err);
	}

	return;
};

export const archiveProject = (projectSerial, isArchived) => async (dispatch) => {
	try {
		await axios.put(`${CORE_API_ROOT}/projects/${projectSerial}/archived`, {
			archived: isArchived,
		});

		dispatch(getDraft(projectSerial));
	} catch (err) {
		alert("Error Archiving Project");
		console.log(err);
	}

	return;
};

export const updateEmissions =
	(projectSerial, emissions, currentUser, history) => async (dispatch) => {
		try {
			await axios.post(
				`${CORE_API_ROOT}/projects/${projectSerial}/updateEmissions`,
				emissions
			);

			dispatch(clearErrors());
			dispatch(clearProject());
			dispatch(getDraft(projectSerial));
			dispatch(getUserProjects(currentUser));
			dispatch(getPublicProjects());

			//dispatch(redirect(USER_HOME_PATH, history));
		} catch (err) {
			if (err.response) {
				dispatch({
					type: GET_ERRORS,
					payload: err.response.data,
				});
			} else {
				alert("Error Updating Emissions");
				console.log(err);
			}
		}

		return;
	};

// Get all projects
export const getProjects = () => (dispatch) => {
	dispatch(setProjectLoading());
	axios
		.get(`${API_ROOT}/projects`)
		.then((res) =>
			dispatch({
				type: GET_PROJECTS,
				payload: res.data,
			})
		)
		.catch((err) =>
			dispatch({
				type: GET_PROJECTS,
				payload: null,
			})
		);
};

// Get public projects
export const getPublicProjects = () => (dispatch, getState) => {
	if (getState().project.publicProjects) {
		// only do request if we haven't fetched the data yet
		return;
	}

	dispatch(setProjectLoading());
	axios
		.get(`${CORE_API_ROOT}/projects/public`)
		.then((res) =>
			dispatch({
				type: GET_PUBLIC_PROJECTS,
				payload: res.data,
			})
		)
		.catch((err) =>
			dispatch({
				type: GET_PUBLIC_PROJECTS,
				payload: null,
			})
		);
};

// Get featured random projects
export const getFeaturedProjects = () => (dispatch) => {
	dispatch(setProjectLoading());
	axios
		.get(`${API_ROOT}/projects/random`)
		.then((res) =>
			dispatch({
				type: GET_FEATURED_PROJECTS,
				payload: res.data,
			})
		)
		.catch((err) =>
			dispatch({
				type: GET_FEATURED_PROJECTS,
				payload: null,
			})
		);
};

// Get Single project
export const getProject = (id) => (dispatch) => {
	dispatch(setProjectLoading());
	axios
		.get(`${CORE_API_ROOT}/projects/${id}`)
		.then((res) => {
			dispatch({
				type: GET_PROJECT,
				payload: res.data,
			});
		})
		.catch((err) =>
			dispatch({
				type: GET_PROJECT,
				payload: null,
			})
		);
};

// Create Emission Reduction table
export const createERTable = (start, finish) => (dispatch) => {
	const startYear = new Date(start).getFullYear();
	const finishYear = new Date(finish).getFullYear();
	const diff = finishYear - startYear;
	const range = [...Array(diff + 1).keys()].map((i) => ({
		year: i + startYear,
		reduction: "",
		erStatus: "",
		retiredAgainst: "",
	}));
	dispatch({
		type: GET_RANGE,
		payload: range,
	});
};

// Get User Project Tokens
export const getUserProjectTokens = () => (dispatch) => {
	dispatch(setProjectTokensLoading());
	axios
		.get(`${CORE_API_ROOT}/users/current`)
		.then((res) => {
			dispatch({
				type: GET_TOKENS,
				payload: {
					projectTokens: res.data.projectTokens,
					inventoryTokens: res.data.inventoryTokens,
					micrositeTokens: res.data.micrositeTokens,
				},
			});
		})
		.catch((err) => {
			dispatch({
				type: GET_TOKENS,
				payload: {},
			});
		});
};

// Project loading
export const setProjectLoading = () => {
	return {
		type: PROJECT_LOADING,
	};
};

// Project loading
export const setProjectTokensLoading = () => {
	return {
		type: PROJECT_TOKENS_LOADING,
	};
};

// Clear Project
export const clearProject = () => {
	return {
		type: PROJECT_CLEAR,
	};
};
