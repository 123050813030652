import React, { Component } from "react";
import axios from "axios";
import moment from "moment";

import TextFieldGroup from "../../components/common/TextFieldGroup";
import TextAreaFieldGroup from "../../components/common/TextAreaFieldGroup";
import SelectListGroup from "../../components/common/SelectListGroup";
import FileUploadFieldGroup from "../../components/common/FileUploadFieldGroup";

import EmissionReduction from "../../components/emissionReduction/EmissionReduction";
import { API_ROOT } from "../../config/api-config";

class Project extends Component {
	constructor() {
		super();
		this.state = {
			id: ""
		};

		this.onChange = this.onChange.bind(this);
		this.timelineUpdated = this.timelineUpdated.bind(this);
		this.erRange = this.erRange.bind(this);
		this.fileUpload = this.fileUpload.bind(this);
		this.onFileRemove = this.onFileRemove.bind(this);
		this.onErChange = this.onErChange.bind(this);
		this.onButtonClick = this.onButtonClick.bind(this);
		this.onErUpdate = this.onErUpdate.bind(this);
	}
	componentDidMount() {
		console.log("compdidmount");
		axios
			.get(`${API_ROOT}/admin/projects/${this.props.match.params.id}`)
			.then(projectData => {
				console.log(projectData.data._id);
				this.setState({
					id: projectData.data._id,
					title: projectData.data.title,
					description: projectData.data.description,
					featured: projectData.data.pictures.featured,
					projectSite: projectData.data.pictures.projectSite,
					before: projectData.data.pictures.before,
					activity: projectData.data.pictures.activity,
					otherImage: projectData.data.pictures.otherImage,
					programType: projectData.data.programType,
					projectType: projectData.data.projectType,
					projectStatus: projectData.data.projectStatus,
					startYear: projectData.data.timeline.startYear,
					finishYear: projectData.data.timeline.finishYear,
					startMonth: projectData.data.timeline.startMonth,
					finishMonth: projectData.data.timeline.finishMonth,
					address: projectData.data.location.address,
					latitude: projectData.data.location.latitude,
					longitude: projectData.data.location.longitude,
					fullName: projectData.data.contact.fullName,
					jobTitle: projectData.data.contact.jobTitle,
					email: projectData.data.contact.email,
					tel: projectData.data.contact.tel,
					projectPlan: projectData.data.documents.projectPlan,
					projectValidation: projectData.data.documents.projectValidation,
					verificationReport: projectData.data.documents.verificationReport,
					other1: projectData.data.documents.other1,
					other2: projectData.data.documents.other2,
					erLastUpdated: projectData.data.erLastUpdated,
					erRow: projectData.data.erRow,
					status: projectData.data.status
				});
			})
			.catch(err => console.log(err));
	}
	onFileRemove(name) {
		this.setState({ [name]: { url: "" } });
	}
	fileUpload(e) {
		// const file = e.target.files[0];
		// if (file) {
		// 	const name = e.target.name;
		// 	const fileType = e.target.getAttribute("file");

		// 	aws.config.update({
		// 		accessKeyId: process.env.ACCESS_KEY_ID || s3config.accessKeyId,
		// 		secretAccessKey: process.env.SECRET_ACCESS_KEY || s3config.secretAccessKey
		// 	});

		// 	var s3 = new aws.S3();

		// 	var params = {
		// 		Bucket: process.env.S3_BUCKET || s3config.bucket,
		// 		Key: `uploads/${fileType}/${file.name}`,
		// 		ContentType: file.type,
		// 		Body: file,
		// 		ACL: "public-read"
		// 	};

		// 	s3.upload(params, (err, res) => {
		// 		if (err) {
		// 			console.log(err);
		// 		} else {
		// 			this.setState({ [name]: { url: res.Location, fileName: file.name } });
		// 		}
		// 	});
		// }
	}
	timelineUpdated(e) {
		this.setState({ [e.target.name]: e.target.value }, () => {
			this.erRange(this.state.startYear);
		});
	}
	erRange(startYear) {
		const diff = moment().format("YYYY") - startYear;
		const range = [...Array(diff + 1)].map(i => ({
			year: startYear++
		}));

		let newRange = [...this.state.erRow];
		const diffRange = newRange.filter(a => !range.map(b => b.year).includes(a.year));

		if (diffRange.length > 0) {
			newRange = newRange.filter(function(obj) {
				return !this.has(obj.year);
			}, new Set(diffRange.map(obj => obj.year)));
		} else if (range.length > newRange.length) {
			range.map(a => {
				// TODO look into reducing
				if (!this.state.erRow.some(b => b.year === a.year)) {
					return newRange.push(a);
				} else {
					return newRange;
				}
			});
		}
		newRange.sort((a, b) => parseFloat(a.year) - parseFloat(b.year));

		this.setState({ erRow: newRange });
	}
	onErChange(object, year) {
		const curData = [...this.state.erRow];
		const doesExist = curData.some(obj => {
			return obj.year === year;
		});
		// TODO do it for the drop down options
		if (!doesExist) {
			curData.push(object);
			this.setState({ erRow: curData });
		} else {
			const index = curData.findIndex(obj => obj.year === year);
			const newData = { ...curData[index], ...object };
			curData[index] = newData;
			this.setState({ erRow: curData });
		}
	}
	onErUpdate(e) {
		this.setState({ erLastUpdated: e.target.value });
	}
	onChange(e) {
		this.setState({ [e.target.name]: e.target.value });
	}
	onButtonClick(type, data) {
		axios
			.post(`${API_ROOT}/admin/projects/action/${this.props.match.params.id}`, {
				type: type,
				data: data
			})
			.then(res => {
				alert(res.data.message);
				this.setState({ status: res.data.status });
			})
			.catch(err => console.log(err));
	}
	render() {
		const currentYear = moment().format("YYYY");
		const yearRange = [...Array(currentYear - 1950).keys()].map(i => ({
			label: currentYear - i,
			value: currentYear - i
		}));
		const monthRange = [
			{ label: "January", value: "January" },
			{ label: "February", value: "February" },
			{ label: "March", value: "March" },
			{ label: "April", value: "April" },
			{ label: "May", value: "May" },
			{ label: "June", value: "June" },
			{ label: "July", value: "July" },
			{ label: "August", value: "August" },
			{ label: "September", value: "September" },
			{ label: "October", value: "October" },
			{ label: "November", value: "November" },
			{ label: "December", value: "December" }
		];
		const programTypeOptions = [
			{ label: "BC GCC", value: "BC GCC" },
			{ label: "FCM", value: "FCM" },
			{ label: "Compact of Mayors", value: "Compact Of Mayors" },
			{ label: "Infrastructure Canada", value: "Infrastructure Canada" },
			{ label: "ISO 14064-2", value: "ISO 14064-2" }
		];
		const typeOptions = [
			{ label: "Acid Gas Injection", value: "Acid Gas Injection" },
			{ label: "Other", value: "Other" }
		];
		const statusOptions = [
			{ label: "Active", value: "Active" },
			{ label: "Inactive", value: "Inactive" },
			{ label: "Other", value: "Other" }
		];
		let status;

		if (this.state.status === 0) {
			status = "Pending";
		} else if (this.state.status === 1) {
			status = "Draft";
		} else if (this.state.status === 2) {
			status = "Approved";
		} else if (this.state.status === 3) {
			status = "Rejected";
		} else if (this.state.status === 4) {
			status = "Archived";
		}

		return (
			<div>
				<h2 className="m-3 p-3">
					Register your Project <span className="h6">{status}</span>
				</h2>
				<form>
					<div style={{ display: "flex" }}>
						<div style={{ width: "50%" }} className="p-3">
							<div className="border mb-3 p-3">
								<div style={{ display: "grid", gridTemplateColumns: "1fr" }}>
									<TextFieldGroup
										name="title"
										label="Project Title"
										onChange={this.onChange}
										value={this.state.title}
									/>
									<TextAreaFieldGroup
										name="description"
										label="Project Description"
										onChange={this.onChange}
										value={this.state.description}
									/>
								</div>
							</div>
							<div className="border mb-3 p-3">
								<p className="h5">Project Snapshot</p>
								<div
									style={{
										display: "grid",
										gridTemplateColumns: "1fr 1fr",
										gridColumnGap: "1rem"
									}}
								>
									<SelectListGroup
										name="programType"
										value={this.state.programType}
										label="ProgramType"
										options={programTypeOptions}
										onChange={this.onChange}
									/>
									<SelectListGroup
										name="projectType"
										value={this.state.projectType}
										label="Project Type"
										options={typeOptions}
										onChange={this.onChange}
									/>
									<SelectListGroup
										name="projectStatus"
										value={this.state.projectStatus}
										label="Project Status"
										options={statusOptions}
										onChange={this.onChange}
									/>
								</div>
							</div>
							<div className="border mb-3 p-3">
								<p className="h5 w-100">Project Timeline</p>
								<div
									style={{
										display: "grid",
										gridTemplateColumns: "1fr 1fr 0.25fr 1fr 1fr",
										gridColumnGap: "1rem"
									}}
								>
									<SelectListGroup
										name="startMonth"
										label="Start Month"
										options={monthRange}
										onChange={this.onChange}
										value={this.state.startMonth}
										style={{ container: { gridColumnStart: 1 } }}
									/>
									<SelectListGroup
										name="startYear"
										label="Start Year"
										options={yearRange}
										onChange={this.timelineUpdated}
										value={this.state.startYear}
										style={{ container: { gridColumnStart: 2 } }}
									/>
									<SelectListGroup
										name="finishMonth"
										label="Finish Month"
										options={monthRange}
										onChange={this.onChange}
										value={this.state.finishMonth}
										style={{ container: { gridColumnStart: 4 } }}
									/>
									<SelectListGroup
										name="finishYear"
										label="Finish Year"
										options={yearRange}
										onChange={this.onChange}
										value={this.state.finishYear}
										style={{ container: { gridColumnStart: 5 } }}
									/>
								</div>
							</div>
							<div className="border mb-3 p-3">
								<p className="h5">Location</p>
								<div
									style={{
										display: "grid",
										gridTemplateColumns: "1fr 1fr",
										gridColumnGap: "1rem"
									}}
								>
									<TextFieldGroup
										name="address"
										placeholder="Address"
										value={this.state.address}
										label="Address"
										onChange={this.onChange}
										autoComplete="street-address"
										style={{ gridColumnStart: "1", gridColumnEnd: "3" }}
									/>
									<TextFieldGroup
										name="latitude"
										placeholder="Latitude"
										value={this.state.latitude}
										label="Latitude"
										onChange={this.onChange}
									/>
									<TextFieldGroup
										name="longitude"
										placeholder="Longitude"
										value={this.state.longitude}
										label="Longitude"
										onChange={this.onChange}
									/>
								</div>
							</div>
							<div className="border mb-3 p-3">
								<p className="h5">Contact Person</p>
								<div
									style={{
										display: "grid",
										gridTemplateColumns: "1fr 1fr",
										gridColumnGap: "1rem"
									}}
								>
									<TextFieldGroup
										name="fullName"
										placeholder="Full Name"
										value={this.state.fullName}
										label="Full Name"
										onChange={this.onChange}
										autoComplete="name"
									/>
									<TextFieldGroup
										name="jobTitle"
										placeholder="Job Title"
										value={this.state.jobTitle}
										label="Job Title"
										onChange={this.onChange}
									/>
									<TextFieldGroup
										name="email"
										placeholder="Email Address"
										value={this.state.email}
										label="Email"
										onChange={this.onChange}
										autoComplete="email"
									/>
									<TextFieldGroup
										name="tel"
										placeholder="Tel"
										value={this.state.tel}
										label="Tel"
										onChange={this.onChange}
										autoComplete="tel"
									/>
								</div>
							</div>
						</div>
						<div style={{ width: "50%" }} className="p-3">
							<div className="mb-3">
								<p className="h5">Upload Featured Pictures</p>
								<div
									style={{
										display: "grid",
										gridTemplateColumns: "1fr 1fr 1fr",
										gridRowGap: "1rem"
									}}
								>
									<FileUploadFieldGroup
										label="Featured View"
										name="featured"
										onChange={this.fileUpload}
										file="images"
										imageUrl={
											this.state.featured ? this.state.featured.url : ""
										}
										fileName={
											this.state.featured ? this.state.featured.fileName : ""
										}
									/>
									<FileUploadFieldGroup
										label="Project Site"
										name="projectSite"
										onChange={this.fileUpload}
										file="images"
										imageUrl={
											this.state.projectSite ? this.state.projectSite.url : ""
										}
										fileName={
											this.state.projectSite
												? this.state.projectSite.fileName
												: ""
										}
									/>
									<FileUploadFieldGroup
										label="Situation Before"
										name="before"
										onChange={this.fileUpload}
										file="images"
										imageUrl={this.state.before ? this.state.before.url : ""}
										fileName={
											this.state.before ? this.state.before.fileName : ""
										}
									/>
									<FileUploadFieldGroup
										label="Project Activity"
										name="activity"
										onChange={this.fileUpload}
										file="images"
										imageUrl={
											this.state.activity ? this.state.activity.url : ""
										}
										fileName={
											this.state.activity ? this.state.activity.fileName : ""
										}
									/>
									<FileUploadFieldGroup
										label="Other"
										name="otherImage"
										onChange={this.fileUpload}
										file="images"
										imageUrl={
											this.stateotherImage ? this.state.otherImage.url : ""
										}
										fileName={
											this.stateotherImage
												? this.state.otherImage.fileName
												: ""
										}
									/>
								</div>
							</div>
							<div>
								<p className="h5 mb-3">Project Documents</p>
								<div style={{ display: "grid", gridTemplateColumns: "1fr 1.25fr" }}>
									<p className="h6">Title</p>
									<p className="h6">File</p>
								</div>
								<div>
									<FileUploadFieldGroup
										label="Project Plan"
										name="projectPlan"
										onChange={this.fileUpload}
										file="documents"
										fileName={
											this.state.projectPlan
												? this.state.projectPlan.fileName
												: ""
										}
										remove={this.onFileRemove}
									/>
									<FileUploadFieldGroup
										label="Project Validation"
										name="projectValidation"
										onChange={this.fileUpload}
										file="documents"
										fileName={
											this.state.projectValidation
												? this.state.projectValidation.fileName
												: ""
										}
										remove={this.onFileRemove}
									/>
									<FileUploadFieldGroup
										label="Project Verification Report"
										name="verificationReport"
										onChange={this.fileUpload}
										file="documents"
										fileName={
											this.state.verificationReport
												? this.state.verificationReport.fileName
												: ""
										}
										remove={this.onFileRemove}
									/>
									<FileUploadFieldGroup
										label="Other"
										name="other1"
										onChange={this.fileUpload}
										file="documents"
										fileName={
											this.state.other1 ? this.state.other1.fileName : ""
										}
										remove={this.onFileRemove}
									/>
									<FileUploadFieldGroup
										label="Other"
										name="other2"
										onChange={this.fileUpload}
										file="documents"
										fileName={
											this.state.other2 ? this.state.other2.fileName : ""
										}
										remove={this.onFileRemove}
									/>
								</div>
							</div>
						</div>
					</div>
					<div className="d-flex">
						<div className="p-3" style={{ width: "60%" }}>
							{this.state.erRow ? (
								this.state.erRow.length >= 1 ? (
									<EmissionReduction
										// dateRange={erDateRange}
										onErChange={this.onErChange}
										erRow={this.state.erRow}
										erUpdate={this.onErUpdate}
										value={this.state.erLastUpdated}
									/>
								) : null
							) : null}
						</div>
						<div className="p-3" style={{ width: "40%" }}>
							<div className="mb-3">
								<p className="m-0">Legend</p>
								<p className="m-0">Registered</p>
								<p className="m-0">Retired</p>
								<p className="m-0">Cancelled</p>
							</div>
							<div className="mb-3">check boxes</div>
							<div className="mb-3">
								{(this.state.status !== 2 && this.state.status !== 1) ||
								this.state.status === 4 ? (
									<p
										style={{
											padding: "10px",
											border: "1px solid #000",
											width: "150px",
											textAlign: "center",
											background: "grey",
											color: "#fff",
											marginLeft: "auto"
										}}
										onClick={() => this.onButtonClick("approve", this.state)}
									>
										Approve
									</p>
								) : null}
								{this.state.status === 0 ? (
									<p
										style={{
											padding: "10px",
											border: "1px solid #000",
											width: "150px",
											textAlign: "center",
											background: "grey",
											color: "#fff",
											marginLeft: "auto"
										}}
										onClick={() => this.onButtonClick("reject", this.state)}
									>
										Reject
									</p>
								) : null}
								{this.state.status === 2 ? (
									<p
										style={{
											padding: "10px",
											border: "1px solid #000",
											width: "150px",
											textAlign: "center",
											background: "grey",
											color: "#fff",
											marginLeft: "auto"
										}}
										onClick={() => this.onButtonClick("cancel", this.state)}
									>
										Cancel Project
									</p>
								) : null}
								{this.state.status !== 4 &&
								this.state.status !== 1 &&
								this.state.status !== 2 ? (
									<p
										style={{
											padding: "10px",
											border: "1px solid #000",
											width: "150px",
											textAlign: "center",
											marginLeft: "auto"
										}}
										onClick={() => this.onButtonClick("archive", this.state)}
									>
										Archive
									</p>
								) : null}
								<p
									style={{
										padding: "10px",
										border: "1px solid #000",
										width: "150px",
										textAlign: "center",
										marginLeft: "auto"
									}}
									onClick={() => this.onButtonClick("save", this.state)}
								>
									Save
								</p>
							</div>
						</div>
					</div>
				</form>
			</div>
		);
	}
}

export default Project;
