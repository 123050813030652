import {
	PROJECT_LOADING,
	GET_PROJECTS,
	GET_USER_PROJECTS,
	GET_PROJECT,
	GET_FEATURED_PROJECTS,
	GET_PUBLIC_PROJECTS,
	GET_RANGE,
	GET_TOKENS,
	GET_DRAFT,
	PROJECT_TOKENS_LOADING,
	PROJECT_CLEAR,
} from "../actions/types";

const initialState = {
	erDateRange: [],
	project: {},
	projects: null,
	projectTokens: null,
	inventoryTokens: null,
	micrositeTokens: null,
	loading: false
};

export default function(state = initialState, action) {
	switch (action.type) {
		case GET_USER_PROJECTS:
			return {
				...state,
				projects: action.payload,
				loading: false
			};
		case PROJECT_LOADING:
			return {
				...state,
				loading: true
			};
		case PROJECT_TOKENS_LOADING:
			return {
				...state,
				tokens_loading: true
			};
		case GET_FEATURED_PROJECTS:
			return {
				...state,
				featuredProjects: action.payload,
				loading: false
			};
		case GET_PROJECTS:
			return {
				...state,
				projects: action.payload,
				loading: false
			};
		case GET_PUBLIC_PROJECTS:
			return {
				...state,
				publicProjects: action.payload,
				loading: false
			};
		case GET_PROJECT:
			return {
				...state,
				project: action.payload,
				loading: false
			};
		case GET_RANGE:
			return {
				...state,
				erDateRange: action.payload
			};
		case GET_TOKENS:
			return {
				...state,
				projectTokens: action.payload.projectTokens,
				inventoryTokens: action.payload.inventoryTokens,
				micrositeTokens: action.payload.micrositeTokens,
				tokens_loading: false
			};
		case GET_DRAFT:
			return {
				...state,
				project: action.payload,
				loading: false
			};
		case PROJECT_CLEAR:
			return {};
		default:
			return state;
	}
}
