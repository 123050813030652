import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";

import {
	AreaChart,
	Area,
	XAxis,
	YAxis,
	CartesianGrid,
	Tooltip,
	ResponsiveContainer,
	Legend,
	Label,
} from "recharts";
import { SmoothStepFunc } from "../../lib/Helpers";
import { addExtraYear } from "../../lib/Emissions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "@fortawesome/free-solid-svg-icons";

const payload = [
	{
		inactive: false,
		dataKey: "inventory",
		type: "circle",
		color: "#a01c4a",
		value: "GHG Inventory",
	},
	{
		inactive: false,
		dataKey: "projectedInventory",
		type: "circle",
		color: "#D7BDC6",
		value: "Projected GHG Inventory",
	},
	{
		inactive: false,
		dataKey: "offsets",
		type: "circle",
		color: "#5e9841",
		value: "Offset GHG Inventory",
	},
	{
		inactive: false,
		dataKey: "remaining",
		type: "circle",
		color: "#B5E2B5", //D4E7D4
		value: "Available ERs/Offsets",
	},
];

const CustomTooltip = ({ active, payload, label }) => {
	const colors = ["#a01c4a", "#D7BDC6", "#5e9841", "#B5E2B5"];
	if (active) {
		return (
			<div
				className="recharts-default-tooltip"
				style={{
					margin: 0,
					padding: 10,
					backgroundColor: "rgb(255, 255, 255)",
					border: "1 solid rgb(204, 204, 204)",
					borderColor: "rgb(204, 204, 204)",
					borderStyle: "solid",
					borderWidth: "1px",
					whiteSpace: "nowrap",
				}}
				//"margin: 0px; padding: 10px; background-color: rgb(255, 255, 255); border: 1px solid rgb(204, 204, 204); white-space: nowrap;"
			>
				<p className="label mb-1">{`Year: ${label}`}</p>
				{payload?.map((x, i) => (
					<Fragment>
						<p className="desc mb-1">
							<FontAwesomeIcon style={{ color: colors[i] }} icon={faCircle} />{" "}
							{x.name}: {x.value}
						</p>
					</Fragment>
				))}
				{/* <p className="intro">{label)}</p> */}
			</div>
		);
	}

	return null;
};

class InventoryGraph extends Component {
	render() {
		const emissionArray = addExtraYear(this.props.emissionArray);
		const type = SmoothStepFunc;

		return (
			<div style={{ width: "100%", height: this.props.height }}>
				<ResponsiveContainer>
					<AreaChart
						data={emissionArray}
						margin={{
							top: 10,
							right: 30,
							left: 0,
							bottom: 0,
						}}
					>
						<CartesianGrid strokeDasharray="3 3" />
						<XAxis dataKey="year" />
						<YAxis>
							<Label
								value="Tonnes CO₂e"
								position="right"
								offset={-50}
								// position="top"
								// offset={-120}
								angle={270}
							/>
						</YAxis>
						<Tooltip content={<CustomTooltip />} />
						{/* <Legend /> */}
						<Legend payload={payload} />

						{/* <Area
							name="ERs/Offsets"
							type={type}
							dataKey="offsets"
							stackId="1"
							stroke="#82ca9d"
							fill="#82ca9d"
						/>
						<Area
							name="GHG Inventory"
							type={type}
							dataKey="unretiredInventory"
							stackId="1"
							stroke="#f94040"
							fill="#ff8484"
							//type='linear'
						/>

						<Area
							name="Projected GHG Inventory"
							type={type}
							dataKey="projectedInventory"
							stackId="1"
							stroke="#8884d8"
							fill="#8884d8"
							fillOpacity="0.1"
							strokeDasharray="8 8"
						/>

						<Area
							name="Available ERs/Offsets"
							type={type}
							dataKey="remaining"
							stackId="3"
							fill="lightgreen"
							stroke="green"
							fillOpacity="0.1"
							strokeDasharray="8 8"
						/> */}

						<Area
							name="GHG Inventory"
							type={type}
							//dataKey="unretiredInventory"
							dataKey="inventory"
							stackId="1"
							stroke="#a01c4a"
							strokeWidth="1.5"
							fill="#a01c4a"
							//type='linear'
						/>

						<Area
							name="Projected GHG Inventory"
							type={type}
							dataKey="projectedInventory"
							stackId="1"
							stroke="#a01c4a"
							fill="#a01c4a"
							fillOpacity="0.2"
							strokeDasharray="8 8"
						/>
						<Area
							//name="Compensated GHG Inventory"
							name="Compensated GHG Inventory"
							type={type}
							dataKey="offsets"
							stackId="2"
							stroke="#5e9841"
							fill="#94B783"
							// stroke="#FBC12F"
							// fill="#F3D078"
							strokeWidth="1.5"
							fillOpacity="1"
						/>

						<Area
							name="Available ERs/Offsets"
							type={type}
							dataKey="remaining"
							stackId="2"
							// fill="#5d6f5f"
							// stroke="#5d6f5f"

							fill="lightgreen"
							stroke="#5e9841"
							fillOpacity="0.2"
							//strokeDasharray="8 8"
						/>
					</AreaChart>
				</ResponsiveContainer>
			</div>
		);
	}
}
InventoryGraph.propTypes = {
	height: PropTypes.string.isRequired,
	emissionArray: PropTypes.array.isRequired,
};

export { InventoryGraph };
