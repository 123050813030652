import React from "react";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import Proptypes from "prop-types";

const UserRoute = ({ component: Component, auth, extraProps, ...rest }) => (
	<Route
		{...rest}
		render={props =>
			auth.isAuthenticated === true ? (
				<Component {...props} {...extraProps} />
			) : (
				<Redirect to="/login" />
			)
		}
	/>
);

UserRoute.propTypes = {
	auth: Proptypes.object.isRequired
};

const mapStateToProps = state => ({
	auth: state.auth
});

export default connect(mapStateToProps)(UserRoute);
