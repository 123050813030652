import React, { Component } from "react";
import { Link } from "react-router-dom";

class ProjectItem extends Component {
	render() {
		const { project } = this.props;
		return (
			<Link to={`/projects/${project.projectSerial}`}>
				<div
					style={{ width: "100%", paddingBottom: "75%", background: "gray", margin:5 }}
				>
					<div
						style={{
							position: "absolute",
							top: 5,
							bottom: 5,
							left: 5,
							right: 5,
							overflow: "hidden",
							background: "gray"
						}}
					>
						<img
							className="d-block"
							src={project.pictures.featured.url && project.pictures.featured.url+".300.jpg"}
							alt={project.title}
							style={{
								margin: "auto",
								// marginLeft: "auto",
								// marginRight: "auto",
								//maxHeight: "100%",
								width: "100%"
							}}
						/>
						<div style={{ position: "absolute", bottom: 0, left: 0, right: 0 }}>
							<div
								//captionText={item.caption}
								//captionHeader=
								style={{
									background: "rgba(65,117,5,.7)",
									color: "white",
									textAlign: "center"
								}}
								className="custom-tag d-block"
							>
								<h3>{project.title}</h3>
							</div>
						</div>
					</div>
				</div>
			</Link>

			// <div className="container-fluid" style={style}>
			// 	{project.pictures.featured.url ? (
			// 		<div>
			// 			<div className="imgContainer2" /*style={style.img}*/>
			// 				<img src={project.pictures.featured.url} alt="" />
			// 			</div>
			// 		</div>
			// 	) : null}
			// 	<div className="projectMeta pt-3 pb-3">
			// 		{project.status === 1 ? (
			// 			<Link to={`/profile/drafts/${project._id}`}>Edit Draft</Link>
			// 		) : (
			// 			<Link to={`/projects/${project._id}`}>{project.title}</Link>
			// 		)}
			// 	</div>
			// </div>
		);
	}
}
export default ProjectItem;
