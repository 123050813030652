import axios from "axios";

import { GET_PROFILE, PROFILE_LOADING, CLEAR_CURRENT_PROFILE, GET_ERRORS } from "./types";
import { USER_HOME_PATH, CORE_API_ROOT } from "../config/api-config";
import { redirect } from "./authActions";

// Update or save new Profile data // ✅
export const updateProfile = (profileData, history) => dispatch => {
	axios
		.put(`${CORE_API_ROOT}/users/current`, profileData)
		.then(res => {
			// update the profile in the state before going back.
			dispatch(clearCurrentProfile());

			// dispatch({
			// 	type: GET_PROFILE,
			// 	payload: res.data
			// });

			dispatch(redirect(USER_HOME_PATH, history));
		})
		.catch(err => {
			dispatch({
				type: GET_ERRORS,
				payload: err.response.data
			});
		});
};
// Get current profile // ✅
export const getCurrentProfile = () => dispatch => {
	dispatch(setProfileLoading());
	axios
		.get(`${CORE_API_ROOT}/users/current`)
		.then(res =>
			dispatch({
				type: GET_PROFILE,
				payload: res.data
			})
		)
		.catch(err =>
			dispatch({
				type: GET_PROFILE,
				payload: null
			})
		);
};

// Profile Loading
export const setProfileLoading = () => {
	return {
		type: PROFILE_LOADING
	};
};

// Clear Profile
export const clearCurrentProfile = () => {
	return {
		type: CLEAR_CURRENT_PROFILE
	};
};
