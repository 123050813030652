import React, { Component } from "react";
import axios from "axios";
import { CORE_API_ROOT } from "../../config/api-config";
import ContentBox from "../../components/common/ContentBox";
import { Row, Col, Button } from "reactstrap";
import TextFieldGroup from "../../components/common/TextFieldGroup";

class ForgotPassword extends Component {
	constructor() {
		super();
		this.state = {
			email: ""
		};

		this.handleSubmit = this.handleSubmit.bind(this);
		this.handleChange = this.handleChange.bind(this);
	}

	handleSubmit(e) {
		e.preventDefault();
		var email = this.state.email;

		var bodyFormData = new FormData();
		bodyFormData.set("email", email);

		axios({
			method: "POST",
			url: `${CORE_API_ROOT}/users/forgotPassword`,
			data: {
				email: email
			}
		})
			.then(res => {
				console.log(res);
				alert("A request to reset your password was sent to your e-mail address");
				this.setState({ email: "" });
			})
			.catch(err => {
				console.log(err);
				alert("Error");
			});
	}
	handleChange(e) {
		this.setState({ [e.target.name]: e.target.value });
	}
	render() {
		return (
			<div>
				<ContentBox
					width="6"
					title="Reset Your Password"
					subtitle="We will send you an email to reset your password."
				>
					<form onSubmit={this.handleSubmit}>
						<TextFieldGroup
							label="Email"
							placeholder=""
							name="email"
							type="email"
							value={this.state.email}
							onChange={this.handleChange}
						/>
						<Row>
							<Col xs="6">
								<Button
									color="primary"
									className="px-4"
									type="submit"
									name="submit"
									id="forgotPasswordClickEvt"
									value="Send"
								>
									Send
								</Button>
							</Col>
						</Row>
					</form>
				</ContentBox>
			</div>
		);
	}
}
export default ForgotPassword;
