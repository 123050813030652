import axios from "axios";
import { GET_SETTINGS_PRICING, GET_SETTINGS_APIKEYS } from "./types";
import { CORE_API_ROOT } from '../config/api-config';

export const getSettingsPricing = () => dispatch => {
	axios
		.get(`${CORE_API_ROOT}/settings/pricing`)
		.then(res => {
			dispatch({
				type: GET_SETTINGS_PRICING,
				payload: res.data
			});
		})
		.catch(err =>
			dispatch({
				type: GET_SETTINGS_PRICING,
				payload: null
			})
		);
};

export const getSettingsApiKeys = () => dispatch => {
	axios
		.get(`${CORE_API_ROOT}/apikey`)
		.then(res => {
			dispatch({
				type: GET_SETTINGS_APIKEYS,
				payload: res.data
			});
		})
		.catch(err =>
			dispatch({
				type: GET_SETTINGS_APIKEYS,
				payload: null
			})
		);
};
