import { GET_SETTINGS_PRICING, GET_SETTINGS_APIKEYS } from "../actions/types";

const initialState = {
	pricing: null,
	apiKeys: null
};

export default function(state = initialState, action) {
	switch (action.type) {
		case GET_SETTINGS_PRICING:
			return {
				...state,
				pricing: action.payload
			};
		case GET_SETTINGS_APIKEYS:
			return {
				...state,
				apiKeys: action.payload
			};
		default:
			return state;
	}
}
