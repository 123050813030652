import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";

import { getDrafts } from "../../actions/draftActions";
import Spinner from "../../components/common/Spinner";
import ProjectItem from "../../components/projects/ProjectItem";

class ProjectDrafts extends Component {
	componentDidMount() {
		this.props.getDrafts(this.props.id);
	}

	render() {
		const { drafts, loading } = this.props.drafts;
		let draftItems;

		if (drafts === null || loading) {
			draftItems = <Spinner />;
		} else {
			if (drafts.length > 0) {
				draftItems = drafts.map(draft => (
					<ProjectItem
						key={draft._id}
						project={draft}
						style={{
							width: "49%",
							margin: "0 0 30px 0",
							img: { height: "350px" }
						}}
					/>
				));
			} else {
				draftItems = <h4>No Drafts found...</h4>;
			}
		}

		return (
			<div className="drafts">
				<div className="container">
					<h1>Drafts</h1>
					{draftItems}
				</div>
			</div>
		);
	}
}

ProjectDrafts.propTypes = {
	getDrafts: PropTypes.func.isRequired,
	drafts: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
	drafts: state.drafts,
	id: state.auth.user.id
});

export default connect(
	mapStateToProps,
	{ getDrafts }
)(withRouter(ProjectDrafts));
