import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

// import Spinner from "../common/Spinner";
import Spinner from "../../components/common/Spinner";
import { getUserProjects, getUserProjectTokens } from "../../actions/projectActions";
import { getCurrentProfile } from "../../actions/profileActions";
import { clearErrors } from "../../actions/errorActions";
import { redirect } from "../../actions/authActions";
import ContentBox from "../../components/common/ContentBox";
import { Table, Input } from "reactstrap";
import { Link } from "react-router-dom";
import { getEmissions } from "../../lib/Emissions";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";

import { getPublicProjects } from "../../actions/projectActions";
import { InventoryGraph } from "./InventoryGraph";
import { ShareButton } from "../../components/common/ShareButton";

function getOkSymbol() {
	return <FontAwesomeIcon style={{ color: "limegreen" }} icon={faCheck} />;
}

function getNOkSymbol() {
	return <FontAwesomeIcon style={{ color: "tomato" }} icon={faTimes} />;
}

class Overview extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showProjects: true,
		};
	}

	componentDidMount() {
		this.props.getCurrentProfile(this.props.user.id);
		this.props.getUserProjects(this.props.user.certserialnumber);
		this.props.getUserProjectTokens();
		this.props.clearErrors();
		this.props.getPublicProjects();

		this.setState({
			loading: false,
		});
	}
	componentWillUnmount() { }

	componentDidUpdate() {
		// if (!this.isLoaded()) {
		// 	return;
		// }
		// if (
		// 	!this.props.profile.profile.contactName ||
		// 	!this.props.profile.profile.mailingAddress ||
		// 	!this.props.profile.profile.contactTel
		// ) {
		// 	this.props.redirect("/profile/edit", this.props.history);
		// 	return;
		// }
		// if (this.props.userProjects.projects.length <= 0) {
		// 	// No projects yet, guide the user to creating one
		// 	this.props.redirect("/create-project", this.props.history);
		// 	return;
		// }
	}

	isLoadedPublic() {
		const { publicProjects } = this.props.publicProjects;

		if (publicProjects == null || publicProjects.loading) {
			return false;
		} else {
			return true;
		}
	}

	isLoaded() {
		const { loading } = this.state;
		const { projects } = this.props.userProjects;
		const { profile } = this.props.profile;
		const { publicProjects } = this.props.publicProjects;

		if (
			loading ||
			profile == null ||
			profile.loading ||
			projects == null ||
			projects.loading ||
			projects.tokens_loading ||
			publicProjects == null ||
			publicProjects.loading
		) {
			return false;
		} else {
			return true;
		}
	}

	renderProjectLine(year, projectSerial, projects, e2, showPublic, projectYear) {
		const project = projects.find((p) => p.projectSerial === projectSerial);
		return (
			<tr
				key={year + projectSerial}
				style={this.state.showProjects ? { backgroundColor: "white" } : {}}
			>
				<td className="pl-4">
					<Link
						to={
							showPublic
								? `/projects/${projectSerial}`
								: `/profile/drafts/${projectSerial}`
						}
					>
						{project.offsetProvider && project.offsetProvider + " - "}
						{project.title}
					</Link>
				</td>
				<td>{projectYear}</td>
				<td className="" /> {/* {e2[year].projects[projectSerial].inventory} */}
				<td className="">{e2[year].projects[projectSerial].offsetsPerYear[projectYear]}</td>
				<td className="" />
			</tr>
		);
	}

	render() {
		const showPublic = this.props.match.path === "/inventory/overview/:id";

		let projects;
		let loaded;
		if (showPublic) {
			projects = this.props.publicProjects.publicProjects
				? this.props.publicProjects.publicProjects.filter(
					(x) => x.projectSerial.split("-")[0] === this.props.match.params.id
				)
				: undefined;


			var transferred = this.props.publicProjects.publicProjects?.filter(x => x.emissionReductions.registered.some(y => y.transferredTo === this.props.match.params.id)) // 

			transferred = transferred?.map(x => ({
				...x,
				emissionReductions: {
					registered: x.emissionReductions.registered.filter(y => y.transferredTo === this.props.match.params.id)
						.map(y => ({ ...y, status: y.inventoryProject ? "RetiredInventory" : "Registered" })),
					draft: []
				}
				//...x
			}))
			//console.log(transferred);
			projects = projects?.concat(transferred)

			loaded = this.isLoadedPublic();
		} else {
			projects = this.props.userProjects.projects;
			loaded = this.isLoaded();
		}

		//const { projects } = this.props.userProjects;

		if (!loaded || !projects) {
			return <Spinner />;
		}

		const e2 = getEmissions(projects);

		const emissionArray = Object.keys(e2).map((v) => e2[v]);
		const currentYear = new Date().getFullYear().toString();

		function getStyle(year) {
			return {
				fontWeight: e2[year].projectedInventory > 0 ? "" : "bold",
			};
		}

		function getNeutralityStatus(year) {
			return e2[year].inventory - e2[year].offsets <= 0 ? (
				<React.Fragment>
					{getOkSymbol()} Achieved
					{/* {e2[year].inventory - e2[year].offsets < 0 &&
						` (+${e2[year].offsets - e2[year].inventory})`} */}
				</React.Fragment>
			) : (
				<React.Fragment>
					{getNOkSymbol()} Not Achieved ({e2[year].offsets - e2[year].inventory})
				</React.Fragment>
			);
		}

		const logo =
			(projects.length > 0 && projects[0].organizationLogo) ||
			(this.props.profile && this.props.profile.profile && this.props.profile.profile.logo);

		console.log(logo);

		const orgName =
			(projects.length > 0 && projects[0].organization) ||
			(this.props.profile &&
				this.props.profile.profile &&
				this.props.profile.profile.organization);

		return (
			<ContentBox
				title={
					<div>
						<p className="mb-0">
							{logo && (
								<img
									src={logo}
									alt=""
									style={{ maxHeight: 80, maxWidth: 150, borderRadius: "5%" }}
									className="mr-2"
								/>
							)}
							GHG True-up Overview{" "}
							{showPublic || (
								<ShareButton
									url={window.location + this.props.user.certserialnumber}
								/>
							)}
						</p>
					</div>
				}
				subtitle={
					<React.Fragment>
						{" "}
						This page provides an overview of the GHG inventory that {orgName} has added
						to the registry. The inventory may be compensated by emission reduction
						(ERs) projects that have been added to the registry or by registered offsets
						purchased from a third-party.
					</React.Fragment>
				}
				width="12"
			>
				<InventoryGraph height="300px" emissionArray={emissionArray} />

				<div className="mt-5 ml-4">
					<Input
						className="pl-3"
						type="checkbox"
						checked={this.state.showProjects}
						onChange={(e) => {
							this.setState({ showProjects: e.target.checked });
						}}
					/>{" "}
					Show Project Breakdown
				</div>

				<Table striped size="sm" borderless className="mt-4">
					<thead>
						<tr>
							<th>Calendar Year</th>
							{this.state.showProjects && <th>Vintage</th>}
							<th>Inventory</th>
							<th>ERs/Offsets</th>
							<th>Carbon Neutrality Status</th>
						</tr>
					</thead>
					<tbody>
						{Object.keys(e2)
							.reverse()
							.filter(
								(year) =>
									e2[year].inventory !== 0 ||
									e2[year].projectedInventory !== 0 ||
									e2[year].offsets !== 0
							)
							.map((year, idx) => (
								<React.Fragment>
									<tr
										key={year}
										style={
											this.state.showProjects
												? currentYear === year
													? { backgroundColor: "#bad5ff" }
													: { backgroundColor: "#F2F2F2" }
												: {}
										}
									>
										<td style={getStyle(year)}>
											{year}
											{e2[year].projectedInventory > 0 && " (Projected)"}
										</td>
										{this.state.showProjects && <td />}
										<td style={getStyle(year)}>
											{e2[year].inventory + e2[year].projectedInventory}
										</td>
										<td style={getStyle(year)}>{e2[year].offsets}</td>
										<td style={getStyle(year)}>
											{e2[year].projectedInventory <= 0 &&
												getNeutralityStatus(year)}
											{e2[year].projectedInventory > 0 &&
												(Object.keys(e2)
													.map((x) =>
														x <= year
															? e2[x].remaining -
															e2[x].projectedInventory
															: 0
													)
													.reduce((a, b) => a + b) < 0 ? (
													<React.Fragment>
														Projected Not Achieved (YTD{" "}
														{Object.keys(e2)
															.map((x) =>
																x <= year
																	? e2[x].remaining -
																	e2[x].projectedInventory
																	: 0
															)
															.reduce((a, b) => a + b)}
														)
													</React.Fragment>
												) : (
													"Projected Achieved (YTD)"
												))}
										</td>
									</tr>

									{this.state.showProjects &&
										Object.keys(e2[year].projects)
											.filter(
												(projectSerial) =>
													projects.find(
														(p) => p.projectSerial === projectSerial
													)?.programType !== "Inventory"
											)
											.map((projectSerial) =>
												Object.keys(
													e2[year].projects[projectSerial].offsetsPerYear
												).map((projectYear) =>
													this.renderProjectLine(
														year,
														projectSerial,
														projects,
														e2,
														showPublic,
														projectYear
													)
												)
											)}
								</React.Fragment>
							))}
					</tbody>
				</Table>
			</ContentBox>
		);
	}
}
Overview.propTypes = {
	getCurrentProfile: PropTypes.func.isRequired,
	getUserProjects: PropTypes.func.isRequired,
	user: PropTypes.object.isRequired,
	userProjects: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
	profile: state.profile,
	user: state.auth.user,
	userProjects: state.project,
	userTokens: state.project.projectTokens,
	settings: state.settings,
	publicProjects: state.project,
});
export default connect(mapStateToProps, {
	getUserProjects,
	getCurrentProfile,
	getUserProjectTokens,
	redirect,
	clearErrors,
	getPublicProjects,
})(Overview);
