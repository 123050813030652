import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";

import { Input } from 'reactstrap';

const DatePickerGroup = ({ name, label, value, error, info, onChange, style }) => {
	
	return (
		<div className="form-group" style={style ? style.container : null}>
			{label ? (
				<label className="pl-2"
					htmlFor={name}					
				>
					{label}
				</label>
			) : null}
			<Input
				type="date"
				id={name}
				className={classnames("form-control", {
					"is-invalid": error
				})}
				name={name}
				onChange={onChange}
				value={value || ""}
			>				
			</Input>
			{info && <small className="form-text text-muted">{info}</small>}
			{error && <div className="invalid-feedback" id={name+"_error"}>{error}</div>}
		</div>
	);
};

DatePickerGroup.propTypes = {
	name: PropTypes.string.isRequired,
	info: PropTypes.string,
	error: PropTypes.string,
	onChange: PropTypes.func.isRequired,
};

export default DatePickerGroup;
