import React, { Component } from "react";

import Spinner from "../../components/common/Spinner";
import qs from "querystring";
import Axios from "axios";
import { CORE_API_ROOT } from "../../config/api-config";

class TestEmail extends Component {
	constructor(props) {
		super(props);
		this.state = {
			data: null,
			error: null
		};
	}

	getData() {
		const params = qs.parse(this.props.location.search.slice(1)); //{ ignoreQueryPrefix: true }

		Axios.get(`${CORE_API_ROOT}/test/dev/email?name=${params.name}`)
			.then(res => {
				this.setState({ data: res.data });
			})
			.catch(err => {
				throw new Error("Email Error");
			});
	}

	componentDidMount() {
		this.getData();
	}

	render() {
		if (!this.state.data) {
			return <Spinner />;
		}

		return <div id="content" dangerouslySetInnerHTML={{ __html: this.state.data }}></div>;
	}
}

export default TestEmail;
