import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import axios from "axios";

import {
	getRequests,
	getUsers,
	getUserData,
	getAllProjects,
	clearUserData,
	getProjects
} from "../../actions/adminActions";
import { API_ROOT } from "../../config/api-config";
import {Row, Col} from "reactstrap"

import "./Admin.css";
import AdminRequests from "./AdminRequests";
import AdminProjects from "./AdminProjects";

class Admin extends Component {
	constructor(props) {
		super(props);
		this.state = {
			tab: 1
		};

		this.handleTabClick = this.handleTabClick.bind(this);
		this.handleApproveClick = this.handleApproveClick.bind(this);
		this.handleRejectClick = this.handleRejectClick.bind(this);
		this.handleAccountClick = this.handleAccountClick.bind(this);
		this.handleResetPassword = this.handleResetPassword.bind(this);
		this.handleActivateAccount = this.handleActivateAccount.bind(this);
		this.handleDisableAccount = this.handleDisableAccount.bind(this);
		this.handleProjectTabClick = this.handleProjectTabClick.bind(this);
	}
	componentDidMount() {
		this.props.getRequests();
		this.props.getUsers();
		this.props.getAllProjects();
		//window.scrollTo(0, 0);
	}
	handleApproveClick(id) {
		axios({
			method: "POST",
			url: `${API_ROOT}/admin/requests/approve`,
			data: {
				id: id
			}
		}).then(res => {
			// TODO create dispatch on backend instead
			this.props.getRequests();
		});
	}
	handleRejectClick(id) {
		axios({
			method: "POST",
			url: `${API_ROOT}/admin/requests/reject`,
			data: {
				id: id
			}
		}).then(res => {
			// TODO create dispatch on backend instead
			this.props.getRequests();
		});
	}
	handleActivateAccount(id) {
		axios({
			method: "POST",
			url: `${API_ROOT}/admin/users/activate`,
			data: {
				id: id
			}
		}).then(res => {
			this.props.getUserData(res.data);
		});
	}
	handleDisableAccount(id) {
		axios({
			method: "POST",
			url: `${API_ROOT}/admin/users/disable`,
			data: {
				id: id
			}
		}).then(res => {
			this.props.getUserData(res.data);
		});
	}
	handleAccountClick(id) {
		this.props.getUserData(id);
	}
	handleTabClick(tab) {
		if (tab === 2) {
			this.props.clearUserData();
		}
		this.setState({ tab: tab });
	}
	handleProjectTabClick(projectStatus) {
		this.props.getProjects(projectStatus);
	}
	handleResetPassword(email) {
		axios({
			method: "POST",
			url: `${API_ROOT}/users/forgot`,
			data: {
				email: email
			}
		})
			.then(res => {
				console.log(res);
				alert("successfully sent");
			})
			.catch(err => {
				console.log(err);
				alert("Error");
			});
	}
	render() {
		return (
			// <div>
			// 	{true && admin.usersLoading || admin.requestsLoading || admin.projectsLoading ? (
			// 		<Spinner />
			// 	) : (
					<Row>
				 			<Col lg="6" ><AdminRequests/></Col>
				 			<Col lg="6" ><AdminProjects statusFilter="Submitted" showHistory={false}/></Col>
						</Row>
				// 	<div className="wrapper">
				// 		<Row>
				// 			<Col md="6" >test</Col>
				// 			<Col md="6" >test</Col>
				// 		</Row>

				// 		<Tabs tab={this.state.tab} handleTabClick={this.handleTabClick} />
				// 		{this.state.tab === 1 && requests ? (
				// 			<RequestList
				// 				requests={requests}
				// 				handleApproveClick={this.handleApproveClick}
				// 				handleRejectClick={this.handleRejectClick}
				// 			/>
				// 		) : null}
				// 		{this.state.tab === 2 ? (
				// 			<div className="listColumnsUser">
				// 				<UserList users={users} accountClick={this.handleAccountClick} />
				// 				{userData ? (
				// 					<UserDetail
				// 						logo={
				// 							userData.accountHolder
				// 								? userData.accountHolder.logo
				// 								: null
				// 						}
				// 						org={userData.organization}
				// 						isActive={userData.isActive}
				// 						contactName={
				// 							userData.contactName ? userData.contactName : ""
				// 						}
				// 						contactTel={userData.contactTel ? userData.contactTel : ""}
				// 						projects={userData.projects}
				// 						balance={userData.projectTokens}
				// 						email={userData.email}
				// 						handleResetPassword={this.handleResetPassword}
				// 						handleDisableAccount={this.handleDisableAccount}
				// 						handleActivateAccount={this.handleActivateAccount}
				// 						userId={userData._id}
				// 						getUserData={this.props.getUserData}
				// 						loading={this.props.admin.userDataLoading}
				// 					/>
				// 				) : null}
				// 			</div>
				// 		) : null}
				// 		{this.state.tab === 3 ? (
				// 			<ProjectList
				// 				projects={projects}
				// 				projectOccurrences={projectOccurrences}
				// 				handleProjectTabClick={this.handleProjectTabClick}
				// 			/>
				// 		) : null}
				// 	</div>
				// )}
			// </div>
		);
	}
}

Admin.propTypes = {
	getRequests: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
	admin: state.admin,
	requests: state.admin.requests,
	users: state.admin.users,
	userData: state.admin.userData,
	projects: state.admin.projects,
	projectOccurrences: state.admin.projectOccurrences
});

export default connect(
	mapStateToProps,
	{ getRequests, getUsers, getUserData, getAllProjects, clearUserData, getProjects }
)(Admin);
