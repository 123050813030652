import { faAngleDown, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Component, useState } from "react";
import { Collapse } from "reactstrap";
import ContentBox from "../../components/common/ContentBox";
import Contact from "../contact/Contact";

// const Item = (props) => (
// 	<p>
// 		<h3>{props.title}</h3>
// 		{props.children}
// 	</p>
// );

const Item = (props) => {
	const [isOpen, setIsOpen] = useState(props.isOpen || false);

	return (
		<div>
			<button
				className="link-button ml-2"
				style={{
					fontSize: "1.3rem",
					fontWeight: "500",
					textAlign: "left",
					outline: "none",
					textIndent: -15,
				}}
				onClick={(e) => {
					setIsOpen(!isOpen);
				}}
			>
				{isOpen && <FontAwesomeIcon icon={faAngleDown} className="mr-1" />}

				{!isOpen && <FontAwesomeIcon icon={faAngleRight} className="mr-1" />}
				<span>{props.title}</span>
			</button>

			<div>
				<Collapse isOpen={isOpen}>{props.children}</Collapse>
			</div>
		</div>
	);
};

class HowItWorks extends Component {
	render() {
		return (
			<ContentBox title="What is the Local Carbon Registry" width="10">
				<Item title="How the LCR works ..." isOpen={true}>
					<p>
						Whether you decide to track your greenhouse gas (GHG) inventory or add your GHG emission reduction projects to the registry, here is how the Local Carbon Registry (LCR) works:
					</p>

					<ol>
						<li>
							Select Register to request an account. You can choose to only create an
							account - or you can tell us if you’d like to start tracking your GHG
							inventory or add GHG emission reduction projects right away. Creating an
							account is free but adding GHG inventory and/or projects incur a cost.
							GHG inventory pricing depends on your feature needs, while GHG emission
							reduction projects cost $500 plus GST per project. The fee goes
							towards the general maintenance and administration of the registry. It
							will also enable us to continue to add enhancements to the registry and
							develop new initiatives to promote local carbon reductions. You can
							always choose to create an account first and request to pay for the
							registry features later. 
							<p>Please keep in mind that the email address you
							choose to register cannot be changed in the future.</p>
						</li>
						<li>
							After you submit your registration request, please give us up to 3
							business days to review your application and activate your account. If
							you told us that you will be using the GHG inventory and/or the GHG
							emission reduction projects feature with the account, you will receive
							an invoice for the costs as part of the registration process.
						</li>
						<li>
							When you receive the “Local Carbon Registry Account Activated!” email
							notification, you can login and begin.
						</li>
					</ol>
					<p>
						If you choose not to create an account, you can still see all the GHG
						emission reduction projects and public GHG inventory that are listed in the
						registry.
					</p>
				</Item>
				<Item title="How do I search for a project?">
					<p>
						There are several ways to search for a project on the Local Carbon Registry.
					</p>

					<ul>
						<li>
							On the home page, you can find a project using the map. The green icons
							mark the locations and types of projects that are currently in the
							registry. You can also filter the projects shown on the map by using the
							map filter options. Select the Seeking Funding tab to only show the
							projects that are seeking funding.
						</li>
						<li>
							The SEARCH BY OFFSET SERIAL NUMBER allows you to find a specific project’s
							emission reductions by serial number.
						</li>
						<li>
							Show All Projects will take you to a complete list of projects
							registered on the LCR.
						</li>
						<li>
							Lastly, you can choose to see projects by organization. Some local
							governments have a microsite that show all the projects done within
							their area.
						</li>
					</ul>
				</Item>
				<Item title="How do I search for a project that is seeking funding?">
					On our home page, scroll down to the map then select the Seeking Funding tab.
					The map updates to only show projects seeking funding. The green icons mark the
					locations and types of projects that are currently seeking funding. You can also
					filter the projects shown on the map by using the map filter options.
				</Item>
				<Item title="Are the project emission reductions validated and verified?">
					Yes. The project owner provides project planning documents, plus independent
					validation and verification documents. The LCR team crosschecks the provided
					documents to ensure the provided data is aligned.
				</Item>
				<Item title="Can I buy carbon offsets here?">
					<p>No, we do not sell carbon offsets.</p>
					<p>
						However, you can support local projects that are seeking funding in return
						for emission reductions being retired on the registry against your GHG
						inventory. The difference between funding a Registry project compared to
						buying carbon credits is that you don’t gain ownership of a financial
						instrument but rather utilize the environmental benefits. Please check the
						map on our home page for projects seeking funding and contact us for the
						next steps.
					</p>
					<p>
						You can also account for offsets purchased from other platforms as part of
						your GHG inventory carbon neutrality tracking on the registry. This allows
						you to apply the offsets to inventory in the registry to help become carbon
						neutral.
					</p>
				</Item>
			</ContentBox>
		);
	}
}

class FAQ extends Component {
	render() {
		return (
			<React.Fragment>
				<ContentBox title="Frequently Asked Questions" width="10">
					<Item title="How do I add a GHG emission reduction project?">
						<p>
							From the top menu, select <b>Projects > Project List</b>. On the
							following Project List page, select <b>Add Project</b>. The page will
							show the number of projects that you can add to the registry.
						</p>
						<p>
							On the following screen, you can add your GHG emission reduction project
							details then submit the project for review and inclusion in the Local
							Carbon Registry (LCR). Once your project has been submitted and
							approved, you will be able to update emission reductions to confirm
							projected values and add more project documents, but you cannot change
							other details.
						</p>
						<p>
							Be aware that if you did not request to add at least 1 GHG reduction
							project during account registration, you will not be able to access the
							Project List page. Select <b>Projects > Pay</b> for Projects to submit a
							request to add projects to the LCR. Each project costs $500 + GST. Once
							we receive your request, an invoice will be sent to your email. Payment
							options will be displayed on the invoice. You can register a new project
							as soon as your payment is processed.
						</p>
					</Item>

					<Item title="Can I add a GHG emission reduction project that is incomplete? (Ie. project in the planning or proposed stage)">
						<p>
							Yes, you can add a GHG emission reduction project in various stages to
							the LCR. A project that is in the planning stage can be registered on
							the LCR as a draft, which you can update as the project progresses. From
							the top menu, select <b>Projects > Project List</b>. On the following
							Project List page, select <b>Add Project</b>. On the following screen,
							you can add your GHG emission reduction project details.
						</p>

						<p>
							<ul>
								<li>
									To indicate the planning stage, select <b>Planning</b> from the
									Project Stage drop down list. Adding the project in planning
									stage on LCR can be a good exercise to understand the required
									information/documents to prepare to realize the project. The
									project in planning stage will not be publicly visible on the
									registry and will only be accessible to you.
								</li>
								<li>
									To indicate the proposed stage or seeking funding stage, select{" "}
									<b>Proposed – Seeking Funding</b> from the project Stage drop
									down list. Once you choose this project stage, a new required
									field will appear just below to enter the total funding
									required. Also, a financial plan will become a required
									document. Once the project is registered, it will appear on the
									home page in the Seeking Funding map. The LCR team will
									facilitate the matching of investors who are interested in your
									project so that your project can be realized.
								</li>
							</ul>
						</p>
					</Item>
					<Item title="Why can’t I update my project details?">
						<p>
							Once a project has been submitted and approved, it is part of the
							registry. You will be able to update emission reductions to confirm
							projected values and add more project documents, but you cannot change
							other details.
						</p>
						<p>
							When you realize or register projected emission reductions, it will
							require another approval from the LCR team. Please give us up to 5
							business days to review your update request. While your project is
							awaiting approval, it will not be visible to the public.
						</p>
					</Item>
					<Item title="Can I extend my project timeline?">
						<p>Yes! If your project has been approved and added to the registry already but the expected end of the project has changed, you can simply edit the project and change the Project Timeline - End Date.</p>
						<p>After you change the End Date, you can add or edit any <b>Projected</b> emission reductions and it will appear in the project registry page when you select Submit to save your project. </p>
						<p>Be aware that if you change any of Projected emission reductions to <b>Realized</b> status, the project would be subject to review and approval from the LCR team again. While it is in review, the project will be hidden from the registry.</p>
					</Item>
					<Item title="Why doesn’t the project map change when I enter my address?">
						The map is linked to the longitude and latitude settings, and only change if
						you either edit the map directly or add the longitude and latitude values.
					</Item>
					<Item title="Why do you need both the project address and the latitude/longitude?">
						<p>
							The address helps users look up where your project is located through
							normal directories, however the actual location is sometimes not correct
							– for example, some home addresses are a few houses off on Google Maps.
							The longitude and latitude values ensure that we have the most accurate
							location.
						</p>
						<p>
							If you don’t know the geographic coordinates of your project, edit the
							map directly and place a pin on where the project location is. Where you
							place the map pin is translated into the correct longitude and latitude
							values.
						</p>
					</Item>
					<Item title="Are the project emission reductions validated and verified?">
						Yes. The Local Carbon Registry team reviews each project submission and
						verify the details. You will receive an email notification if we approve
						your project and make the project details available to the public. If we are
						unable to approve the submission, we will provide feedback and assistance on
						how the project information can be improved.
					</Item>
					<Item title="What are the serial numbers for? ">
						<p>
							Once your project has been added to the registry, we will provide you
							with a serial number for the project, and each tonne of registered CO2e
							of emission reductions from the project.
						</p>
						<p>
							The serial numbers provide a reference for the status of achieved
							emission reductions. Every tonne of realized (and verified) emission
							reduction from the project is assigned a serial number. The serial
							number are a means of tracking what is being accomplished with the
							emission reductions and avoid double counting. With the realized
							emission reductions, you can choose to keep them registered in the
							registry, retire against your own inventory or other events, or cancel
							under special circumstances.
						</p>
						<p>
							The serial numbers also allow you to share the results of your emission
							reduction projects. When you provide someone with a serial number, they
							will be able to use the search function on our home page to find your
							project or a specific tonne of CO2e.
						</p>
					</Item>
					<Item title="Why isn’t my project listed in the registry?">
						<p>
							Only approved projects are displayed in the registry. Login and check
							your project status to see where it is in the approval process. It
							typically takes 5 business days to approve a newly submitted project.
						</p>
						<p>
							Your project will also not be visible to the public while your project
							is awaiting approval for updating emission reductions. For any reason,
							if you request your project to be private from the registry, it will not
							be listed in the registry for the public.
						</p>
					</Item>
					<Item title="Why isn’t my project shown on the map on the home page?">
						The map on the home page shows all the approved projects in the registry. By
						default, the map is zoomed-in on your current location – you may need to
						zoom out to see projects that are farther away.
					</Item>
					<Item title="How do I add my GHG inventory?">
						<p>
							From the top menu, select <b>Inventory > Inventory</b> List. On the
							Inventory List page, select <b>Add Inventory</b>.
						</p>
						<p>
							On the following screen, you can add your GHG inventory. Once you have
							access to GHG inventory feature, you can add multiple inventories. If
							you work for a local government, you could add corporate and
							community-wide inventories. Make sure to upload a GHG inventory report
							for each year. Inventory information can be updated at any time without
							approval from the LCR team.
						</p>
						<p>
							Be aware that if you did not request to use the GHG inventory feature
							during account registration, you will not be able to access the
							Inventory List page. Contact{" "}
							<a href="mailto:support@localcarbonregistry.org">
								support@localcarbonregistry.org
							</a>{" "}
							to enable this feature for your account.
						</p>
					</Item>
					<Item title="How do I track my emission reduction projects against my inventory?">
						<p>
							You need to first add the GHG inventory. When you add the inventory you
							want to track, make sure the Track in Overview setting is set to
							“Include”. This will enable linking between your GHG inventory and your
							emission reduction projects.{" "}
							<b>We recommend selecting only one GHG inventory for tracking</b>.
						</p>
						<p>
							When you add your GHG emission reduction project, you need to identify
							the amount of Tonnes CO2e that is generated each year (you can enter up to a 10 digit number). The entered amount must be
							marked as Projected (estimate) or Realized (confirmed). If the amount is Realized, it can be Retired - this allows you to align the amount of CO2e against a year of
							GHG inventory that has already been added to the system.
						</p>
						<p>
							Once you have both the GHG inventory and GHG reduction project added to
							the LCR, you will be able to see the emission reductions/offsets from
							the project against your inventory in the Overview graph.
						</p>
					</Item>
					<Item title="Why is my Overview page empty?">
						<p>
							The Overview pages only show a graph of your carbon reduction efforts if
							you have an approved GHG project or GHG inventory.
						</p>
						<p>
							The Reductions Overview page only shows projects that have been approved
							and offsets that have been added.
						</p>
						<p>
							The <i>GHG True-up Overview</i> page only shows inventory that have been added
							and allowed to be tracked on the Overview page. When you add the
							inventory, make sure the Track in Overview setting is set to “Include”.
						</p>
					</Item>
					<Item title="Can I buy carbon offsets here?">
						<p>No, we do not sell carbon offsets.</p>
						<p>
							However, you can support local projects that are seeking funding in
							return for emission reductions being retired on the registry against
							your GHG inventory. The difference between funding a Registry project
							compared to buying carbon credits is that you don’t gain ownership of a
							financial instrument but rather utilize the environmental benefits.
							Please check the map on our home page for projects seeking funding and
							contact us for the next steps.
						</p>
						<p>
							However, we offer the ability to add offsets purchased from other
							organizations as part of your GHG inventory. This allows you to apply
							the offsets to inventory in the registry to help become carbon neutral.
						</p>
						<p>
							After you log into your account, select{" "}
							<b>Inventory > Purchased Offsets</b> from the top menu. On the following
							page, you can add a purchased offset to your account.
						</p>
					</Item>
					<Item
						title="How do I add Purchased Offsets?
"
					>
						<p>
							From the top menu, select <b>Inventory > Purchased Offsets</b>. On the
							Purchased Offsets List page, select <b>Add Offsets</b>.
						</p>
						<p>
							On the following screen, you can add your purchased offsets details then
							save it. Purchased offsets information can be updated at any time
							without approval from the LCR team.
						</p>
						<p>
							Be aware that if you did not request to use the GHG inventory feature
							during account registration, you will not be able to access the
							Purchased Offsets page. Contact{" "}
							<a href="mailto:support@localcarbonregistry.org">
								support@localcarbonregistry.org
							</a>{" "}
							to enable this feature for your account.
						</p>
					</Item>
					<Item title="I received offline funding in exchange for my project's emission reductions (ER), how do I transfer the ER?">
						<p>While the LCR does not sell purchased offsets, users with an LCR account can reach out to projects directly to offer funding in exchange for emission reductions.</p>
						<p>To transfer ER to a different LCR account, simply edit your project and do the following:
						</p>
						<ol>
						<li>Expand a row of <b>Realized</b> emission reduction then change the Add to Registry drop-down to <b>Transfer</b>.</li>
						<li>In the Tonnes CO2e box, enter the amount of ER that you are transferring.</li>
						<li>In the final drop-down list, select the user that provided funding.
						<p>If the user is not listed, you will need to ask them to setup an LCR account with an added inventory or you will not be able to transfer the ER.</p></li>
						</ol>
					</Item>
					<Item title="I provided funding to a project, where is the ER?">
						<p>In order to receive ER, you must have an account in the LCR and at least one of the following:</p>
						<ul>
						<li>An added inventory</li>
						<li>A purchased offset from a different source</li>
						<li>An approved project</li>
						</ul>
						<p>If you have one of the above, please be patient. The transfer of ER is managed by the project owners themselves. The LCR team does not interfere with these transactions.</p>
						<p>Once the transfer has been made, it will appear in your LCR account under Purchased Offsets like this: "Sender Name - Project Name".</p>
						<p>When you select to View the purchased offset, you can assign the emission reductions to an inventory in the LCR or different service:</p>
						<ol>
						<li>Scroll down to Purchased Offsets area and expand a Realized row.</li>
						<li>Change the Registered drop-down to <b>Retired</b>.</li>
						<li>You can change the amount of Tonnes CO2e as needed.</li>
						<li>In the last box, select what the ER is being retired against.
						<p>If you have an inventory setup in the LCR, you will be able to select the inventory. If not, select Other and identify what the ER is being retired against. </p></li>
						</ol>
					</Item>
					<Item title="What is the Visibility setting on the Purchased Offsets form about?">
						The Visibility setting allows you to choose to show or hide your purchased
						offsets on the registry. When you make purchased offsets Private, it will be
						hidden from project lists and the registry map, which is visible to the
						general public. However, it will still be displayed on the Reductions
						Overview page and the <i>GHG True-up Overview</i> page. Be aware that if you decide
						to share the Overview pages with others the receiving person will be able to
						see all purchased offsets in the project details, even if the offsets are
						set to private.
					</Item>
					<Item title="How do I track my purchased offsets against my inventory?">
						<p>
							You need to add the GHG inventory and make sure the Track in Overview
							setting is set to “Include”. This will enable linking between your GHG
							inventory and purchased offsets. We recommend selecting only one GHG
							inventory for tracking.
						</p>
						<p>
							When you add purchased offsets, you need to identify the amount of CO2e
							that is generated each year – that amount must be marked as Projected
							(estimate), Realized (confirmed), and Retired. If you select Retired,
							you can align the amount of CO2e against a year of GHG inventory that
							has already been added to the system.
						</p>
						<p>
							Once you have both the GHG inventory and Purchased Offsets added to the
							LCR, you will be able to see the emission reductions/offsets from the
							project against your inventory in the Overview graph.
						</p>
					</Item>
					<Item title="Am I allowed to change my login email address after registration?">
						No, you cannot change your account email address after registration.
					</Item>
					<Item title="I need help – where do I find it?">
						To reach out for help not covered on this page, please use the Contact form
						below to send us a message.
					</Item>
				</ContentBox>

				<Contact />
			</React.Fragment>
		);
	}
}

export { HowItWorks, FAQ };
