import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";
import Tooltip from "./Tooltip";

const TextFieldGroup = ({
	name,
	defaultValue,
	placeholder,
	value,
	label,
	error,
	info,
	type,
	onChange,
	disabled,
	autoComplete,
	style,
	readOnly,
	tooltip
}) => {
	return (
		<div className="form-group" style={style}>
			<label htmlFor={name} className="pl-2">
				{label ? label : null}
			</label>

			{tooltip && <Tooltip name={name} content={tooltip} />}
			<input
				type={type}
				id={name}
				className={classnames("form-control", {
					"is-invalid": error
				})}
				placeholder={placeholder}
				name={name}
				value={value || ""}
				onChange={onChange}
				disabled={disabled}
				autoComplete={autoComplete}
				readOnly={readOnly}
			/>
			{info && <small className="form-text text-muted">{info}</small>}
			{error && (
				<div className="invalid-feedback" id={name + "_error"}>
					{error}
				</div>
			)}
		</div>
	);
};

TextFieldGroup.propTypes = {
	name: PropTypes.string.isRequired,
	placeholder: PropTypes.string,
	info: PropTypes.string,
	error: PropTypes.string,
	type: PropTypes.string.isRequired,
	onChange: PropTypes.func.isRequired,
	disabled: PropTypes.string
};

TextFieldGroup.defaultProps = {
	type: "text"
};

export default TextFieldGroup;
