import React from "react";

import { Card, CardBody, CardGroup } from "reactstrap";
export default (props) => {
	//const width = props.width ? props.width : 8;

	return (
		<CardGroup>
			<Card style={{}} className="my-4">
				<CardBody
					style={{
						backgroundColor: "white",
						color: "#952168",
						textAlign: "center",
						borderRadius: "0.3rem 0.3rem 0rem 0rem",
					}}
					className="pb-1"
				>
					<h2
						style={{
							fontFamily: "Amiko",
							fontStyle: "normal",
							fontWeight: "normal",
							textTransform: "uppercase",
							padding: "0px 20px 0 20px",
							size: "32px",
						}}
					>
						{props.title}
					</h2>
					<p color="white">{props.subtitle}</p>
				</CardBody>

				<CardBody
					style={{ backgroundColor: "white", borderRadius: "0px 0px 0.3rem 0.3rem" }}
				>
					{props.children}
				</CardBody>
			</Card>
		</CardGroup>
	);
};
