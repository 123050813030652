export const GET_ERRORS = "GET_ERRORS";
export const CLEAR_ONE_ERROR = "CLEAR_ONE_ERROR";
export const SET_WORKING = "UPDATE_WORKING";

// Auth
export const SET_CURRENT_USER = "SET_CURRENT_USER";
export const REDIRECT = "REDIRECT";
export const SET_TOKEN_EXPIRE_POPUP = "SET_TOKEN_EXPIRE_POPUP";

// Profile
export const GET_PROFILE = "GET_PROFILE";
export const PROFILE_LOADING = "PROFILE_LOADING";
export const PROFILE_NOT_FOUND = "PROFILE_NOT_FOUND";
export const CLEAR_CURRENT_PROFILE = "CLEAR_CURRENT_PROFILE";
export const GET_PROFILES = "GET_PROFILES";

// Project
export const PROJECT_LOADING = "PROJECT_LOADING";
export const PROJECT_TOKENS_LOADING = "PROJECT_TOEKNS_LOADING";
export const PROJECT_CLEAR = "PROJECT_CLEAR";
export const GET_PROJECTS = "GET_PROJECTS";
export const GET_PUBLIC_PROJECTS = "GET_PUBLIC_PROJECTS";
export const GET_PROJECT = "GET_PROJECT";
export const GET_FEATURED_PROJECTS = "GET_FEATURED_PROJECTS";
export const GET_USER_PROJECTS = "GET_USER_PROJECTS";
export const GET_RANGE = "GET_RANGE";
export const GET_TOKENS = "GET_TOKENS";

// Dashboard
export const SEARCH_LOADING = "SEARCH_LOADING";
export const GET_SEARCH_RESULTS = "GET_SEARCH_RESULTS";

// Drafts
export const GET_DRAFTS = "GET_DRAFTS";
export const DRAFTS_LOADING = "DRAFTS_LOADING";
export const GET_DRAFT = "GET_DRAFT";

// Admin
export const GET_REQUESTS = "GET_REQUESTS";
export const GET_USERS = "GET_USERS";
export const GET_USER_DATA = "GET_USER_DATA";
export const GET_ADMIN_PROJECTS = "GET_ADMIN_PROJECTS";
export const UPDATE_ADMIN_PROJECTS = "UPDATE_ADMIN_PROJECTS";
export const GET_PROJECT_DATA = "GET_PROJECT_DATA";
export const CLEAR_USER_DATA = "CLEAR_USER_DATA";
export const SET_USERS_LOADING = "SET_USERS_LOADING";
export const SET_REQUESTS_LOADING = "SET_REQUESTS_LOADING";
export const SET_PROJECTS_LOADING = "SET_PROJECTS_LOADING";
export const SET_USER_DATA_LOADING = "SET_USER_DATA_LOADING";

// Settings
export const GET_SETTINGS_PRICING = "GET_SETTINGS_PRICING";
export const GET_SETTINGS_APIKEYS = "GET_SETTINGS_APIKEYS";
