import React, { Component } from "react";
import EmissionReduction from "./EmissionReduction.js";
import ContentBox from "../common/ContentBox.js";

class EmissionTest extends Component {
	constructor(props) {
		super(props);
		this.state = {
			// er_raw : [
			// 	{ year: 2017, state: "retired", serialMin: "10001", retiredAgainst: "something", count:2},
			// 	{ year: 2017, state: "registered", serialMin: "10003", count: 1 },
			// 	{ year: 2018, state: "registered", serialMin: "10004", count: 2 },
			// 	{year: 2019, state: "registered", count: 10 },
			// 	{year: 2020, state: "registered", count: 0 },
			// 	{ year: 2021, state: "projected", count: 0 },
			// ]
			er_raw: {
				draft: [
					{ year: 2019, status: "Registered", quantity: 10 },
					{ year: 2020, status: "Inventory", quantity: 0 },
					{ year: 2021, status: "Projected", quantity: 100 }
				],
				registered: [
					{
						year: 2017,
						status: "Retired",
						serialMin: "X-100010",
						serialMax: "X-100019",
						retiredAgainst: "something"
					},
					{ year: 2017, status: "Registered", serialMin: "X-100020", serialMax: "X-100029" },

					{ year: 2018, status: "Registered", serialMin: "X-100030", serialMax: "X-100049" }
				]
			}
		};

		this.state.er = this.state.er_raw; //.map((obj, idx)=>({idx:idx, ...obj}))

		// this.onChange = this.onChange.bind(this);
		// this.onSubmit = this.onSubmit.bind(this);
		this.onChange = this.onChange.bind(this);
	}

	onChange(newData) {
		this.setState({ er: newData });
		console.log(newData);
	}

	render() {
		return (
			<ContentBox width="12" title="Emmisions">
				<input
					type="checkbox"
					onChange={e => this.setState({ restricted: e.target.checked })}
					checked={this.state.restricted}
				/>{" "}
				<span className="p-2">Restricted</span>
				<input
					type="checkbox"
					onChange={e => this.setState({ readOnly: e.target.checked })}
					checked={this.state.readOnly}
				/>
				<span className="p-2">Read Only</span>
				<EmissionReduction
					value={this.state.er}
					onChange={this.onChange}
					restricted={this.state.restricted}
					readOnly={this.state.readOnly}
				/>
				<p>
					Test:
					{this.state.er.registered.map(obj => (
						<p className="m-0">{JSON.stringify(obj, null, 2)}</p>
					))}
					{this.state.er.draft.map(obj => (
						<p className="m-0">{JSON.stringify(obj, null, 2)}</p>
					))}
					{/* {this.state.er.map(obj => (
						<p className="m-0">{JSON.stringify(obj, null, 2)}</p>
					))} */}
				</p>
			</ContentBox>
		);
	}
}

export default EmissionTest;
