import React from "react";
import spinner from "./25.gif";

export default props => {
    if (!props.show) return null;
    
    const size=props.width?props.width: "50px"
	return (
		<img
			id="spinner"
			src={spinner}
			alt="Loading..."
			style={{ width: size, height: size, margin: "auto" }}
		/>
	);
};
