import React, { Component } from "react";
import { Button, Modal, ModalHeader, ModalBody, Input } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShareAlt } from "@fortawesome/free-solid-svg-icons";

export class ShareButton extends Component {
	constructor() {
		super();
		this.state = {
			showModal: false
		};
	}

	toggleModal = () => {
		this.setState({ showModal: !this.state.showModal });
	};

	render() {
		return (
			<React.Fragment>
				<Button
					color="primary"
					onClick={e => {
						this.toggleModal();
					}}
				>
					<FontAwesomeIcon icon={faShareAlt} />
					{/* style={{ width: 15, height: 15 }} */}
				</Button>
                
				<Modal isOpen={this.state.showModal} toggle={this.toggleModal}>
					<ModalHeader toggle={this.toggleModal}>Public Link</ModalHeader>
					<ModalBody>
						<Input value={this.props.url} />
					</ModalBody>
				</Modal>
			</React.Fragment>
		);
	}
}
